import { Reducer, ReducersMapObject, configureStore } from "@reduxjs/toolkit";

import { createReducerManager } from "./reducerManager";
import { ReduxStoreWithManager, StateSchema, ThunkExtraArg } from "./StateSchema";

import { casesReducer } from "../../../../entities/Cases/models/slices/CasesSlice";
import { userReducer } from "../../../../entities/User/models/slices/UserSlice";

import { $api } from "../../../../shared/api/api";

export function createReduxStore(
    initialState?: StateSchema,
    asyncReducers?: ReducersMapObject<StateSchema>
): ReduxStoreWithManager {
    const staticReducers: ReducersMapObject<StateSchema> = {
        ...asyncReducers,
        user: userReducer,
        cases: casesReducer,
    };
    
    const reducerManager = createReducerManager(staticReducers);
    
    const extraArg: ThunkExtraArg = {
        api: $api,
    };

    const store = {
        ...configureStore({
            reducer: reducerManager.reduce as Reducer<StateSchema>,
            preloadedState: initialState,
            middleware: (getDefaultMiddleware) =>
                getDefaultMiddleware({
                    thunk: {
                        extraArgument: extraArg,
                    },
                }).concat(),
        }),
        reducerManager,
    };

    return store;
};

