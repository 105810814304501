import React from "react";

import styled from "styled-components";


interface SecondaryButtonProps {
    // Style
    $isLarge?: boolean;
    
    $width?: string;
    $borderColor?: string;
    $color?: string;

    // Values
    onClick?: (e?: React.FormEvent) => void;
    leading?: string;
    title?: string;
    toggle?: string;
}

const ButtonContainer = styled.button<SecondaryButtonProps>`
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: transparent;
    width: ${(props) => props.$width ?? '100%'};
    padding: ${(props) => props.$isLarge ? '12px 32px' : '10px 16px'};
    color: ${(props) => props.$borderColor ? props.$color ? props.$color : '#000' : '#153968'};
    border: 1px solid ${(props) => props.$borderColor ?? '#153968'};
    border-radius: 8px;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #E8EBF0;
        color: ${(props) => props.color ? props.color : '#0F2849'};
        border: 1px solid ${(props) => props.$borderColor ? props.$borderColor : '#0F2849'};
    }

    &:active {
        background-color: #DCE1E8;
        color: ${(props) => props.color ? props.color : '#0D233F'};
        border: 1px solid ${(props) => props.$borderColor ? props.$borderColor : '#0D233F'};
    }

    &:disabled {
        background-color: #EAEDF1;
        color: ${(props) => props.color ? props.color : '#7388A4'};
        border: 1px solid ${(props) => props.$borderColor ? props.$borderColor : '#7388A4'};
    }
    
    &:focus {
        border: 1px solid #c2c2c2;
        color: #797979;
    }
    @media (max-width: 420px) {
        width: 100%;
        flex: 1;
    }
`;

const ButtonLeading = styled.img<SecondaryButtonProps>`
    width: '20px';
    color: #153968;
`;

const ButtonTitle = styled.div<SecondaryButtonProps>`
    font-size: ${(props) => props.$isLarge ? '16px' : '14px'};
    font-weight: ${(props) => props.$isLarge ? '600' : '500' };
    font-family: 'SF Pro Display';
`;  

const ButtonToggle = styled.img<SecondaryButtonProps>`
    width: ${(props) => props.$isLarge ? '20px' : '16px'};
    color: #fff;
`;

const SecondaryButton: React.FC<SecondaryButtonProps> = (props) => {
    return (
        <ButtonContainer 
            onClick={props.onClick} 
            $width={props.$width}
            $color={props.$color}
            $borderColor={props.$borderColor}
            $isLarge={props.$isLarge}
            >
                {
                    props.leading &&
                        <ButtonLeading 
                            src={props.leading}
                            alt="icon"
                            $isLarge={props.$isLarge}
                        />
                }
                {
                    props.title &&
                        <ButtonTitle
                            $isLarge={props.$isLarge}
                        >{props.title}</ButtonTitle>
                }
                {
                    props.toggle &&
                        <ButtonToggle 
                            src={props.leading}
                            alt="icon"
                            $isLarge={props.$isLarge}
                        />
                }
            </ButtonContainer>
    )
}

export default SecondaryButton;