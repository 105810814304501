import { useState } from "react";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { sendEmailVerification } from "firebase/auth";
import { Navigate, useNavigate } from "react-router-dom";

import { auth } from "../../configs/firebase-config";

import { errorHandler } from "../../shared/utils/errorHandler";
import { USER_LOCALSTORAGE_KEY } from "../../shared/consts/localStorage";
import { useAppDispatch } from "../../shared/components/useAppDispatch/useAppDispatch";

import Text from "../../shared/lib/widgets/Text";
import RowWidget from "../../shared/lib/widgets/RowWidget";
import Container from "../../shared/lib/widgets/Container";

import PrimaryButton from "../../components/Buttons/PrimaryButton";
import styled from "styled-components";
import { $api } from "../../shared/api/api";
import { fetchUserById } from "../../entities/User/models/services/user.services";


const BlueColor = styled.b`
    color: #007FFF;
    cursor: pointer;
`;

const EmailVerification = () => {
    const { t } = useTranslation();
    
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const email = auth.currentUser?.email;

    if (!email) {
        return <Navigate to='/sign-in' />
    }

    const handleSendEmail = (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);

        sendEmailVerification(auth!.currentUser!)
            .then(() => {
                toast.success(t('messages.success.forgot_password_email_send'));
            })
            .catch((e: any) => toast.error(errorHandler(e.code)))
            .finally(() => setIsLoading(false));
    }

    const checkEmail = async (e: React.FormEvent) => {
        e.preventDefault();

        await auth.currentUser?.reload();

        if (!auth.currentUser?.emailVerified)
            return toast.info(t('messages.info.email_verification'));

        const user = await $api.post('/users-email/', { email: email.trim().toLowerCase() })
        localStorage.setItem(USER_LOCALSTORAGE_KEY, user.data.id);
        dispatch(fetchUserById(user.data.id));
        navigate('/');
    }

    return (
        <RowWidget $width="100%" $height="95vh" $justifyContent="center" $alignItems="center">
            <RowWidget $height="100%" $width="360px" $flexDirection="column" $justifyContent="space-between">
                <Container $height="100%" $margin="64px 0 0 0">
                    <RowWidget $justifyContent="center">
                        <img style={{ height: '32px', marginRight: '8px', }} src="images/img_group.svg" alt="logo" />
                        <img style={{ height: '32px' }} src="images/img_group_light_blue_a700.svg" alt="logo" />
                    </RowWidget>

                    <Container $height="64px" />

                    <RowWidget $justifyContent="center">
                        <img src="images/check_email.svg" alt="key" style={{ width: '80px', height: '80px' }} />
                    </RowWidget>

                    <Container $height="16px" />

                    <Text $textAlign="center">{t('auth.email_verification.description')}</Text>

                    <Container $height="12px" />

                    <Text fontWeight="bold" fontSize="24px" $textAlign="center">{t('auth.email_verification.title')}</Text>

                    <Container $height="8px" />

                    <RowWidget $flexWrap="wrap">
                        <Text $textAlign="center">{t('auth.email_verification.description_text.text1')} <b>{email}</b>. {t('auth.email_verification.description_text.text2')} <b>{t('auth.email_verification.description_text.text3')}</b> {t('auth.email_verification.description_text.text4')} <BlueColor onClick={handleSendEmail}>{t('auth.email_verification.description_text.text5')}</BlueColor>.</Text>
                    </RowWidget>

                    <Container $height="32px" />

                    <RowWidget $justifyContent="center">
                        <PrimaryButton 
                            onClick={checkEmail}
                            title={isLoading ? 'Loading...' : t('button.verify_email')}
                            $width="80%"
                        />
                    </RowWidget>

                    <Container $height="16px" />
                </Container>
                
                <Text $textAlign="center" color="#7E7D83" fontSize="14px">Copyright © 2024 LegalAssists</Text>
            </RowWidget>
        </RowWidget>
    );
}

export default EmailVerification;