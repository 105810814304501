import { format } from 'date-fns';


export function formatDate(date: Date) {
    if (!date)
        return '-';

    const dayOfMonth = format(date, 'do');
    const month = format(date, 'MMM');
    const year = format(date, 'yyyy');

    return `${month} ${dayOfMonth}, ${year}`;
}

export function formatDateTime(date?: Date) {
    if (!date)
        return '-';

    const formatDateTimeValue = format(date, 'Pp');
    
    return formatDateTimeValue
};
