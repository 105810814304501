export const isEmail = (email: string) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

export const checkAgentSubscription = (userAgents: any, agentId: string) => {
    const today = new Date();
    const oneMonthAgo = new Date(today.setFullYear(today.getFullYear(), today.getMonth() - 1));

    const agentPurchased = userAgents.find((purchasedAgent: any) => purchasedAgent.id === agentId);
    const purchaseDate = agentPurchased ? new Date(agentPurchased.date) : null;

    return purchaseDate && purchaseDate > oneMonthAgo;
}