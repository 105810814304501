import { createSlice } from '@reduxjs/toolkit';

import { UserState } from '../types/User.types';
import { fetchUserById, updateUserData, userPurchaseAgent } from '../services/user.services';

import { USER_LOCALSTORAGE_KEY } from '../../../../shared/consts/localStorage';


const initialState: UserState = {
    isLoading: false,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setAuthData: (state, action) => {
            state.userData = action.payload;
        },
        updateCases: (state, action) => {
            state.userData?.case_ids.push(action.payload);
        },
        logout: (state) => {
            state.userData = undefined;
            localStorage.removeItem(USER_LOCALSTORAGE_KEY);
            localStorage.removeItem('accessToken');
        },
    },
    extraReducers: (builder) => {
            builder.addCase(fetchUserById.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchUserById.fulfilled, (state, action) => {
                state.isLoading = false;
                state.userData = action.payload;
            })
            .addCase(fetchUserById.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            });
        builder
            .addCase(userPurchaseAgent.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(userPurchaseAgent.fulfilled, (state, action) => {
                state.isLoading = false;
                const agentIndex = state.userData?.agent_ids.findIndex(agent => agent.id === action.payload.id);

                if (agentIndex !== undefined && agentIndex !== -1) {
                    if (state.userData && state.userData.agent_ids[agentIndex]) {
                        state.userData.agent_ids[agentIndex].date = action.payload.date;
                    }
                } else {
                    state.userData?.agent_ids.push(action.payload);
                }
            })
            .addCase(userPurchaseAgent.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            });
        builder
            .addCase(updateUserData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateUserData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.userData = action.payload;
            })
            .addCase(updateUserData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            });
    }
});


export const { actions: userActions } = userSlice;
export const { reducer: userReducer } = userSlice;
