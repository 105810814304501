import i18n from 'i18next';


export const errorHandler = (errorCode: string) => {
    switch (errorCode){
        case 'auth/invalid-credential':
            return i18n.t('messages.errors.auth.invalid_credential');
        case 'auth/invalid-password':
            return i18n.t('messages.errors.auth.invalid_password');
        case 'auth/user-not-found':
            return i18n.t('messages.errors.auth.user_not_found');
        case 'auth/invalid-email':
            return i18n.t('messages.errors.auth.invalid_email');
        case 'auth/email-already-exists':
            return i18n.t('messages.errors.auth.email_already_exists');
        case 'auth/missing-email':
            return i18n.t('messages.errors.auth.missing_email');
        case 'auth/email-already-in-use':
            return i18n.t('messages.errors.auth.email_already_in_use');
        default:
            return i18n.t('messages.errors.somthing_wrong');
    }
}