import React from "react";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";


interface LoadStripeProps {
    children: React.ReactNode
}

const LoadStripe: React.FC<LoadStripeProps> = ({ children }) => {
    const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_SECRET}`);

    return (
        <>
            {
                stripePromise && 
                    <Elements stripe={stripePromise}>
                        {children}
                    </Elements>
            }
        </>
    );
}

export default LoadStripe;