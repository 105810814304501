import React, { Suspense, useEffect } from 'react';

import { AppRouter } from './app/providers/AppRouter/ui/AppRouter';

import Loader from './shared/lib/widgets/Loader';

import { useAppDispatch } from './shared/components/useAppDispatch/useAppDispatch';
import { USER_LOCALSTORAGE_KEY } from './shared/consts/localStorage';
import { fetchUserById } from './entities/User/models/services/user.services';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './fonts/font.css';


const App: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const userId = localStorage.getItem(USER_LOCALSTORAGE_KEY);
      if (userId)
        dispatch(fetchUserById(userId));
  }, [dispatch]);

  return (
    <>
        <Suspense fallback={<Loader />}>
          <AppRouter />
        </Suspense>

        <ToastContainer />
    </>
  );
};

export default App;