import React from "react";

import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { Case } from "../../entities/Case/models/types/Case.types";

import { StateSchema } from "../../app/providers/StoreProvider/config/StateSchema";

import { formatDateTime } from "../../shared/utils/date_format";
import { useAppSelector } from "../../shared/components/useAppSelector/useAppSelector";

import User from '../../assets/user.svg';

import ActionMenuDropDown from "../DropDown/ActionMenuDropDown";

import RowWidget from "../../shared/lib/widgets/RowWidget";
import Container from "../../shared/lib/widgets/Container";


const CaseCardContainer = styled.div`
	padding: 18px 12px 12px 18px;
	border-radius: 12px;
	background-color: #F6F7F9;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	cursor: pointer;
`;

const CaseCardHeader = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 8px;
`;

const CaseCardTitle = styled.div`
	text-decoration: none;
	font-weight: bold;
	font-size: 18px;
	color: #201F29;
	margin-right: 8px;
	line-height: 20px;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const CaseCardText = styled.div`
	margin-top: 8px;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 16px;
	font-weight: 400;
	font-size: 14px;
	color: #201F29;
	min-height: 50px;
`;


const CaseCardUserImg = styled.img`
	width: 30px;
	height: 30px;
	border-radius: 100%;
	object-fit: cover;
	margin-right: 8px;
`;

const CaseCardBottomDate = styled.div`
	font-size: 14px;
	font-weight: 400;
	color: #8E9092;
`;

interface CaseCardProps {
	caseData?: Case;
	isRecentlyUsed?: boolean;
}

const CaseCard: React.FC<CaseCardProps> = ({ caseData, isRecentlyUsed }) => {
	const navigate = useNavigate();

	const user = useAppSelector((state: StateSchema) => state.user.userData);

	return (
		<CaseCardContainer onClick={() => navigate(`/case/${caseData?.id}`)}>
			<CaseCardHeader>
				<CaseCardTitle>{caseData?.name}</CaseCardTitle>

				<ActionMenuDropDown caseData={caseData!} />
			</CaseCardHeader>

			{
				!isRecentlyUsed && 
				<Container $cursor="pointer">
					<CaseCardText>{caseData!.messages && caseData!.messages.length ? caseData!.messages[caseData!.messages.length - 1]!.content : ''}</CaseCardText>

					<Container $width="100%" $height="1px" $backgroundColor="#E8ECF0" $margin="8px 0" />

					<RowWidget $justifyContent="space-between" $alignItems="center">
						<RowWidget $alignItems="center">
							<CaseCardUserImg
								src={user?.image ? user?.image : User}
								alt="user"
							/>
							{
								caseData!.collaborators && caseData!.collaborators?.length > 1 ? `+${caseData!.collaborators?.length - 1}` : null
							}
						</RowWidget>

						<CaseCardBottomDate>{formatDateTime(caseData?.last_edit)}</CaseCardBottomDate>
					</RowWidget>
				</Container>
			}
		</CaseCardContainer>
	);
};

export default CaseCard;