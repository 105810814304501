import { AppRoutesProps } from "../types/AppRoutesProps.types";
import { 
    AppRoutes, 
    getRouteAcademy, 
    getRouteAcademyArticlesGuides, 
    getRouteAcademyVideoTutorial, 
    getRouteAcademyWebinar, 
    getRouteCases, 
    getRouteChatId, 
    getRouteMarketplace, 
    getRouteMarketplaceAgent, 
    getRouteNotFound, 
    getRouteSettings, 
    getRouteSubscription, 
    getRouteSubscriptionPlan, 
    getRouteAcademyPractical,
    getRouteSignUp,
    getRouteSignIn,
    getRouteForgotPasswpord,
    getArchive,
    getRouteEmailVerification,
    getRouteResetPassword,
    getRouteAffiliate,
    getRouteDocumentId} from "../../../../../shared/consts/routes";

import MyCases from "../../../../../pages/MyCases";
import ArchivePage from "../../../../../components/PageContent/Archive/Archive";
import Chat from "../../../../../pages/Chat";

import DocumentEditor from "../../../../../components/Editor/Editor";

import Subscriptions from "../../../../../pages/Subscriptions";
import SubscriptionPlan from "../../../../../pages/SubscriptionPlan";

import Academy from "../../../../../pages/Academy";
import ArticlesAndGuides from "../../../../../components/PageContent/Academy/ArticlesAndGuides";
import VideoTutorial from "../../../../../components/PageContent/Academy/VideoTutorial";
import Practical from "../../../../../components/PageContent/Academy/PracticalExamples";
import Webinar from "../../../../../components/PageContent/Academy/Webinar";

import AccountSettings from "../../../../../pages/Settings";
import AiMarketPlace from "../../../../../components/PageContent/Marketplace/MarketPlace";
import AssistantInfo from "../../../../../components/PageContent/Marketplace/AssistantInfo";

import SignUp from "../../../../../pages/Auth/SignUp";
import ForgotPassword from "../../../../../pages/Auth/FogotPassword";
import SignIn from "../../../../../pages/Auth/SignIn";
import EmailVerification from "../../../../../pages/Auth/EmailVerification";
import ResetPassword from "../../../../../pages/Auth/ResetPassword";

import Affiliate from "../../../../../pages/Affiliate";

import NotFound from "../../../../../pages/NotFound";


export const routeConfig: Record<AppRoutes, AppRoutesProps> = {
    [AppRoutes.SIGNUP]: {
        path: getRouteSignUp(),
        element: <SignUp />,
    },
    [AppRoutes.SIGNIN]: {
        path: getRouteSignIn(),
        element: <SignIn />,
    },
    [AppRoutes.EMAILVERIFICATION]: {
        path: getRouteEmailVerification(),
        element: <EmailVerification />,
    },
    [AppRoutes.FORGOTPASSWORD]: {
        path: getRouteForgotPasswpord(),
        element: <ForgotPassword />,
    },
    [AppRoutes.RESETPASSWORD]: {
        path: getRouteResetPassword(),
        element: <ResetPassword />,
    },

    // My-Cases
    [AppRoutes.HOME]: {
        path: getRouteCases(),
        element: <MyCases />,
        authOnly: true,
    },
    [AppRoutes.ARCHIVE]: {
        path: getArchive(),
        element: <ArchivePage />,
        authOnly: true,
    },
    [AppRoutes.CHAT_ID]: {
        path: getRouteChatId(':roomId'),
        element: <Chat />,
        authOnly: true,
    },
    [AppRoutes.DOCUMENT_EDITOR]: {
        path: getRouteDocumentId(':documentId'),
        element: <DocumentEditor />,
        authOnly: true,
    },

    // Subscription
    [AppRoutes.SUBSCRIPTION]: {
        path: getRouteSubscription(),
        element: <Subscriptions />,
        authOnly: true,
    },
    [AppRoutes.SUBSCRIPTION_PLAN]: {
        path: getRouteSubscriptionPlan(),
        element: <SubscriptionPlan />,
        authOnly: true,
    },
    [AppRoutes.MARKETPLACE]: {
        path: getRouteMarketplace(),
        element: <AiMarketPlace />,
        authOnly: true,
    },
    [AppRoutes.MARKETPLACE_AGENT]: {
        path: getRouteMarketplaceAgent(':id'),
        element: <AssistantInfo />,
        authOnly: true,
    },

    // Academy
    [AppRoutes.ACADEMY]: {
        path: getRouteAcademy(),
        element: <Academy />,
        authOnly: true,
    },
    [AppRoutes.ACADEMY_VIDEO_TUTORIAL]: {
        path: getRouteAcademyVideoTutorial(),
        element: <VideoTutorial />,
        authOnly: true,
    },
    [AppRoutes.ACADEMY_ARTICLES_GUIDES]: {
        path: getRouteAcademyArticlesGuides(),
        element: <ArticlesAndGuides />,
        authOnly: true,
    },
    [AppRoutes.ACADEMY_PRACTICAL]: {
        path: getRouteAcademyPractical(),
        element: <Practical />,
        authOnly: true,
    },
    [AppRoutes.ACADEMY_WEBINAR]: {
        path: getRouteAcademyWebinar(),
        element: <Webinar />,
        authOnly: true,
    },

    // Settings
    [AppRoutes.SETTINGS]: {
        path: getRouteSettings(),
        element: <AccountSettings />,
        authOnly: true,
    },

    [AppRoutes.AFFILIATE]: {
        path: getRouteAffiliate(),
        element: <Affiliate />,
        authOnly: true,
    },

    // Not Found
    [AppRoutes.NOT_FOUND]: {
        path: getRouteNotFound(),
        element: <NotFound />,
    },
}