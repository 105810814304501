import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import MarketIcon from '../../../assets/MarketIcon.svg';

import AgentCard from '../../Cards/AgentCard';
import SubHeader from '../../SubHeader/SubHeader';

import { Agent } from '../../../entities/Agents/models/types/Agent.types';
import { fetchAgents } from '../../../entities/Agents/models/services/agents.services';
import { agentsReducer, selectAgents } from '../../../entities/Agents/models/slices/AgentsSlice';

import { checkAgentSubscription } from '../../../shared/utils/validators';

import { useAppDispatch } from '../../../shared/components/useAppDispatch/useAppDispatch';
import { useAppSelector } from '../../../shared/components/useAppSelector/useAppSelector';
import { ReducersList } from '../../../shared/components/DynamicModuleLoader/DynamicReducerLoader.types';

import { DynamicReducerLoader } from '../../../shared/components/DynamicModuleLoader/DynamicReducerLoader';

import { StateSchema } from '../../../app/providers/StoreProvider/config/StateSchema';

import Text from '../../../shared/lib/widgets/Text';
import Container from '../../../shared/lib/widgets/Container';
import RowWidget from '../../../shared/lib/widgets/RowWidget';
import GridWidget from '../../../shared/lib/widgets/GridWidget';


const reducers: ReducersList = {
    agents: agentsReducer,
}

const AiMarketPlace: React.FC = () => {
    const { t } = useTranslation();
    
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchAgents());
    }, [dispatch]);

    const user = useAppSelector((state: StateSchema) => state.user.userData);
    const agents = useAppSelector(selectAgents);

    return (
        <Container>
            <SubHeader 
                title='MarketPlace'
                to='/subscription-plan'
                leading={MarketIcon}
                border='1px solid #E4E4E4'
            />

            <Container $height='calc(100vh - 140px)' $backgroundColor='#F9FAFC' $padding='20px'>
                <Container $height='8px' />

                <Text fontSize='26px' fontWeight="600">{t('subscriptions.marketplace.title')}</Text>
                
                <Container $height='16px' />

                <DynamicReducerLoader reducers={reducers}>
                    {
                        agents.length === 0 ?
                            <Container $borderRadius='16px' $border='1px solid grey' $width='100%'>
                                <RowWidget $justifyContent='center' $alignItems='center' $height='80px'>
                                    <Text fontSize='18px' color='grey'>{t('empty_list.record')}</Text>
                                </RowWidget>
                            </Container>
                        :
                            <GridWidget $columnsMinWidth='650px' $gap='16px'>
                                {
                                    agents.map((item: Agent) => {
                                        const isSubsciptionValid = checkAgentSubscription(user?.agent_ids, item.id)

                                        return <AgentCard 
                                            key={item.id}
                                            agentInfo={item}
                                            isPaid={!!isSubsciptionValid}
                                        />
                                    })
                                }
                            </GridWidget>
                    }
                </DynamicReducerLoader>
            </Container>
        </Container >
    );
};

export default AiMarketPlace;

