import React from "react";

import styled from "styled-components";


interface GridWidgetProps {
    $columnsMinWidth: string;
    $gap?: string;
    children: React.ReactNode;
}

const GridStyle = styled.div<GridWidgetProps>`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(${(props) => props.$columnsMinWidth}, 1fr));
    gap: ${(props) => props.$gap || '0'};
`;

const GridWidget: React.FC<GridWidgetProps> = ({ $columnsMinWidth, $gap, children }) => {
    return (
        <GridStyle $columnsMinWidth={$columnsMinWidth} $gap={$gap}>
            {children}
        </GridStyle>
    );
}

export default GridWidget;
