import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import { deleteCase, fetchCases, updateCase } from "../services/cases.services";
import { CasesState } from "../types/Cases.types";

import { Case } from "../../../Case/models/types/Case.types";

import { StateSchema } from "../../../../app/providers/StoreProvider/config/StateSchema";
import { newLegalCase } from "../../../Case/models/services/case.services";


const casesAdapter = createEntityAdapter<Case, string>({
    selectId: (caseItem) => caseItem.id,
});

export const getCases = casesAdapter.getSelectors<StateSchema>(
    (state) => state.cases || casesAdapter.getInitialState(),
);

const initialState: CasesState = casesAdapter.getInitialState({
    isLoading: false,
});

const CasesSlice = createSlice({
    name: 'cases',
    initialState: initialState,
    reducers: {
        init: (state) => {
            casesAdapter.removeAll(state);
        },
        updateCases: (state, action) => {
            casesAdapter.updateOne(state, { id: action.payload.id, changes: action.payload });
        },
        logout: (state) => {
            casesAdapter.removeAll(state);
        },
    },
    extraReducers: (builder) => {
        // Fetch All Cases
        builder
            .addCase(fetchCases.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchCases.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            })
            .addCase(fetchCases.fulfilled, (state, action) => {
                state.isLoading = false;
                if (action.payload !== null)
                    casesAdapter.addMany(state, action.payload);
            });
        // Create Cases
        builder
            .addCase(newLegalCase.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(newLegalCase.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            })
            .addCase(newLegalCase.fulfilled, (state, action) => {
                state.isLoading = false;
                casesAdapter.addOne(state, action.payload);
            });
        // Update Cases
        builder
            .addCase(updateCase.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateCase.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            })
            .addCase(updateCase.fulfilled, (state, action) => {
                state.isLoading = false;
                casesAdapter.updateOne(state, { id: action.payload.id, changes: action.payload });
            });
        // Delete Cases
        builder
            .addCase(deleteCase.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteCase.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            })
            .addCase(deleteCase.fulfilled, (state, action) => {
                state.isLoading = false;
                casesAdapter.removeOne(state, action.payload);
            });
    },
});


export const { 
    reducer: casesReducer, 
    actions: casesActions,
} = CasesSlice;
