import { useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { StateSchema } from '../../app/providers/StoreProvider/config/StateSchema';

import { useAppDispatch } from '../../shared/components/useAppDispatch/useAppDispatch';
import { useAppSelector } from '../../shared/components/useAppSelector/useAppSelector';

import { Member } from '../../entities/Team/models/types/Team.types';
import { addMember, changeAdmin, fetchTeam, removeMember } from '../../entities/Team/models/services/team.services';

import PlusIcon from '../../assets/PlusIconBlue.svg';

import DeletePopup from '../Popups/DeletePopup';
import TeamMemberPopup from '../Popups/TeamMemberPopup';

import SecondaryButton from '../Buttons/SecondaryButton';

import TeamTable from '../Tables/TeamTable';

import TeamMemberCard from '../Cards/TeamMemberCard';

import Text from '../../shared/lib/widgets/Text';
import Container from '../../shared/lib/widgets/Container';
import RowWidget from '../../shared/lib/widgets/RowWidget';
import Loader from '../../shared/lib/widgets/Loader';


const TeamManagement = () => {
    const { t } = useTranslation();
    
    const dispatch = useAppDispatch();
    
    const user = useAppSelector((state: StateSchema) => state.user.userData);
    const teamState = useAppSelector((state: StateSchema) => state.team);
    const team = teamState?.team;

    useEffect(() => {
        dispatch(fetchTeam(user!.team_id));
    }, [dispatch, user]);

    const isAdmin = user?.id === team?.admin.id;

    const [memberData, setMemberData] = useState<Member>();

    const [addNewMember, setAddNewMember] = useState(false);
    const [editMember, setEditMember] = useState(false);
    const [deleteMember, setDeleteMember] = useState(false);

    const handleEditClick = (member: Member) => {
        setEditMember(true);
        setMemberData(member)
    }

    const updateMember = () => {
        dispatch(addMember({ id: team?.id, email: team?.admin.email }));
        dispatch(changeAdmin({ id: team?.id, email: memberData?.email }));
        dispatch(removeMember({ id: team?.id, memberId: memberData?.id }));
        setEditMember(false);
    }

    const handleDeleteClick = (member: Member) => {
        setDeleteMember(true);
        setMemberData(member)
    }

    const deleteMembers = () => {
        if (team?.admin.id !== user?.id)
            return toast.error("Utente non ha il permesso per eliminare un membro dal team!");

        dispatch(removeMember({ id: team?.id, memberId: memberData?.id }));
        setDeleteMember(false);
    };

    // const handleNewMember = (email: string, selectedFrequency = false) => {
    //     if (selectedFrequency) {
    //         dispatch(addMember({ id: team?.id, email: team?.admin.email }));
    //         dispatch(changeAdmin({ id: team?.id, email: email }));
    //     } else
    //         dispatch(addMember({ id: team?.id, email: email }));

    //     setAddNewMember(false);
    // }
    
    if (!team || teamState?.isLoading) {
        return (
            <RowWidget $justifyContent='center' $alignItems='center' $height='60vh' $width='100%'>
                <Loader />
            </RowWidget>
        )
    }

    return (
        <Container>
            {
                addNewMember && 
                <TeamMemberPopup 
                    onSubmit={() => toast.info(t('messages.info.service_not_available'))} 
                    onClose={() => setAddNewMember(false)} 
                />
            }

            {
                editMember && 
                <TeamMemberPopup 
                    userData={memberData}
                    onSubmit={updateMember} 
                    onClose={() => setEditMember(false)} 
                />
            }

            {
                deleteMember && 
                <DeletePopup 
                    onSubmit={deleteMembers} 
                    onClose={() => setDeleteMember(false)} 
                    text1={<div>Vuoi davvero eliminare il membro del team <b>{memberData?.first_name} {memberData?.last_name}</b>.</div>}
                    text2={'Questo processo non può essere annullato, ma tutti i materiali creati da questo utente verranno salvati.'}
                />
            }

            <RowWidget $justifyContent='space-between' $alignItems='start' $flexWrap='wrap' $gap='16px'>
                <Container>
                    <Text fontWeight="600" fontSize='20px' >Membri del team</Text>

                    <Container $height='10px' />

                    <Text fontWeight="400" fontSize='16px' color='#908F94'>Gestisci i membri del tuo team e le autorizzazioni del loro account qui.</Text>
                </Container>

                {
                    isAdmin ?
                        <SecondaryButton
                            onClick={() => setAddNewMember(true)}
                            leading={PlusIcon}
                            title='Aggiungi membro del team'
                            $width='200px'
                        />
                        : null
                }
            </RowWidget>

            <Container $height='60px' />
            
            <RowWidget $justifyContent='space-between' $alignItems='start' $flexWrap='wrap' $gap='16px'>
                <Container $width='270px'>
                    <Text fontWeight="600" fontSize='20px'>Utente amministratore</Text>

                    <Container $height='10px' />

                    <Text fontWeight="400" fontSize='16px' color='#908F94'>L'amministratore può aggiungere e rimuovere utenti e gestire gli indirizzi di fatturazione.</Text>
                </Container>

                <TeamTable>
                    <TeamMemberCard 
                        memberData={team!.admin} 
                        onEditClick={() => handleEditClick(team!.admin)}
                        onDeleteClick={() => handleDeleteClick(team!.admin)}
                        isAdmin={isAdmin} 
                    />
                </TeamTable>
            </RowWidget>

            <Container $height='60px' />
            
            <RowWidget $justifyContent='space-between' $alignItems='start' $flexWrap='wrap' $gap='16px'>
                <Container $width='270px'>
                    <Text fontWeight="600" fontSize='20px'>Utenti dell'account</Text>

                    <Container $height='10px' />

                    <Text fontWeight="400" fontSize='16px' color='#908F94'>Gli utenti dell'account possono creare e lavorare con casi legali, ma non possono gestire team e indirizzi di fatturazione.</Text>
                </Container>

                <TeamTable>
                    {
                        teamState?.team?.members.map((member: Member) =>
                            <TeamMemberCard 
                                key={member.id}
                                memberData={member}
                                onEditClick={() => handleEditClick(member)}
                                onDeleteClick={() => handleDeleteClick(member)} 
                                isAdmin={isAdmin}
                            />
                        )
                    }
                </TeamTable>
            </RowWidget>
        </Container >
    )
}

export default TeamManagement;