import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import i18n from 'i18next';

export const fetchCaseById = createAsyncThunk(
    'case/fetchCaseById',
    async (id: string, thunkApi: any) => {
        const { extra, rejectWithValue } = thunkApi;

        try {
            const response = await extra.api.get(`/cases/${id}/`)
            return response.data;
        } catch (error) {
            toast.error(i18n.t("messages.errors.cases.search"));
            return rejectWithValue('Case fetch error!');
        }
    }
);

export const newLegalCase = createAsyncThunk(
    'case/newLegalCase',
    async (name: string, thunkApi: any) => {
        const { extra, rejectWithValue } = thunkApi;

        try {
            const response = await extra.api.post(`/cases-create/`, { name })
            toast.success(i18n.t('messages.success.new_legal_case_created'))
            return response.data;
        } catch (error) {
            toast.error(i18n.t("messages.errors.cases.create"));
            return rejectWithValue('Case creation error!');
        }
    }
);

export const editCase = createAsyncThunk(
    'case/editCase',
    async (caseData: any, thunkApi: any) => {
        const { extra, rejectWithValue } = thunkApi;

        try {
            const response = await extra.api.patch(`/cases/${caseData.id}/`, caseData);
            return response.data;
        } catch (error) {
            toast.error(i18n.t("messages.errors.cases.update"));
            return rejectWithValue('Case edit error!');
        }
    }
);

export const addCollaborator = createAsyncThunk(
    'case/addCollaborator',
    async ({id, email, action}: any, thunkApi: any) => {
        const { extra, rejectWithValue } = thunkApi;

        try {
            const response = await extra.api.post(`/case-add-user/${id}/`, {email: email, edit: action});

            return {
                    id: response.data.id,
                    image: response.data.image,
                    first_name: response.data.first_name,
                    last_name: response.data.last_name,
                    email: response.data.email,
                    edit: false
                };
        } catch (error) {
            toast.error(i18n.t("messages.errors.cases.update_collaborator"));
            return rejectWithValue('Add collaborator error!');
        }
    }
)

export const updateCollaborator = createAsyncThunk(
    'case/updateCollaborator',
    async ({id, email, edit}: any, thunkApi: any) => {
        const { extra, rejectWithValue } = thunkApi;

        try {
            const response = await extra.api.post(`/case-add-user/${id}/`, {email: email, edit: edit});

            return response.data;
        } catch (error) {
            toast.error(i18n.t("messages.errors.cases.update_collaborator"));
            return rejectWithValue('Add collaborator error!');
        }
    }
)
