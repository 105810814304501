import React, { useState } from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";

import SadFace from '../../assets/faces/sad.svg';
import HappyFace from '../../assets/faces/happy.svg';
import AngryFace from '../../assets/faces/angry.svg';
import NormalFace from '../../assets/faces/normal.svg';
import SmilingFace from '../../assets/faces/smiling.svg';

import Popup from "./Popup";

import InputTextArea from "../Inputs/InputTextArea";

import PrimaryButton from "../Buttons/PrimaryButton";
import SecondaryButton from "../Buttons/SecondaryButton";

import Text from "../../shared/lib/widgets/Text";
import RowWidget from "../../shared/lib/widgets/RowWidget";
import Container from "../../shared/lib/widgets/Container";


const FeedbackEmoji = styled.div<{ $isActive: boolean }>`
    width: 100px; 
    min-width: 100px;
    cursor: pointer;
    padding: 12px;
    border-radius: 8px; 
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    border: 1px solid ${(props) => props.$isActive ? '#4158E3' : 'transparent'};
`;

interface FeedbackProps {
    onSubmit: () => void;
    onClose: () => void;
}

const Feedback: React.FC<FeedbackProps> = ({ onSubmit, onClose }) => {
    const { t } = useTranslation();
    
    const [feedbackEmotion, setFeedbackEmotion] = useState('');
    const [feedbackItem, setFeedbackItem] = useState('');

    const feedbackItems = [
        {
            img: HappyFace,
            key: 'chat.popup.feedback.emoji.amazing',
        },
        {
            img: SmilingFace,
            key: 'chat.popup.feedback.emoji.good',
        },
        {
            img: NormalFace,
            key: 'chat.popup.feedback.emoji.okey',
        },
        {
            img: SadFace,
            key: 'chat.popup.feedback.emoji.bad',
        },
        {
            img: AngryFace,
            key: 'chat.popup.feedback.emoji.terrible',
        },
    ];

    return (
        <Popup title={t('chat.popup.feedback.title')} subTitle={t('chat.popup.feedback.subtitle')} width="620px" onClose={onClose}>
            <Container>
                <RowWidget $justifyContent="space-between">
                    {
                        feedbackItems.map((item, index) => 
                        <FeedbackEmoji 
                            key={index} 
                            $isActive={feedbackEmotion === item.key}
                            onClick={() => {
                                setFeedbackEmotion(item.key);
                                setFeedbackItem('');
                            }}
                        >
                            <img src={item.img} alt="icon" width="80%" />

                            <Text $textAlign="center" color="#153968" fontWeight="bold">{t(`${item.key}.title`)}</Text>
                        </FeedbackEmoji>)
                    }
                </RowWidget>
                
                <RowWidget $flexWrap="wrap" $margin='24px 0' $gap='10px'>
                    {
                        feedbackItems
                            .filter(i => i.key === feedbackEmotion)
                            .flatMap(i => t(`${i.key}.items`, { returnObjects: true }) as string[])
                            .map((item, index) =>
                                <Container key={index} onClick={() => setFeedbackItem(item)} $padding="4px 12px" $backgroundColor={feedbackItem === item ? '#ECEEFC' : "#F2F4F6"} $borderRadius="8px" $cursor="pointer" $border={`1px solid ${feedbackItem === item ? '#4158E3' : 'transparent'}`} style={{ flexGrow: '1' }}>
                                    <RowWidget $gap="4px" $justifyContent="center">
                                        {
                                            feedbackItem === item ? 
                                                <Text color={feedbackItem === item ? '#4158E3' : '#153968'}>&#10003;</Text>
                                                : null
                                        }
                                        <Text color={feedbackItem === item ? '#4158E3' : '#153968'}>{item}</Text>
                                    </RowWidget>
                                </Container>
                            )
                    }
                </RowWidget>

                {
                    feedbackItem &&
                        <Container $margin="0 0 32px 0">
                            <Text fontWeight="bold">{t('chat.popup.feedback.description')}</Text>
                            <Container $height="8px" />
                            <InputTextArea 
                                rows={7}
                            />
                        </Container>
                }

                <RowWidget $justifyContent="space-between" $alignItems="center" $gap="16px">
                    <SecondaryButton 
                        onClick={onClose}
                        title={t('button.cancel')}
                        $width='120px'
                        $borderColor='#E9E9EA'
                    />
                    <PrimaryButton 
                        onClick={onSubmit}
                        title={t('button.send')}
                        $width='180px'
                    />
                </RowWidget>
            </Container>
        </Popup>
    );
};

export default Feedback;
