import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Bag from '../assets/Bag.svg';

import { StateSchema } from '../app/providers/StoreProvider/config/StateSchema';

import SecondaryButton from '../components/Buttons/SecondaryButton';

import SubscribetAgentCard from '../components/Cards/SubscribedAgentCard';

import PurchasedAssistantTable from '../components/Tables/PurchasedAssistantsTable';
import SubscribedCard from '../components/Cards/SubscribedCard';

import { selectUserAgents, userAgentReducer } from '../features/userAgents/models/slices/userAgentsSlice';

import { Agent } from '../entities/Agents/models/types/Agent.types';
import { teamReducer } from '../entities/Team/models/slices/TeamSlice';
import { fetchUserAgents } from '../entities/Agents/models/services/agents.services';

import { formatDate } from '../shared/utils/date_format';
import { useAppDispatch } from '../shared/components/useAppDispatch/useAppDispatch';
import { useAppSelector } from '../shared/components/useAppSelector/useAppSelector';
import { ReducersList } from '../shared/components/DynamicModuleLoader/DynamicReducerLoader.types';
import { DynamicReducerLoader } from '../shared/components/DynamicModuleLoader/DynamicReducerLoader';

import Text from '../shared/lib/widgets/Text';
import RowWidget from '../shared/lib/widgets/RowWidget';
import Container from '../shared/lib/widgets/Container';
import CreditCard from '../components/Cards/CreditCard';


const reducers: ReducersList = {
    userAgents: userAgentReducer,
    team: teamReducer,
}

const SubscriptionPlan: React.FC = () => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const user = useAppSelector((state: StateSchema) => state.user.userData);

    useEffect(() => {
        dispatch(fetchUserAgents());
    }, [dispatch]);

    const agents = useAppSelector(selectUserAgents);

    const navigate = useNavigate();

    return (
        <DynamicReducerLoader reducers={reducers}>
            <Container $padding='20px'>
                <Container $height='12px' />

                <Text fontSize='32px' fontWeight='bold'>{t('subscriptions.title')}</Text>

                <Container $height='32px' />

                <RowWidget $justifyContent='space-between' $alignItems='center' $gap='32px' $flexWrap='wrap'>
                    <SubscribedCard />

                    <CreditCard />
                </RowWidget>

                <Container $height='64px' />

                <RowWidget $alignItems='center' $justifyContent='space-between'>
                    <Text fontWeight='bold' fontSize='24px'>{t('subscriptions.section.my_purchases')}</Text>

                    <SecondaryButton
                        onClick={() => navigate('/marketplace')}
                        leading={Bag}
                        title='Marketplace'
                        $color='#4158E3'
                        $width='200px'
                        $borderColor='#4158E3'
                    />
                </RowWidget>

                <Container $height='32px' />

                <PurchasedAssistantTable />

                <Container $height='16px' />

                {
                    agents.length === 0 ?
                            <Container $borderRadius='16px' $border='1px solid grey' $width='100%'>
                                <RowWidget $justifyContent='center' $alignItems='center' $height='80px'>
                                    <Text fontSize='18px' color='grey'>{t('empty_list.record')}</Text>
                                </RowWidget>
                            </Container>
                        :
                        agents.map((item: Agent) => {
                            const today = new Date();
                            const oneMonthAgo = new Date(today.setFullYear(today.getFullYear(), today.getMonth() - 1));

                            const agentPurc = user?.agent_ids.find(purchase => purchase.id === item.id);
                            const purchaseDate = agentPurc ? new Date(agentPurc.date) : null;
                            const isWithinLastMonth = purchaseDate && purchaseDate > oneMonthAgo;

                            return <SubscribetAgentCard
                                key={item.id}
                                agentInfo={item}
                                purchaseDate={purchaseDate ? formatDate(purchaseDate) : "-"}
                                isPaid={!!isWithinLastMonth}
                            />
                        })
                }
            </Container >
        </DynamicReducerLoader>
    )
}

export default SubscriptionPlan;