import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import SubHeader from '../../SubHeader/SubHeader';

import TutorialCard from '../../Cards/TutorialCard';

import DropDown from '../../DropDown/DropDown';

import InputSearch from '../../Inputs/InputSearch';

import Text from '../../../shared/lib/widgets/Text';
import RowWidget from '../../../shared/lib/widgets/RowWidget';
import Container from '../../../shared/lib/widgets/Container';


const ArticlesAndGuides: React.FC = () => {
    const { t } = useTranslation();
    
    const [articlesAndGuides, setArticlesAndGuides] = useState([]);
    
    const fetchArticlesAndGuides = () => {
        setArticlesAndGuides([]);
    }

    useEffect(() => {
        fetchArticlesAndGuides();
    }, [])
    
    const [difficulty, setDifficulty] = useState('');
    const [category, setCategory] = useState('');

    const difficultyList = [t('dropdown.difficulty.beginner'), t('dropdown.difficulty.intermediate'), t('dropdown.difficulty.advanced')];
    
    const [filterOptions, setFilterOptions] = useState<any | null>(null);
    
    const handleDifficultyChange = (value: string) => {
        setDifficulty(value);
        setFilterOptions({ type: 'difficulty', value: `${difficultyList.indexOf(value) + 1}` });
    }
    const handleCategoryChange = (value: string) => {
        setCategory(value);
        setFilterOptions({ type: 'category', value });
    }

    const getFilteredArticles = useCallback((articles: any, filter: any | null) => {
        if (!filter) return articles;

        const filteredData = [...articles];

        if (filter.type === 'difficulty')
            filteredData.sort((a, b) => {
                if (a.level === filter.value) return -1;
                if (b.level === filter.value) return 1;
                return 0;
            });

        // if (filter.type === 'category')
            // Filter for category type

        return filteredData;
    }, []);

    const filteredArticles = useMemo(() => getFilteredArticles(articlesAndGuides, filterOptions), [articlesAndGuides, filterOptions, getFilteredArticles]);

    return (
        <Container>
            <SubHeader title={t('academy.title')} to='/academy' border='1px solid #E4E4E4' />

            <Container $height='40px' />
            
            <Text fontSize='32px' fontWeight='bold' $textAlign='center'>{t('academy.articles_and_guides.title')}</Text>

            <Container $height='10px' />

            <Text color='#908F94' fontWeight='400' $textAlign='center'>{t('academy.articles_and_guides.sub_description')}</Text>
            
            <Container $height='24px' />

            <RowWidget $justifyContent='center' $gap='16px' $flexWrap='wrap'>
                <InputSearch 
                    placeholder={t('dropdown.filter.search')}
                    someData={articlesAndGuides}
                />

                <DropDown 
                    width='280px'
                    dropDownName={t('dropdown.difficulty.title')}
                    value={difficulty}
                    onChange={handleDifficultyChange}
                    menuItems={difficultyList}
                />

                <DropDown 
                    width='280px'
                    dropDownName={t('dropdown.category.title')}
                    value={category}
                    onChange={handleCategoryChange}
                    menuItems={[t('dropdown.category.platform_functionality'), t('dropdown.category.legal_domains'), t('dropdown.category.workflow_optimization')]}
                />
            </RowWidget>
            
            <Container $height='32px' />

            <RowWidget $justifyContent='center' $gap='16px' $flexWrap='wrap'>
                {
                    filteredArticles.length === 0 ?
                        <Container $borderRadius='16px' $border='1px solid grey' $width='100%' $margin='0 16px'>
                            <RowWidget $justifyContent='center' $alignItems='center' $height='80px'>
                                <Text fontSize='18px' color='grey'>{t('empty_list.record')}</Text>
                            </RowWidget>
                        </Container>
                        :
                        filteredArticles.map((item: any, index: number) => 
                            <TutorialCard
                                key={index}
                                src={item.image}
                                link={item.link}
                                title={item.name}
                                description={item.description}
                                level={item.level} 
                            />
                        )
                }
            </RowWidget>

            <Container $height='32px' />
        </Container >
    );
};

export default ArticlesAndGuides;

