import styled from "styled-components";


interface ContainerProps {
    $width?: string;
    $height?: string;
    $flex?: string;
    $margin?: string;
    $padding?: string;
    $border?: string;
    $borderRadius?: string;
    $backgroundColor?: string;
    $cursor?: string;
}

const Container = styled.div<ContainerProps>`
    width: ${({ $width }) => $width || 'auto'};
    height: ${({ $height }) => $height || 'auto'};
    flex: ${({ $flex }) => $flex || '0 1 auto'};
    margin: ${({ $margin }) => $margin || '0'};
    padding: ${({ $padding }) => $padding || '0'};
    border: ${({ $border }) => $border || 'none'};
    border-radius: ${({ $borderRadius }) => $borderRadius || '0'};
    background-color: ${({ $backgroundColor }) => $backgroundColor || 'transparent'};
    cursor: ${({ $cursor }) => $cursor || 'default'};
`;

export default Container;
