// import VideoTutorialIcon from '../../assets/images/video_tutorial.png';
// import PracticalExamplesIcon from '../../assets/images/practical_examples.png';
// import ArticlesAndGuidesIcon from '../../assets/images/articles_and_guides.png';
// import WebinarsIcon from '../../assets/images/webinars.png';

import ArticleIcon from '../../assets/ArticlesIcon.svg';
import PracticalIcon from '../../assets/PracticalIcon.svg';
import VideoIcon from '../../assets/VideoTutorialIcon.svg';
import WebinarIcon from '../../assets/WebinarsIcon.svg';


export const faqTexts = [
    'update_plan',
    'forgot_cancel_plan',
    'update_credit_card',
    'cancel_plan',
    'add_users_to_subscription'
];


export const academyList = [
    {
        path: '/academy/video-tutorial',
        icon: VideoIcon,
        title: 'video_tutorial',
        backgroundColor: '#FFEEEE',
    },
    {
        path: '/academy/articles-guides',
        icon: ArticleIcon,
        title: 'articles_and_guides',
        backgroundColor: '#ECEEFC',
    },
    {
        path: '/academy/practical',
        icon: PracticalIcon,
        title: 'practical_examples',
        backgroundColor: '#EDF8E8',
    },
    {
        path: '/academy/webinar',
        icon: WebinarIcon,
        title: 'webinars_and_training_sessions',
        backgroundColor: '#E8EBF0',
    },
];