import React from "react";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import Input from "../Inputs/Input";

import Container from "../../shared/lib/widgets/Container";
import RowWidget from "../../shared/lib/widgets/RowWidget";

import PrimaryButton from "../Buttons/PrimaryButton";
import SecondaryButton from "../Buttons/SecondaryButton";


interface BillingFormsProps {
    onClose?: () => void;
}

const PhysicalPersonForm: React.FC<BillingFormsProps> = ({ onClose }) => {
    const { t } = useTranslation();
    
    return (
        <RowWidget $flexDirection="column" $margin="16px 0 0 0">
            <RowWidget>
                <Input
                    label={t('input.user_name_label')}
                    placeholder={t('input.user_name_placeholder')}
                />
                <Container $width="24px" />
                <Input
                    label={t('input.user_surname_label')}
                    placeholder={t('input.user_surname_placeholder')}
                />
            </RowWidget>

            <Container $height="16px" />

            <Input
                label={t('settings.billing_and_payments.forms.residential_address.label')}
                placeholder={t('settings.billing_and_payments.forms.residential_address.placeholder')}
            />

            <Container $height="16px" />

            <Input
                label={t('settings.billing_and_payments.forms.tax.label')}
                placeholder={t('settings.billing_and_payments.forms.tax.placeholder')}
            />

            <RowWidget $justifyContent="space-between" $margin="32px 0 0 0" $gap="16px">
                <SecondaryButton
                    onClick={onClose}
                    title={t('button.cancel')}
                    $width='120px'
                    $borderColor='#E9E9EA'
                />
                <PrimaryButton
                    onClick={() => toast.info(t('messages.info.service_not_available'))}
                    title={t('button.update')}
                    $width='180px'
                />
            </RowWidget>
        </RowWidget>
    );
}

export default PhysicalPersonForm;