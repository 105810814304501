import React, { 
    forwardRef, 
    // useCallback, 
    useEffect, 
    useMemo, 
    useRef, 
    useState } from 'react';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';

import User from '../assets/user.svg';

import useChat from '../app/providers/socket/socket';
import { StateSchema } from '../app/providers/StoreProvider/config/StateSchema';

import ChatSidebar from '../components/Sidebar/ChatSidebar';
import MessageCard from '../components/Cards/MessageCard';
import SubHeader from '../components/SubHeader/ChatSubHeader';
import ShareCasePopup from '../components/Popups/ShareCasePopup';
import SendMessageCard from '../components/Cards/SendMessageCard';

// import { Message } from '../entities/Case/models/types/Case.types';
import { caseReducer } from '../entities/Case/models/slices/CaseSlice';
import { fetchCaseById, updateCollaborator } from '../entities/Case/models/services/case.services';

import { useAppSelector } from '../shared/components/useAppSelector/useAppSelector';
import { useAppDispatch } from '../shared/components/useAppDispatch/useAppDispatch';
import { ReducersList } from '../shared/components/DynamicModuleLoader/DynamicReducerLoader.types';
import { DynamicReducerLoader } from '../shared/components/DynamicModuleLoader/DynamicReducerLoader';

import Text from '../shared/lib/widgets/Text';
import Loader from '../shared/lib/widgets/Loader';
import Container from '../shared/lib/widgets/Container';
import RowWidget from '../shared/lib/widgets/RowWidget';


const ChatStyle = styled.div`
    background-color: #F9FAFC;
    width: 100%;
    height: calc(100vh - 150px);
    padding: 30px 0 200px 0;
    overflow-x: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-track {
        background: transparent; 
    }
    &::-webkit-scrollbar-thumb {
        cursor: pointer;
        border-radius: 40px;
        background: #9fa0a1; 
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #aaa; 
    }
`;

const reducers: ReducersList = {
    case: caseReducer,
};

const Chat = () => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    
    const containerRef = useRef<HTMLDivElement>(null);
    const itemRefs = useRef<Record<number, HTMLDivElement>>({});

    
    const [shareLink, setShareLink] = useState(false)

    const [sidebarExpanded, setSidebarExpanded] = useState(true)

    // const [loading, setLoading] = useState(false);
    // const [hasLoadedMore, setHasLoadedMore] = useState(false);

    // const [moreMessages, setMoreMessages] = useState<Message[]>([]);

    const user = useAppSelector((state: StateSchema) => state.user);
    const caseData = useAppSelector((state: StateSchema) => state.case);
    
    const { roomId } = useParams<{ roomId: string }>();
    const { messages, sendMessage, isQueueComplete } = useChat(roomId as string);
    
    useEffect(() => {
        dispatch(fetchCaseById(roomId as string));

        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    }, [dispatch, roomId]);

    const messagesList = useMemo(() => {
        return [
            // ...moreMessages, 
            ...(caseData?.case?.messages || []), ...messages];
    }, [
        // moreMessages, 
        caseData, 
        messages
    ]);

    useEffect(() => {
        if (containerRef.current)
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }, [messagesList, 
        // loading, 
        t]);

    const MessageCardRef = forwardRef(
        ({ messageData }: { messageData: any }, ref: React.LegacyRef<HTMLDivElement>) => {
            return (
                <div ref={ref}>
                    <MessageCard
                        messageText={messageData.content} 
                        image={messageData.sender === 'user_proxy' ? user.userData?.image || User : ''}
                    />
                </div>
            );
        }
    );

    // const fetchMoreMessages = useCallback(async () => {
    //     setLoading(true);
    //     try {
    //         const oldMessages = await $api.get(`/case/old-messages/${roomId}`);
    //         setMoreMessages((prevMessages) => [...oldMessages.data, ...prevMessages]);
    //     } catch (_) {
    //         toast.info(t('messages.info.no_messages'));
    //     } finally {
    //         setHasLoadedMore(true);
    //         setLoading(false);
    //         setShowPopup(false);
    //     }
    // }, [roomId, t]);

    // const onScroll = useCallback(
    //     (e: React.UIEvent<HTMLDivElement>) => {
    //         const { scrollTop } = e.target as HTMLDivElement;
    //         if (scrollTop <= 0 && !loading && !hasLoadedMore) {
    //             setShowPopup(true);
    //         } else {
    //             setShowPopup(false);
    //         }
    //     },
    //     [loading, hasLoadedMore]
    // );
    
    const handlerSendMessage = (message: string) => {
        sendMessage(message);
    }
    
    if (!caseData || !user || caseData.isLoading || user.isLoading) {
        return (
            <DynamicReducerLoader reducers={reducers}>
                <RowWidget $justifyContent='center' $alignItems='center' $height='80vh'>
                    <Loader />
                </RowWidget>
            </DynamicReducerLoader>
        );
    }
    
    const isCollaborator = caseData?.case?.collaborators?.some(coll => coll.id === user.userData?.id);
    if (caseData.case?.creator_id !== user?.userData?.id && !isCollaborator) {
        return (
            <Container $border='1px solid grey' $borderRadius='16px' color='grey' $margin='16px' $height='80px'>
                <RowWidget $justifyContent='center' $alignItems='center' $height='100%'>
                    <Text color='grey' fontSize='18px'>{t('empty_list.no_access')} <Link style={{ marginLeft: '8px', color: '#4158E3', fontWeight: 'bold' }} to='/'>Home</Link></Text>
                </RowWidget>
            </Container>
        )
    }

    return (
        <DynamicReducerLoader reducers={reducers}>
            {
                shareLink &&
                    <ShareCasePopup 
                        onSubmit={(item) => {
                            dispatch(updateCollaborator({ id: caseData?.case?.id, email: item.email, edit: !item.edit }))
                        }}
                        onClose={() => setShareLink(false)}
                    />
            }

            <SubHeader 
                title={caseData!.case!.name} 
                id={caseData!.case!.id} 
                showShareLink={() => setShareLink(true)}
            />

            <RowWidget>
                <ChatSidebar sidebarExpanded={sidebarExpanded} setSidebarExpanded={setSidebarExpanded} />

                <ChatStyle ref={containerRef} 
                // onScroll={onScroll}
                >
                    {
                        messagesList!.map((item, index) => (
                            <MessageCardRef
                                key={index}
                                ref={(el: HTMLDivElement) => (itemRefs.current[index] = el)}
                                messageData={item}
                            />
                        ))
                    }

                    <SendMessageCard
                        isLoading={isQueueComplete}
                        onClick={handlerSendMessage}
                        sidebarExpanded={sidebarExpanded}
                    />
                </ChatStyle>
            </RowWidget>
        </DynamicReducerLoader>
    );
}


export default Chat;