import React, { useState } from "react";

import styled from "styled-components";

import InputComponent from "./InputComponent";

import EyeIcon from '../../assets/EyeIcon.svg';
import EyeSlashIcon from '../../assets/EyeslashIcon.svg';


const InputContainer = styled.div`
    position: relative;
`;

const InputStyle = styled.input<{ $error?: boolean }>`
    width: 100%;
    height: 40px;
    padding: 10px 16px;
    font-size: 16px;
    border-radius: 6px;
    border: 2px solid ${(props) => props.$error ? '#FF5252' : 'transparent'};
    font-family: 'SF Pro Display';
    background-color: ${(props) => props.$error ? '#FFEEEE' : '#f6f7f9'};
    &:focus{
        border: 2px solid #4158E3;
        outline: none;
    }
    &::placeholder {
        color: #A4A3A7;
    }
    @media (max-width: 568px) {
        font-size: 14px;
    }
`;

const EyeContainer = styled.img`
    width: 16px;
    height: 16px;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
`;

interface InputPasswordProps {
    label?: string;
    backgroundColor?: string;
    name?: string;
    placeholder?: string;
    readOnly?: boolean;
    value?: string;
    onChange?: (e: any) => void;
    errorMessage?: string;
}

const InputPassword: React.FC<InputPasswordProps> = (props) => {
    const [inputVisible, setInputVisible] = useState(true);

    return (
        <InputComponent label={props.label} errorMessage={props.errorMessage}>
            <InputContainer>
                <InputStyle 
                    $error={!!props.errorMessage}
                    name={props.name}
                    type={inputVisible ? 'password' : 'text'}
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={props.onChange}
                    readOnly={props.readOnly}
                />
                <EyeContainer 
                    onClick={() => setInputVisible(!inputVisible)}
                    src={inputVisible ? EyeSlashIcon : EyeIcon} 
                    alt="eye" 
                />
            </InputContainer>
        </InputComponent>
    );
}

export default InputPassword;