import React, { useEffect } from 'react';

import styled from 'styled-components';

import { useAppDispatch } from '../../components/useAppDispatch/useAppDispatch';
import { useAppSelector } from '../../components/useAppSelector/useAppSelector';

import { fetchTeam } from '../../../entities/Team/models/services/team.services';

import { StateSchema } from '../../../app/providers/StoreProvider/config/StateSchema';

import Text from './Text';
import Container from './Container';


const Circle = styled.circle`
    fill: transparent;
    stroke: #eceefc;
    stroke-linecap: round;
`;

const FilledCircle = styled(Circle)`
    stroke: #4158e3;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    transition: stroke-dashoffset 0.5s ease-out;
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    color: #201f29;
    font-weight: bold;
    font-size: 38px;
`;

interface ProgressWidgetProps {
    maxSpace?: number;
}

const ProgressWidget: React.FC<ProgressWidgetProps> = ({ maxSpace = 10 }) => {
    const dispatch = useAppDispatch();
    const user = useAppSelector((state: StateSchema) => state.user.userData);

    useEffect(() => {
        dispatch(fetchTeam(user?.team_id as string));
    }, [dispatch, user?.team_id]);

    const team = useAppSelector((state: StateSchema) => state.team?.team);

    const progress = team?.members.length ?? 0;

    const strokeWidth = 6;
    const radius = (100 / 2) - (strokeWidth * 2);
    const circumference = radius * 2 * Math.PI;
    const offset = circumference - progress! / maxSpace * circumference;

    return (
        <Container style={{ position: 'relative', height: '190px' }}>
            <svg
                height='100%'
                width='100%'
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
            >
                <Circle
                    cx="50"
                    cy="50"
                    r={radius}
                    strokeWidth={strokeWidth}
                />

                <FilledCircle
                    cx="50"
                    cy="50"
                    r={radius}
                    strokeDasharray={`${circumference} ${circumference}`}
                    strokeDashoffset={offset}
                    strokeWidth={strokeWidth}
                />
            </svg>

            <TextContainer>
                <Text fontWeight="bold" fontSize='32px' $textAlign='center'>{progress}</Text>
                <Text fontWeight="bold" fontSize='16px' color='#a4a3a7' $textAlign='center'>su {maxSpace} utenti</Text>
            </TextContainer>
        </Container>
    );
};

export default ProgressWidget;
