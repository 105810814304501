import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import FolderIcon from '../../../assets/folder.svg';
import FilterIcon from '../../../assets/FilterIcon.svg';

import { filterOptions } from '../../../shared/consts/default_values';

import CaseCard from '../../../components/Cards/CaseCard';
import SubHeader from '../../../components/SubHeader/SubHeader';

import { Case } from '../../../entities/Case/models/types/Case.types';
import { getCases } from '../../../entities/Cases/models/slices/CasesSlice';
import { fetchCases } from '../../../entities/Cases/models/services/cases.services';

import { useAppSelector } from '../../../shared/components/useAppSelector/useAppSelector';
import { useAppDispatch } from '../../../shared/components/useAppDispatch/useAppDispatch';

import Text from '../../../shared/lib/widgets/Text';
import Container from '../../../shared/lib/widgets/Container';
import GridWidget from '../../../shared/lib/widgets/GridWidget';
import RowWidget from '../../../shared/lib/widgets/RowWidget';
import Tooltip from '../../../shared/lib/widgets/Tooltip';
import InputSearch from '../../Inputs/InputSearch';
import DropDown from '../../DropDown/DropDown';
import SortByDropdown from '../../DropDown/SortByDropdown';


const ArchivePage: React.FC = () => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchCases());
    }, [dispatch]);

    const cases = useAppSelector(getCases.selectAll).filter((caseData) => caseData.is_archived);

    const [timeFrame, setTimeFrame] = useState('');
    const [sortby, setSortby] = useState('');
    
    const filter_cases: any = () => {
        let filteredData = [...cases];
        if (timeFrame !== '')
            filteredData = filteredData.filter(caseItem => {
                const year = new Date(caseItem.creation_date).getFullYear();
                return year === parseInt(timeFrame);
            });

        if (sortby !== '')
            switch(sortby){
                case 'date_created':
                    filteredData
                    .sort((a: any, b: any) => new Date(a.creation_date).getTime() - new Date(b.creation_date).getTime())
                    break;
                case 'alphabetical':
                    filteredData.sort((a: any, b: any) => a.name.localeCompare(b.name));
                    break;
                case 'last_modified':
                    filteredData
                    .sort((a: any, b: any) => new Date(b.last_edit).getTime() - new Date(a.last_edit).getTime())
                    break;
                case 'a_z':
                    filteredData.sort((a: any, b: any) => a.name.localeCompare(b.name));
                    break;
                case 'z_a':
                    filteredData.sort((a: any, b: any) => b.name.localeCompare(a.name));
                    break;
            }
        
        return filteredData;
    };

    const filteredCases = filter_cases();

    return (
        <>
            <SubHeader
                title={t('header.my_cases')}
                to='/'
                border='1px solid #E4E4E4'
            />

            <Container $padding='20px' $backgroundColor='#F9FAFC' $height='calc(100vh - 140px)'>
                <Container $height='16px'/>

                <Text fontSize='28px' fontWeight="600">{t('header.archive')}</Text>

                <Container $height='24px' />

                <Container>
                    <RowWidget $flexWrap='wrap' $gap='16px'>
                        <Tooltip
                            title={t('tooltip.search.title')}
                            description={t('tooltip.search.description')}
                        >
                            <InputSearch placeholder={t('dropdown.filter.search')} someData={cases} />
                        </Tooltip>

                        <Tooltip
                            title={t('tooltip.timeframe.title')}
                            description={t('tooltip.timeframe.description')}
                        >
                            <DropDown 
                                width='200px'
                                dropDownName={t('dropdown.filter.timeframe')}
                                value={timeFrame} 
                                onChange={(e) => {
                                    setTimeFrame(e);
                                }} 
                                menuItems={filterOptions.year} 
                            />
                        </Tooltip>

                        <RowWidget $flex='1' $justifyContent='end'>
                            <Tooltip
                                title={t('tooltip.sort_by.title')}
                                description={t('tooltip.sort_by.description')}
                            >
                                <SortByDropdown 
                                    width='200px'
                                    dropDownName={t('dropdown.filter.sort_by')}
                                    value={sortby} 
                                    leading={FilterIcon}
                                    translation={'sort_by'}
                                    onChange={setSortby} 
                                    title1={t('dropdown.filter.sort_by')}
                                    title2={t('dropdown.filter.sort_by')}
                                    menuItems1={filterOptions.sort.slice(0, 3)} 
                                    menuItems2={filterOptions.sort.slice(3)} 
                                />
                            </Tooltip>
                        </RowWidget>
                    </RowWidget>

                    {
                        (timeFrame || sortby) ?
                            <RowWidget $margin="20px 0 0 0" $gap="8px">
                                <Text color="#7E7D83">{t('my_cases.search_query')} “{ timeFrame ? timeFrame : sortby ? t(`dropdown.sort_by.${sortby}`) : '' }”</Text>
                                <Text color="#7E7D83" fontWeight="bold" style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => {
                                    setTimeFrame('');
                                    setSortby('');
                                }}>{t(`button.cancel`)}</Text>
                            </RowWidget>
                            :
                            null
                    }
                </Container>

                <Container $height='24px' />

                {
                    cases.length === 0 ?
                        <RowWidget $flexDirection='column' $alignItems='center' $justifyContent='center' $margin='128px 0 0 0'>
                                <img src={FolderIcon} alt="icon" width="50px" height="50px"/>

                                <Container $height='16px' />

                                <Text $textAlign='center' color="#BBC6D3">{t('my_cases.no_cases')}</Text>
                        </RowWidget>
                        :
                        <GridWidget $columnsMinWidth='350px' $gap='16px'>
                            {
                                filteredCases?.map((item: Case) => (
                                    <CaseCard key={item.id} caseData={item} />
                                ))
                            }
                        </GridWidget>
                }
            </Container>
        </>
    );
}

export default ArchivePage;