import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";

import { StateSchema } from "../../../../app/providers/StoreProvider/config/StateSchema";
import { Agent, AgentState } from "../../../../entities/Agents/models/types/Agent.types";
import { fetchUserAgents } from "../../../../entities/Agents/models/services/agents.services";


const userAgentAdapter = createEntityAdapter<Agent, string>({
    selectId: (agentItem) => agentItem.id,
});

export const selectUserAgents = createSelector(
    [(state: StateSchema) => state.userAgents?.ids, (state: StateSchema) => state.userAgents?.entities],
    (ids, entities) => {
        if (!ids || !entities) {
            return [];
        }

        return ids.map(id => {
            const agent = entities[id];
            return {
                ...agent,
                displayName: `UserAgent ${agent.name}`,
            };
        });
    }
);

const initialState: AgentState = userAgentAdapter.getInitialState({
    ids: [],
    entities: {},
    isLoading: false,
})

const UserAgentSlice = createSlice({
    name: 'userAgents',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserAgents.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchUserAgents.fulfilled, (state, action) => {
                state.isLoading = false;
                if (action.payload !== null)
                    userAgentAdapter.addMany(state, action.payload);
            })
            .addCase(fetchUserAgents.rejected, (state) => {
                state.isLoading = false;
            });
    },
});

export const { 
    reducer: userAgentReducer, 
    actions: userAgentActions,
} = UserAgentSlice;
