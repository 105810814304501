export const yearNames = [
    '2024',
    '2023',
    '2022',
    '2021',
];

export const orderNames = [
    'date_created',
    'alphabetical',
    'last_modified',
    'a_z',
    'z_a',
];

export const filterOptions = {
    year: yearNames,
    sort: orderNames,
};
export const subscriptionPlans = (isUserSubscribed?: string) => {
    const subscriptions = [
        {
            id: '1',
            title:'PRO',
            priceMonth:20,
            priceYear:16,
            upgradeLabel:'Al mese',
            user:'1',
            items: 'subscriptions.subscription_type.pro',
            upgradeTo:'PRO',
            containPreviousPlan: false,
            isPlanPurchased: isUserSubscribed === '1',
        },
        {
            id: '2',
            title:'TEAM',
            priceMonth:60,
            priceYear:48,
            upgradeLabel:'Al mese',
            user:'20',
            items: 'subscriptions.subscription_type.team',
            upgradeTo:'TEAM',
            containPreviousPlan: true,
            isPlanPurchased: isUserSubscribed === '2',
        },
        {
            id: '3',
            title:'ENTERPRISE',
            priceMonth:'Contattaci',
            priceYear:'Contattaci',
            upgradeLabel:'',
            user:'∞',
            items: 'subscriptions.subscription_type.enterprise',
            upgradeTo:'Contattaci',
            containPreviousPlan: true,
            isPlanPurchased: isUserSubscribed === '3',
        },
    ];

    return subscriptions;
}

export const cancelSubscriptionCase = [
    "Troppo caro",
    "L'abbonamento è stato creato per sbaglio",
    "Scarsa qualità del prodotto",
    "Ho già più del necessario",
];
