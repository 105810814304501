import React from "react";

import styled from "styled-components";

import TooltipIcon from '../../../assets/TooltipIcon.svg';

import Text from "./Text";
import RowWidget from "./RowWidget";


const TooltipContainterStyle = styled.div`
    position: relative;
    cursor: pointer;
    @media (max-width: 532px) {
        flex: 1 1 300px;
    }
    &:hover {
        .tooltip {
            display: block;
        }
    }
`;

const TooltipStyle = styled.div<{ position?: string }>`
    display: none;
    width: 240px;
    background-color: #6174e8;
    border-radius: 10px;
    padding: 10px;
    position: absolute;
    z-index: 2;
    ${({ position }) => {
        switch (position) {
            case 'top':
                return `
                    bottom: calc(100% + 10px);
                    left: 50%;
                    transform: translateX(-50%);
                `;
            case 'right':
                return `
                    left: calc(100% + 10px);
                    top: 50%;
                    transform: translateY(-50%);
                `;
            case 'bottom':
                return `
                    top: calc(100% + 10px);
                    left: 50%;
                    transform: translateX(-50%);
                `;
            case 'left':
                return `
                    right: calc(100% + 10px);
                    top: 50%;
                    transform: translateY(-50%);
                `;
            default:
                return `
                    bottom: calc(100% + 10px);
                    left: 50%;
                    transform: translateX(-50%);
                `;
        }
    }}
`;

const Triangle = styled.div<{ position?: string }>`
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px;
    ${({ position }) => {
        switch (position) {
            case 'top':
                return `
                    bottom: -10px;
                    left: 50%;
                    transform: translateX(-50%);
                    border-color: #6174e8 transparent transparent transparent;
                `;
            case 'right':
                return `
                    right: 100%;
                    top: 50%;
                    transform: translateY(-50%);
                    border-color: transparent #6174e8 transparent transparent;
                `;
            case 'bottom':
                return `
                    top: -10px;
                    left: 50%;
                    transform: translateX(-50%);
                    border-color: transparent transparent #6174e8 transparent;
                `;
            case 'left':
                return `
                    left: 100%;
                    top: 50%;
                    transform: translateY(-50%);
                    border-color: transparent transparent transparent #6174e8;
                `;
            default:
                return `
                    bottom: -10px;
                    left: 50%;
                    transform: translateX(-50%);
                    border-color: #6174e8 transparent transparent transparent;
                `;
        }
    }};
`;

interface TooltipProps {
    position?: 'top' | 'right' | 'bottom' | 'left';
    title: string;
    description: string;
    children: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = (props) => {
    return (
        <TooltipContainterStyle>
            {props.children}
            <TooltipStyle className="tooltip" position={props.position}>
                <RowWidget $margin="0 0 6px 0" $gap="8px">
                    <img src={TooltipIcon} alt="icon" width='16px' height='16px' />

                    <Text fontSize="14px" fontWeight="bold" color="#FFF">{props.title}</Text>
                </RowWidget>

                <Text color="#FFF" fontSize="13px">{props.description}</Text>
                
                <Triangle position={props.position} />
            </TooltipStyle>
        </TooltipContainterStyle>
    );
}

export default Tooltip;