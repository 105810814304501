import { useEffect, useRef, useState } from 'react';

import styled from 'styled-components';
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { auth } from '../../configs/firebase-config';

import User from '../../assets/user.svg';
import DropDownImage from '../../assets/DropDown.svg';

import MyCasesIcon from '../../assets/headerIcon/blueFileIcon.svg';
import SubscriptionIcon from '../../assets/headerIcon/Subscriptions.svg';
import AcademyIcon from '../../assets/headerIcon/Academy.svg';
import SettingsIcon from '../../assets/headerIcon/settingIcon.svg';

import ExitIcon from '../../assets/exit.svg';

import { userActions } from '../../entities/User/models/slices/UserSlice';
import { casesActions } from '../../entities/Cases/models/slices/CasesSlice';

import { StateSchema } from '../../app/providers/StoreProvider/config/StateSchema';

import { useAppSelector } from '../../shared/components/useAppSelector/useAppSelector';
import { useAppDispatch } from '../../shared/components/useAppDispatch/useAppDispatch';

import Text from '../../shared/lib/widgets/Text';


const UserDropDownStyle = styled.div`
    position: relative;
    min-width: 200px;
    max-width: 400px;
    @media (max-width: 468px) {
        min-width: auto;
    }
`;

const UserDropDownContent = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
        opacity: .8;
    }
`;

const UserDropDownImg = styled.img`
    object-fit: cover;
    border-radius: 50%;
    width: 40px;
    height: 40px;
`;

const UserDropDownText = styled.div`
    white-space: nowrap; 
    user-select: none;
    max-width: 300px; 
    text-overflow: ellipsis; 
    flex: 1; 
    overflow: hidden; 
    @media (max-width: 468px) {
        display: none;
    }
`;

const UserDropDownIcon = styled.img<{ $isActive: boolean }>`
    width: 16px;
    height: 16px;
    rotate: ${(props) => props.$isActive && '180deg'};
    transition: .3s;
    &:hover {
        opacity: .5;
    }
`;

const ResponsiveMenu = styled.div`
    display: none;
    @media (max-width: 1150px) {
        display: block;
    }
`;

const UserDropDownMenu = styled.div`
    position: absolute; 
    top: calc(100% + 4px); 
    right: 0;
    width: 200px;
    background-color: #fff; 
    border-radius: 8px;
    border: 1px solid #eaeaea;
    z-index: 3;
    box-shadow: 0 1px 3px #eee;
`;

const UserDropDownMenuItem = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 8px 14px;
    margin: 2px;
    gap: 8px;
    border-radius: 6px;

    &:hover {
        background-color: #F6F7F9;
    }
`;

const UserDropDownMenuItemImg = styled.img`
    width: 16px;
    height: 16px;
    filter: brightness(1.9) saturate(100%) invert(31%) sepia(4%) saturate(0%) hue-rotate(179deg) brightness(93%) contrast(88%);
`;

const UserDropDownMenuDivider = styled.div`
    width: 100%;
    height: 1px;
    background-color: #eaeaea;
`;

const UserDropDown = () => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const user = useAppSelector((state: StateSchema) => state.user.userData);

    const [showDropDown, setShowDropDown] = useState(false);

    const userDropdown = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const handlerClick = (e: MouseEvent) => {
            if (userDropdown.current && !userDropdown.current.contains(e.target as Node)) {
                setShowDropDown(false);
            }
        };
    
        document.addEventListener('mousedown', handlerClick);
        return () => {
            document.removeEventListener('mousedown', handlerClick);
        };
    }, []);
    return (
        <UserDropDownStyle ref={userDropdown} onClick={() => setShowDropDown(!showDropDown)}>
            <UserDropDownContent>
                <UserDropDownImg src={user?.image?user?.image:User}  alt='user' />

                <UserDropDownText>{user?.first_name} {user?.last_name}</UserDropDownText>
                
                <UserDropDownIcon src={DropDownImage} alt='icon' $isActive={showDropDown} />
            </UserDropDownContent>

            {
                showDropDown &&
                    <UserDropDownMenu>
                        <ResponsiveMenu>
                            <UserDropDownMenuItem onClick={() => navigate('/')}>
                                <UserDropDownMenuItemImg src={MyCasesIcon} alt='icon' />
                                
                                <Text>{t('header.my_cases')}</Text>
                            </UserDropDownMenuItem>

                            <UserDropDownMenuItem onClick={() => navigate('/subscription-plan')}>
                                <UserDropDownMenuItemImg src={SubscriptionIcon} alt='icon' />
                                
                                <Text>{t('header.subscriptions')}</Text>
                            </UserDropDownMenuItem>

                            <UserDropDownMenuItem onClick={() => navigate('/academy')}>
                                <UserDropDownMenuItemImg src={AcademyIcon} alt='icon' />
                                
                                <Text>{t('header.academy')}</Text>
                            </UserDropDownMenuItem>

                            <UserDropDownMenuItem onClick={() => navigate('/settings')}>
                                <UserDropDownMenuItemImg src={SettingsIcon} alt='icon' />
                                
                                <Text>{t('header.settings')}</Text>
                            </UserDropDownMenuItem>

                            <UserDropDownMenuDivider />
                        </ResponsiveMenu>

                        {/* <UserDropDownMenuItem onClick={() => navigate('/affiliate')}>
                            <UserDropDownMenuItemImg src={SettingsIcon} alt='icon' />
                            
                            <Text>Affiliate</Text>
                        </UserDropDownMenuItem>

                        <UserDropDownMenuDivider /> */}

                        <UserDropDownMenuItem
                            onClick={() => {
                                dispatch(userActions.logout());
                                dispatch(casesActions.logout());
                                signOut(auth);
                                navigate('/sign-in');
                            }}
                        >
                            <UserDropDownMenuItemImg src={ExitIcon} alt='icon' />

                            <Text>{t('header.exit')}</Text>
                        </UserDropDownMenuItem>
                    </UserDropDownMenu>
            }
        </UserDropDownStyle>
    );
}

export default UserDropDown;