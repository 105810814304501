import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyBx-LWV936JoaRVvofKQr_JEhl4kvrngZ4",
  authDomain: "fir-app-422af.firebaseapp.com",
  projectId: "fir-app-422af",
  storageBucket: "fir-app-422af.appspot.com",
  messagingSenderId: "65301247454",
  appId: "1:65301247454:web:72253761067c6e9ac4194f"
};


export const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);