import React, { useState } from "react";

import styled from "styled-components";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import LoadingIcon from '../../assets/loading.svg';
import SendRightIcon from "../../assets/SendRightIcon.svg";

// import { editCase } from "../../entities/Case/models/services/case.services";

// import { StateSchema } from "../../app/providers/StoreProvider/config/StateSchema";

// import { $api } from "../shared/api/api";

// import { actionNames } from "../../shared/consts/default_values";
// import { useAppDispatch } from "../../shared/components/useAppDispatch/useAppDispatch";
// import { useAppSelector } from "../../shared/components/useAppSelector/useAppSelector";

import Tooltip from '../../shared/lib/widgets/Tooltip';

// import DropDown from "../DropDown/DropDown";
// import MarketplaceDropDown from "./DropDown/MarketplaceDropDown";

import InputTextArea from "../Inputs/InputTextArea";

// import UserIcon from "../assets/UserIcon.svg";
// import BlueCrossIcon from "../assets/BlueCrossIcon.svg";
// import SearchAnalysisImage from "../assets/SearchAnalysisImage.svg";

// import Text from "../widgets/Text";
import RowWidget from "../../shared/lib/widgets/RowWidget";
// import { casesActions } from "../../entities/Cases/models/slices/CasesSlice";
// import Container from "../widgets/Container";


interface SendMessageCardProps {
    isLoading?: boolean;
    onClick: (e: string) => void;
    sidebarExpanded?: boolean;
}

const SendMessageCardContainer = styled.div<{ $sidebarExpanded?: boolean }>`
    position: fixed;
    bottom: 0;
    left: ${(props) => props.$sidebarExpanded ? '64%' : '50%'};
    transform: ${(props) => props.$sidebarExpanded ? 'translateX(-64%)' : 'translateX(-50%)'};
    background-color: #F9FAFC;
    padding-bottom: 8px;
    display: flex;
    justify-content: center;
    width: 900px;
    @media (max-width: 900px) {
        width: 100%;
    }
`;

const SendMessageCardStyle = styled.div`
    background-color: #fff;
    border: 1px solid rgba(233, 233, 234, 1);
    border-radius: 8px;
    width: 100%;
    @media (max-width: 956px) {
        margin-left: 15px;
    }
`;

// const SendMessageInfo = styled.div`
//     display: flex;
//     align-items: center;
//     gap: 8px;
//     margin: 8px 16px 0 16px;
//     padding-bottom: 8px;
//     border-bottom: 1px solid rgba(233, 233, 234, 1);
// `;

const LoadingIconAnimation = styled.img`
    width: 25px;
    height: 25px;
    animation: rotation 1s infinite linear;
    @keyframes rotation {
        to{
        transform: rotate(-1turn)
        }
    }
`;

// const ChatInfo = styled.div<{ $borderRadius: string, $backgroundColor: string }>`
//     min-width: 200px;
//     height: 35px;
//     border-radius: 16px;
//     background-color: ${(props) => props.$backgroundColor ?? ''};
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     padding: 0 10px;
//     gap: 8px;
// `;

// const InfoIcons = styled.img<{ $size?: string }>`
//     width: ${(props) => props.$size ?? '12px'};
//     height: ${(props) => props.$size ?? '12px'};
//     cursor: pointer;
// `;

const SendMessageCard: React.FC<SendMessageCardProps> = (props) => {
    const { t } = useTranslation();
    
    // const caseData = useAppSelector((state: StateSchema) => state.case?.case);
    const [newMessage, setNewMessage] = useState("");
    // const [agent, setAgent] = useState({
    //     id: '',
    //     profileImage: '',
    //     name: '',
    //     skills: [],
    // });

    // const dispatch = useAppDispatch();

    // const user = useAppSelector((state: StateSchema) => state.user.userData);

    // useEffect(() => {
    //     const fetchAgent = async (agentId: string) => {
    //         try {
    //             const response = await $api.get(`/agents/${agentId}`);
    //             setAgent(response.data);
    //         } catch (error) {
    //             toast.error(t('messages.errors.agent.search'));
    //             setAgent({
    //                 id: '',
    //                 profileImage: '',
    //                 name: 'Not found',
    //                 skills: [],
    //             });
    //         }
    //     };

    //     if (caseData?.agent_id)
    //         fetchAgent(caseData?.agent_id);
    // }, [caseData?.agent_id]);

    // const [showAllSkills, setShowAllSkills] = useState(false);

    // const [skill, setSkill] = useState('');

    // const isMoreThanAMonthOld = (agentDate: Date) => {
    //     const currentDate = new Date();

    //     const oneMonthAgo = new Date();
    //     oneMonthAgo.setMonth(currentDate.getMonth() - 1);

    //     return agentDate < oneMonthAgo;
    // };

    // const isCollaboratorCanEdit = caseData?.collaborator_ids?.some(coll => coll.id === user?.id && coll.edit === true);

    const handleSendMessage = (event: any) => {
        event.preventDefault();

        if (props.isLoading)
            return toast.info(t('messages.info.wait_generation'))

        if (!newMessage)
            return toast.info(t('messages.info.input_message'))

        // if (!skill)
        //     return toast.info('Per favore seleziona una skill!')

        // if (!isCollaboratorCanEdit)
        //     return toast.info('Utente non ha il permesso ad inviare i messaggi!')

        // if (!caseData?.agent_id)
        //     return toast.info("Per favore seleziona agente!")

        // if (!user?.agent_ids.map((agentData) => agentData.id === caseData.agent_id ? isMoreThanAMonthOld(agentData.date) : null))
        //     return toast.info("Per favore aggiorna l'iscrizione per agent!")

        try {
            props.onClick(newMessage)
            setNewMessage("");
        } catch (e: any) {
            toast.error(t('messages.errors.cases.message_send'));
        }
    };

    // const handleRowSelect = (rowData: any) => {
    //     if (!isCollaboratorCanEdit)
    //         return toast.info('Utente non ha il permesso per cambiare agente!')

    //     dispatch(editCase({ id: caseData?.id, agent_id: rowData.id }))
    // };

    return (
        <SendMessageCardContainer $sidebarExpanded={props.sidebarExpanded}>
            <SendMessageCardStyle>
                {/* <SendMessageInfo>
                    {
                        (!caseData?.agent_id || caseData?.agent_id) && 
                            <ChatInfo $backgroundColor="#ECEEFC" $borderRadius="16px" onClick={() => {
                                if (!isCollaboratorCanEdit)
                                    return toast.info("Utente non ha il permesso per cambiare l'agente!");

                                dispatch(editCase({ id: caseData?.id, agent_id: '' }));
                                setSkill('');
                            }}>
                                <RowWidget $alignItems="center" $gap="8px">
                                    <InfoIcons src={UserIcon} alt="icon" $size="16px" />

                                    <Text fontSize='14px' fontWeight='400px' color='#4158E3'>{agent?.name}</Text>
                                </RowWidget>

                                <InfoIcons src={BlueCrossIcon} alt="icon" />
                            </ChatInfo>
                    }
                    {
                        skill && 
                            <ChatInfo $backgroundColor="#F2F4F6" $borderRadius="8px" onClick={() => setSkill('')}>
                                <RowWidget $alignItems="center" $gap="8px">
                                    <InfoIcons src={SearchAnalysisImage} alt="icon" $size="16px" />

                                    <Text fontSize='14px' fontWeight='400px' color='#153968'>{skill}</Text>
                                </RowWidget>

                                <InfoIcons src={BlueCrossIcon} alt="icon" />
                            </ChatInfo>
                    }
                </SendMessageInfo> */}

                <RowWidget $margin="4px 16px 16px 16px" $gap="12px">
                    {/* {
                        caseData?.agent_id &&
                        <MarketplaceDropDown onClick={handleRowSelect} />
                    } */}

                    <InputTextArea
                        padding="10px 0"
                        backcgroundColor="#FFF"
                        rows={4}
                        disabled={props.isLoading}
                        placeholder={t('input.chat_label')}
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        onSubmit={handleSendMessage}
                    />

                    <RowWidget $alignItems="end">
                            {
                                props.isLoading ?
                                    <LoadingIconAnimation src={LoadingIcon} alt="icon" />
                                    :
                                    <Tooltip
                                        title={t('tooltip.send_message.title')}
                                        description={t('tooltip.send_message.description')}
                                    >
                                        <img src={SendRightIcon} alt="icon" width="25px" height="25px" onClick={handleSendMessage} />
                                    </Tooltip>
                            }
                    </RowWidget>
                </RowWidget>

                {/* {
                    caseData?.agent_id && !skill &&
                        <Container $padding="12px" backgroundColor="#f2f4f6" color="#153968" $borderRadius="0 0 10px 10px">
                            <RowWidget $gap="8px" $alignItems="center" $justifyContent="space-between" $flexWrap="wrap">
                                <RowWidget $gap="8px">
                                    {
                                        agent?.skills
                                            .slice(0, showAllSkills ? undefined : 4)
                                            .map((skill: any, index: any) => (
                                                <Tooltip
                                                    key={index}
                                                    title={t('tooltip.skill.title')}
                                                    description={t('tooltip.skill.title')}
                                                >
                                                    <Container $backgroundColor="#FFF" padding="8px 10px" $borderRadius="8px" onClick={() => setSkill(skill.name)}>
                                                        <Text fontSize="14px">{skill.name}</Text>
                                                    </Container>
                                                </Tooltip>
                                            ))
                                    }
                                </RowWidget>

                                <Text color="#153968" onClick={() => setShowAllSkills(!showAllSkills)} style={{ cursor: 'pointer' }}>
                                    {showAllSkills ? "<" : "more skill >"}
                                </Text>
                            </RowWidget>
                        </Container>
                } */}
            </SendMessageCardStyle>
        </SendMessageCardContainer>
    );
};

export default SendMessageCard;