import { useEffect, useRef, useState } from "react";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { Message } from "../../../entities/Case/models/types/Case.types";


const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_URL;

const useChat = (roomId: string) => {
    const { t } = useTranslation();
    
    const [isQueueComplete, setIsQueueComplete] = useState<boolean>(false);
    const [messages, setMessages] = useState<Message[]>([]);
    const websocketRef = useRef<WebSocket | null>(null);
    const reconnectIntervalRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        const connectWebSocket = () => {
            try {
                const ws = new WebSocket(`${SOCKET_SERVER_URL}/ws/${roomId}`);
                websocketRef.current = ws;

                ws.onopen = () => {
                    console.log("WebSocket connection opened");
                    if (reconnectIntervalRef.current) {
                        clearInterval(reconnectIntervalRef.current);
                        reconnectIntervalRef.current = null;
                    }
                };

                ws.onmessage = (event) => {
                    try {
                        const data = event.data;
                        if (typeof data === 'string' && data.startsWith('{')) {
                            const parsedResponse: Message = JSON.parse(data);
                            setMessages((prevMessages) => [...prevMessages, parsedResponse]);
                        } else {
                            console.error("Invalid JSON data received:", data);
                        }
                    } catch (error) {
                        toast.info(t('messages.info.try_later'));
                        console.error("Message parsing error:", error);
                    }
                    setIsQueueComplete(false);
                };

                ws.onclose = () => {
                    console.log("WebSocket connection closed, attempting to reconnect...");
                    if (!reconnectIntervalRef.current) {
                        reconnectIntervalRef.current = setInterval(connectWebSocket, 5000);
                    }
                };

                ws.onerror = (error) => {
                    console.error("WebSocket error:", error);
                };
            } catch (error) {
                console.error("WebSocket connection error:", error);
            }
        };

        connectWebSocket();

        return () => {
            if (websocketRef.current) {
                websocketRef.current.close();
            }
            if (reconnectIntervalRef.current) {
                clearInterval(reconnectIntervalRef.current);
                reconnectIntervalRef.current = null;
            }
        };
    }, [roomId]);

    const sendMessage = (content: string) => {
        const localLanguage = localStorage.getItem('i18nextLng') || 'it';

        if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
            setIsQueueComplete(true);
            setMessages((prevMessages) => [
                ...prevMessages,
                {
                    sender: "user_proxy",
                    recipient: "assistant",
                    content: content,
                },
            ]);

            websocketRef.current.send(
                JSON.stringify({
                    content: 
                        localLanguage === 'en' ?
                            'Answer the question as completely and accurately as possible, referencing relevant laws, regulations and case law, where applicable. Make sure that your answers comply with applicable laws and do not constitute legal advice. If you have any legal doubts or uncertainties, always recommend that you consult a qualified professional. Your answer should be complete and precise, providing detailed information in accordance with relevant laws and regulations. so in english answer extensively to:' + content
                            :
                            'Rispondi alla domanda nel modo più completo e accurato possibile, facendo riferimento a leggi, regolamenti e giurisprudenza pertinenti, ove applicabile. Assicurati che le tue risposte siano conformi alle normative applicabili e non costituiscano consulenza legale. Se hai dubbi o incertezze legali, raccomanda sempre di consultare un professionista qualificato. La tua risposta dovrebbe essere completa e precisa, fornendo informazioni dettagliate in conformità con le leggi e i regolamenti pertinenti. quindi in italiano rispondi ampiamente a:' + content,
                    role: "user",
                })
            );
        } else {
            toast.error(t("messages.errors.socket_error"));
        }
    };

    return { messages, sendMessage, isQueueComplete };
};

export default useChat;
