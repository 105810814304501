import styled from "styled-components";
import { useTranslation } from "react-i18next";


const CitationsDocumentsToggleStyle = styled.div`
    height: 40px;
    display: flex;
    border-bottom: 1px solid #E8EBF0;
    margin: 10px 20px 20px 20px;
`;

const CitationsDocumentsToggleItemStyle = styled.div<{ $toggle: boolean }>`
    width: 100%;
    height: 40px;
    border-bottom: 2px solid ${(props) => props.$toggle ? 'transparent' : '#007FFF'};
    font-weight: ${(props) => props.$toggle ? 'normal' : 'bold'};
    cursor: pointer;
    display: grid;
    place-items: center;
`;

const CitationsDocumentsToggle = ({ toggle, setToggle }: any) => {
    const { t } = useTranslation();
    
    return (
        <CitationsDocumentsToggleStyle>
            <CitationsDocumentsToggleItemStyle onClick={() => setToggle(false)} $toggle={toggle}>{t('chat.sidebar.citations_sources')}</CitationsDocumentsToggleItemStyle>
            <CitationsDocumentsToggleItemStyle onClick={() => setToggle(true)} $toggle={!toggle}>{t('chat.sidebar.documents')}</CitationsDocumentsToggleItemStyle>
        </CitationsDocumentsToggleStyle>
    );
}


export default CitationsDocumentsToggle;