import React, { useState } from "react";

import { Trans, useTranslation } from "react-i18next";

import SuccessIcon from '../../assets/SuccessIcon.svg';

import Popup from "./Popup"

import PlanToggle from "../Toggles/PlanToggle";

import SubscriptionPaymentForm from "../Forms/SubsciptionPaymentForm";

import Text from "../../shared/lib/widgets/Text";
import RowWidget from "../../shared/lib/widgets/RowWidget";
import Container from "../../shared/lib/widgets/Container";
import LoadStripe from "../../shared/components/LoadStripe/LoadStripe";


interface SubscribePopupProps {
    subscriptionDetails: any;
    onSubmit: () => void;
    onClose: () => void;
}

const SubscribePopup: React.FC<SubscribePopupProps> = ({ onSubmit, onClose, subscriptionDetails }) => {
    const { t } = useTranslation();

    const [isAnnual, setIsAnnual] = useState<boolean>(false);

    return (
        <Popup title={t('upgrade_plan.popup.subscribe.title')} width="1024px" onClose={onClose}>
            <RowWidget $flexWrap="wrap">
                <Container $width="350px">
                    <Text color="#FF5252" fontWeight="bold" fontSize="14px">{subscriptionDetails.title}</Text>

                    <Container $height="4px" />

                    <RowWidget $alignItems="end">
                        <Text color="#201F29" fontWeight="400" fontSize="40px">€{!isAnnual ? subscriptionDetails.priceMonth : subscriptionDetails.priceYear}</Text>
                        <Container $width="8px" />
                        <Text color="#201F29" fontWeight="600" fontSize="14px" margin="0 0 8px 0">{t('subscriptions.subscription_type.per_month')}</Text>
                    </RowWidget>

                    <Container $height="8px" />

                    <RowWidget>
                        <Container $padding="4px 10px" $backgroundColor="#DCE1E8" $borderRadius="40px">
                            <Text color="#153968" fontWeight="bold" fontSize="14px">
                                {
                                    subscriptionDetails.user > 1 ?
                                        <Trans t={t} i18nKey="subscriptions.subscription_type.up_to_users">
                                            {subscriptionDetails.user}
                                        </Trans>
                                        :
                                        <Trans t={t} i18nKey="subscriptions.subscription_type.user">
                                            {subscriptionDetails.user}
                                        </Trans>
                                }
                            </Text>
                        </Container>
                    </RowWidget>

                    <Container $height="24px" />
                    
                    <Container $width="100%" $height="1px" $backgroundColor="#E8ECF0" />
                    
                    {
                        (t(subscriptionDetails.items, { returnObjects: true }) as string[])
                        .map((item: any, index: any) =>
                            <RowWidget $margin="24px 0" key={index}>
                                <img key={index} src={SuccessIcon} alt='icon' width='18px' height='18px' />
                                <Container $width="8px" />
                                <Text fontWeight={index === 0 && subscriptionDetails.containPreviousPlan ? '600' : '400'} fontSize={index === 0 && subscriptionDetails.containPreviousPlan ? '16px' : '14px'} >{item}</Text>
                            </RowWidget>
                        )
                    }
                </Container>

                <Container style={{ minWidth: '64px' }} />

                <RowWidget $flex="1" $flexDirection="column">
                    <RowWidget $justifyContent="space-between" $alignItems="center">
                        <Text fontSize="14px" color="#201F29">Plan</Text>

                        <RowWidget $alignItems="center">
                            <Text fontSize="13px" color="#7E7D83">{t('upgrade_plan.popup.subscribe.currency')}</Text>
                            <Container $width="8px" />
                            <Container $backgroundColor="#F6F7F9" $padding="4px 8px" $borderRadius="10px">
                                <Text color="#201F29" fontSize="14px">EUR</Text>
                            </Container>
                        </RowWidget>
                    </RowWidget>

                    <Container $height="8px" />

                    <PlanToggle 
                        onClick={setIsAnnual} 
                        toggleState={isAnnual} 
                        priceMonth={subscriptionDetails.priceMonth} 
                        priceYeah={subscriptionDetails.priceYear} 
                    />

                    <Container $height="32px" />

                    <LoadStripe>
                        <SubscriptionPaymentForm 
                            onSubmit={onSubmit}
                            onClose={onClose}
                            price={!isAnnual ? subscriptionDetails.priceMonth : subscriptionDetails.priceYear}
                        />
                    </LoadStripe>
                </RowWidget>
            </RowWidget>
        </Popup>
    );
};

export default SubscribePopup;