import { useEffect, useState } from "react";

import { toast } from "react-toastify";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { addCollaborator } from "../../entities/Case/models/services/case.services";
import { User } from "../../entities/User/models/types/User.types";

import { StateSchema } from "../../app/providers/StoreProvider/config/StateSchema";

import { $api } from "../../shared/api/api";
import { isEmail } from "../../shared/utils/validators";
import { useAppDispatch } from "../../shared/components/useAppDispatch/useAppDispatch";
import { useAppSelector } from "../../shared/components/useAppSelector/useAppSelector";

import UserImg from '../../assets/user.svg';
import PrimaryButton from "../Buttons/PrimaryButton";

import DropDown from "../DropDown/DropDown";

import Text from "../../shared/lib/widgets/Text";
import RowWidget from "../../shared/lib/widgets/RowWidget";
import Container from "../../shared/lib/widgets/Container";


const CollaboraitorsContainer = styled.div`
    overflow-y: auto; 
    max-height: 300px;
    padding-right: 4px;
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        background: transparent; 
    }
    &::-webkit-scrollbar-thumb {
        cursor: pointer;
        border-radius: 40px;
        background: #E8ECF0; 
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #aaa; 
    }
`;

const CollaboratorsData = ({ onSubmit }: any) => {
    const { t } = useTranslation();
    
    const dispatch = useAppDispatch();
    
    const caseData = useAppSelector((state: StateSchema) => state.case?.case);

    const [email, setEmail] = useState<string>('');
    const [userAction, setUserAction] = useState(t('dropdown.permission.view'));

    const [collaboratorsData, setCollaboratorsData] = useState<User[]>([]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!isEmail(email))
            return toast.error(t('messages.errors.input.email'));

        dispatch(addCollaborator({ id: caseData?.id, email: email, action: userAction === t('dropdown.permission.view') ? false : true }))
        setEmail('');
    };
    
    useEffect(() => {
        const fetchCollaborators = async () => {
            if (!caseData?.id || !caseData?.collaborators?.length) return;
    
            try {
                const collaborators = [];
    
                for (const collaboratorId of caseData.collaborators) {
                    const response = await $api.get(`/users/${collaboratorId.id}/`);                    
                    collaborators.push({
                    ...collaboratorId,
                    ...response.data,
                    });
                }
    
                setCollaboratorsData(collaborators);
            } catch (error) {
                toast.error(t('messages.errors.cases.search_collaborators'));
            }
        };
        
        fetchCollaborators();
    }, [caseData?.id, caseData?.collaborators, t]);

    return (
        <>
            <Container $width="100%" $height="1px" $backgroundColor="#e4e4e5" $margin="16px 0" />

            <Text fontWeight="bold" margin="8px 0">{t('chat.popup.share.subtitle')}</Text>

            <RowWidget $alignItems="center" $gap="16px">
                <Container $backgroundColor='#fff' $borderRadius='12px' $border='1px solid rgba(32, 31, 41, 0.12)'>
                    <RowWidget $alignItems="center" $padding="0 8px 0 0">
                        <input 
                            type="text" 
                            value={email} 
                            onChange={(e: any) => setEmail(e.target.value)} 
                            placeholder='Email' 
                            style={{
                                padding: '12px 16px',
                                border: 'none',
                                fontSize: '16px',
                                outline: 'none',
                                backgroundColor: 'transparent',
                                width: '100%',
                            }}
                        />

                        <DropDown 
                            border="transparent"
                            width='160px'
                            dropDownName={userAction}
                            value={userAction} 
                            onChange={setUserAction} 
                            menuItems={[t('dropdown.permission.view'), t('dropdown.permission.edit')]} 
                        />
                    </RowWidget>
                </Container>

                <PrimaryButton
                    onClick={handleSubmit}
                    title={t('button.invite')}
                    $isLarge
                    $width='120px'
                />
            </RowWidget>

            <Container $height="24px" />

            <CollaboraitorsContainer>
                {
                    collaboratorsData.length === 0 ?
                        <Text fontSize='18px' margin="16px 0" $textAlign="center" color='grey'>{t('empty_list.record')}</Text>
                        :
                        <>
                            {
                                collaboratorsData.map((item: any) =>
                                    <RowWidget key={item.id} $margin="0 0 16px 0" $justifyContent="space-between" $alignItems="center">
                                        <RowWidget>
                                            <img src={item.image ? item.image : UserImg} alt="user" width="40px" height="40px" style={{ borderRadius: '50%', backgroundColor: 'rgba(222, 225, 241, 1)', objectFit: 'cover' }} />
                                            
                                            <Container $width="10px" />

                                            <RowWidget $flexDirection="column" $justifyContent="space-between">
                                                <Text fontWeight="600">{item.first_name} {item.last_name}</Text>
                                                <Text color="rgba(126, 125, 131, 1)" fontSize="14px" fontWeight="400">{item.email}</Text>
                                            </RowWidget>
                                        </RowWidget>

                                        {
                                            caseData?.creator_id === item.id ?
                                                <Text color="#908F94" fontSize="14px" fontWeight="400">{t('my_cases.owner')}</Text>
                                                :
                                                <DropDown 
                                                    border="transparent"
                                                    width='160px'
                                                    dropDownName={item.edit ? t('dropdown.permission.view') : t('dropdown.permission.edit')}
                                                    value={item.edit ? t('dropdown.permission.view') : t('dropdown.permission.edit')} 
                                                    onChange={onSubmit} 
                                                    menuItems={[t('dropdown.permission.edit'), t('dropdown.permission.view')]} 
                                                />
                                        }
                                    </RowWidget>
                                )
                            }
                            <Container $height="85px" />
                        </>
                }
            </CollaboraitorsContainer>
        </>
    );
}

export default CollaboratorsData;