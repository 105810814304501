import { useState } from "react";

import styled from "styled-components";

import ThreeDotsIcon from "../../assets/ThreeDotsIcon.svg";


const ActionsStyle = styled.div`
    position: relative;
    height: 24px;
    border-radius: 6px;
`;

const ActionsImgStyle = styled.img`
    width: 24px;
    height: 100%;
    padding: 4px;
    border-radius: 6px;
    &:hover {
        background-color: #e6e4e46c;
    }
`;

const ActionMenuStyle = styled.div`
    position: absolute;
    top: calc(100% + 4px);
    right: 0;
    z-index: 1;
    padding: 2px;
    width: 160px;
    background-color: #FFF;
    border-radius: 8px;
`;

const ActionMenuItemStyle = styled.div`
    padding: 4px 8px;
    border-radius: 4px;
    &:hover {
        background-color: #e6e4e46c;
    }
`;

const DocumentActionMenu = () => {
    const [viewDropDowm, setViewDropDowm] = useState(false)

    return (
        <ActionsStyle onClick={(e) => e.stopPropagation()}>
            <ActionsImgStyle 
                src={ThreeDotsIcon}
                alt='icon'
                onClick={() => setViewDropDowm(!viewDropDowm)}
            />

            {
                viewDropDowm &&
                    <ActionMenuStyle>
                        <ActionMenuItemStyle>A</ActionMenuItemStyle>
                        <ActionMenuItemStyle>A</ActionMenuItemStyle>
                        <ActionMenuItemStyle>A</ActionMenuItemStyle>
                        <ActionMenuItemStyle>A</ActionMenuItemStyle>
                    </ActionMenuStyle>
            }
        </ActionsStyle>
    );
}


export default DocumentActionMenu;