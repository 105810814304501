import { Provider } from "react-redux";
import { ReducersMapObject } from "@reduxjs/toolkit";

import { StoreProviderProps } from "./StoreProvider.types";

import { createReduxStore } from "../config/store";
import { StateSchema } from "../config/StateSchema";


export const StoreProvider = (props: StoreProviderProps) => {
    const {
        children,
        initialState,
        asyncReducers,
    } = props;

    const store = createReduxStore(
        initialState as StateSchema,
        asyncReducers as ReducersMapObject<StateSchema>,
    );

    return (
        <Provider store={store}>
            {children}
        </Provider>
    );
};
