import React from "react";

import styled from "styled-components";


type ButtonColorVariants = 'default' | 'red';

interface ButtonColorStyle {
    normal: string;
    hovered: string;
    active: string;
}

const ButtonColorStyles: Record<ButtonColorVariants, ButtonColorStyle> = {
    default: {
        normal: '#153968',
        hovered: '#0F2849',
        active: '#0D233F',
    },
    red: {
        normal: '#FF5252',
        hovered: '#B33939',
        active: '#9C3232',
    }
};

export const getButtonColorStyle = (type?: string): ButtonColorStyle => {
    return ButtonColorStyles[type as ButtonColorVariants] || ButtonColorStyles.default;
};

const ButtonContainer = styled.button<PrimaryButtonProps>`
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => getButtonColorStyle(props.type).normal};
    color: #fff;
    outline: none;
    width: ${(props) => props.$width};
    padding: ${(props) => props.$isLarge ? '12px 32px' : '10px 16px'};
    border: 1px solid ${(props) => getButtonColorStyle(props.type).normal};
    opacity: ${(props) => props.$isDisabled ? '.7' : ''};
    border-radius: 8px;
    white-space: nowrap;
    cursor: pointer;
    transition: background-color 0.3s ease;
    &:hover {
        background-color: ${(props) => getButtonColorStyle(props.type).hovered};
        color: #fff;
        border: 1px solid ${(props) => getButtonColorStyle(props.type).hovered};
    }
    &:active {
        background-color: ${(props) => getButtonColorStyle(props.type).active};
        color: #fff;
        border: 1px solid ${(props) => getButtonColorStyle(props.type).active};
    }
    &:focus {
        border: 1px solid #c2c2c2;
        color: #e0e0e0;
    }
    @media (max-width: 420px) {
        flex: 1;
    }
`;

const ButtonLeading = styled.img`
    width: '20px';
    color: #fff;
    margin-right: 8px;
`;

const ButtonTitle = styled.div<PrimaryButtonProps>`
    font-size: ${(props) => props.$isLarge ? '16px' : '14px'};
    font-weight: ${(props) => props.$isLarge ? '600' : '500' };
    font-family: 'SF Pro Display';
`;  

const ButtonToggle = styled.img<PrimaryButtonProps>`
    width: ${(props) => props.$isLarge ? '20px' : '16px'};
    color: #fff;
    margin-left: 8px;
`;

interface PrimaryButtonProps {
    // Style
    $isLarge?: boolean;
    $isDisabled?: boolean;
    
    $width?: string;

    // Values
    type?: string;
    onClick?: (e?: any) => void;
    leading?: string;
    title?: string;
    toggle?: string;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = (props) => {
    return (
        <ButtonContainer 
            onClick={(e) => {
                if (!props.$isDisabled && props.onClick) {
                    props.onClick(e);
                }
            }} 
            type={props.type}
            $width={props.$width}
            $isLarge={props.$isLarge}
            $isDisabled={props.$isDisabled}
            >
                {
                    props.leading &&
                        <ButtonLeading 
                            src={props.leading}
                            alt="icon"
                        />
                }
                <ButtonTitle
                    $isLarge={props.$isLarge}
                >{props.title}</ButtonTitle>
                {
                    props.toggle &&
                        <ButtonToggle 
                            src={props.leading}
                            alt="icon"
                            $isLarge={props.$isLarge}
                        />
                }
            </ButtonContainer>
    )
}

export default PrimaryButton;