import React from "react";

import { useNavigate } from "react-router-dom";

import Popup from "./Popup";

import PrimaryButton from "../Buttons/PrimaryButton";

import Text from "../../shared/lib/widgets/Text";
import Container from "../../shared/lib/widgets/Container";
import RowWidget from "../../shared/lib/widgets/RowWidget";


interface SuccessPopupProps {
    onClose: () => void;
}

const SuccessPopup: React.FC<SuccessPopupProps> = ({ onClose }) => {
    const navigate = useNavigate();

    return (
        <Popup title="Success!" onClose={onClose} width="600px">
            <RowWidget $flexDirection="column" $alignItems="center">
                <Text fontWeight="bold" fontSize="28px">Thank you for subscription</Text>

                <Container $height="16px" />
                
                <Text>Now you have access to all the features of the <b>PRO plan</b>.</Text>

                <Container $height="24px" />

                <PrimaryButton 
                    onClick={() => navigate('/')}
                    title="Go to My cases"
                    $width="220px"
                />
            </RowWidget>
        </Popup>
    );
}

export default SuccessPopup