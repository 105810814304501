import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";

import { Agent, AgentState } from "../types/Agent.types";
import { fetchAgents } from "../services/agents.services";

import { StateSchema } from "../../../../app/providers/StoreProvider/config/StateSchema";


const agentAdapter = createEntityAdapter<Agent, string>({
    selectId: (agentItem) => agentItem.id,
});

export const selectAgents = createSelector(
    [(state: StateSchema) => state.agents?.ids, (state: StateSchema) => state.agents?.entities],
    (ids, entities) => {
        if (!ids || !entities) {
            return [];
        }

        return ids.map(id => {
            const agent = entities[id];
            return {
                ...agent,
                displayName: `Agent ${agent.name}`,
            };
        });
    }
);

const initialState: AgentState = agentAdapter.getInitialState({
    ids: [],
    entities: {},
    isLoading: false,
})

const AgentsSlice = createSlice({
    name: 'agents',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAgents.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchAgents.fulfilled, (state, action) => {
                state.isLoading = false;
                if (action.payload !== null)
                    agentAdapter.addMany(state, action.payload);
            }
            )
            .addCase(fetchAgents.rejected, (state) => {
                state.isLoading = false;
            });
    },
});

export const { 
    reducer: agentsReducer, 
    actions: agentsActions,
} = AgentsSlice;
