export enum AppRoutes {
    SIGNIN = 'sign-in',
    SIGNUP = 'sign-up',
    EMAILVERIFICATION = 'email-verification',
    FORGOTPASSWORD = 'forgot-password',
    RESETPASSWORD = 'reset-password',

    // My-Cases
    HOME = 'home',
    ARCHIVE = 'archive',
    CHAT_ID = 'chat-id',
    DOCUMENT_EDITOR = 'document-editor',

    // Subscription
    SUBSCRIPTION = 'subscription',
    SUBSCRIPTION_PLAN = 'subscription-plan',
    MARKETPLACE = 'marketplace',
    MARKETPLACE_AGENT = 'marketplace-agent',

    // Academy
    ACADEMY = 'academy',
    ACADEMY_VIDEO_TUTORIAL = 'academy-video-tutorial',
    ACADEMY_ARTICLES_GUIDES = 'academy-articles-guides',
    ACADEMY_PRACTICAL = 'academy-practical',
    ACADEMY_WEBINAR = 'academy-webinar',

    // Settings
    SETTINGS = 'settings',
    
    AFFILIATE = 'affiliate',
    
    // NOT FOUND
    NOT_FOUND = 'not_found',
}

export const getRouteSignIn = () => '/sign-in';
export const getRouteSignUp = () => '/sign-up';
export const getRouteEmailVerification = () => '/email-verification';
export const getRouteForgotPasswpord = () => '/forgot-password';
export const getRouteResetPassword = () => '/reset-password';

// My-Cases
export const getRouteCases = () => '/';
export const getArchive = () => '/archive';
export const getRouteChatId = (roomId: string) => `/case/${roomId}`;
export const getRouteDocumentId = (documentId: string) => `/document/${documentId}`;

// Subscription
export const getRouteSubscription = () => '/subscriptions';
export const getRouteSubscriptionPlan = () => '/subscription-plan';
export const getRouteMarketplace = () => '/marketplace';
export const getRouteMarketplaceAgent = (id: string) => `/marketplace/${id}`;

// Academy
export const getRouteAcademy = () => '/academy';
export const getRouteAcademyVideoTutorial = () => '/academy/video-tutorial';
export const getRouteAcademyArticlesGuides = () => '/academy/articles-guides';
export const getRouteAcademyPractical = () => '/academy/practical';
export const getRouteAcademyWebinar = () => '/academy/webinar';

// Settings
export const getRouteSettings = () => '/settings';

export const getRouteAffiliate = () => '/affiliate';

// Not Found
export const getRouteNotFound = () => '*';
