import React, { useState } from 'react';

import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import remarkGfm from 'remark-gfm'
import ReactMarkdown from 'react-markdown'

import CopyMessage from '../../assets/copy.png';
import FeedbackLike from '../../assets/feedback-like.png';
import FeedbackDislike from '../../assets/feedback-dislike.png';

import FeedbackPopup from '../Popups/FeedbackPopup';

import RowWidget from '../../shared/lib/widgets/RowWidget';
import Container from '../../shared/lib/widgets/Container';
import Text from '../../shared/lib/widgets/Text';

import styleMessage from '../../shared/utils/markdown-styles.module.css';
import Tooltip from '../../shared/lib/widgets/Tooltip';


interface MessageCardProps {
    messageText: string;
    image?: string;
}

const MessageCardStyle = styled.div`
    display: flex;
    gap: 12px;
    width: 840px;
    margin: 0 auto 28px auto;

    @media (max-width: 840px) {
        width: 90%;
    }
`;

const Avatar = styled.img`
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 100%;
    object-fit: cover;
`;

const PlaceholderAvatar = styled.div`
    background-color: #4158E3;
    color: #fff;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    min-width: 40px;
    height: 40px;
`;

const Icon = styled.img`
    width: 20px;
    height: 20px;
    cursor: pointer;
`;

const MessageCard: React.FC<MessageCardProps> = ({ messageText, image }) => {
    const { t } = useTranslation();
    
    const [feedbackPopup, setFeedbackPopup] = useState(false);

    return (
        <MessageCardStyle>
            {
                feedbackPopup &&
                <FeedbackPopup
                    onSubmit={() => toast.info(t('messages.info.service_not_available'))}
                    onClose={() => setFeedbackPopup(false)}
                />
            }
            {
                image ?
                    <Avatar src={image} alt='avatar' />
                    :
                    <PlaceholderAvatar>AI</PlaceholderAvatar>
            }
            <Container $width='100%'>
                {/* {
                    skill ?
                        <div style={{
                            fontFamily: 'SF Pro Display',
                            marginBottom: '8px',
                        }}>
                            Used assistant: <b style={{ color: '#4158E3' }}>{name}</b> / 
                            Used skill: <b style={{ color: '#234571' }}>{skill}</b>
                        </div>
                        :
                        null
                } */}
                <Container $backgroundColor={image ? '#ECEEFC' : '#FFF'} $borderRadius='12px' $width='100%'>
                    <Text padding='12px'>
                        <ReactMarkdown
                            children={messageText}
                            remarkPlugins={[remarkGfm]}
                            className={styleMessage.reactMarkDown}
                        />
                    </Text>

                    {
                        !image &&
                        <RowWidget $alignItems='center' $justifyContent='space-between' $padding='8px 12px'>
                            <RowWidget $gap='8px'>
                                <Icon
                                    src={FeedbackLike}
                                    alt='icon'
                                    onClick={() => setFeedbackPopup(true)}
                                />
                                <Icon
                                    src={FeedbackDislike}
                                    alt='icon'
                                    onClick={() => setFeedbackPopup(true)}
                                />
                            </RowWidget>

                            <RowWidget $gap='8px'>
                                <Tooltip
                                    title={t('tooltip.copy_message.title')}
                                    description={t('tooltip.copy_message.description')}
                                >
                                    <Icon
                                        src={CopyMessage}
                                        alt='icon'
                                        onClick={() => {
                                            toast.success(t('messages.success.message_copied'));
                                            navigator.clipboard.writeText(messageText);
                                        }}
                                    />
                                </Tooltip>
                                {/* <Icon 
                                    src={ResendMessage}
                                    alt='icon'
                                    onClick={() => sendMessage(messageText)}
                                /> */}
                            </RowWidget>
                        </RowWidget>
                    }
                </Container>
            </Container>
        </MessageCardStyle>
    );
};

export default MessageCard;
