import { useState } from "react";

import styled from "styled-components";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import CloudUploadIcon from '../../assets/cloud_upload.svg'

import DownArrowIcon from '../../assets/down_arrow.svg'
import DocumentIcon from '../../assets/document_icon.svg'
import AIDocumentIcon from '../../assets/ai_document_icon.svg'

import DocumentActionMenu from "../DropDown/DocumentActionsDropdown";

import Text from "../../shared/lib/widgets/Text";
import Container from "../../shared/lib/widgets/Container";
import RowWidget from "../../shared/lib/widgets/RowWidget";


const BrowseButton = styled.label`
    user-select: none;
    cursor: pointer;
    white-space: nowrap;
    color: #4158E3;
    font-weight: bold;
    font-size: 16px;
    outline: none;
    border: none;
    background-color: transparent;
    &:hover {
        color: #2E3E9F;
    }
    &:active {
        color: #28368A;
    }
    &:focus {
        color: #28368A;
    }
`;

const DocumentsTitleStyle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px;
    cursor: pointer;
    user-select: none;
`;

const DocumentsContainerStyle = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 12px;
    padding: 12px;
    cursor: pointer;
    border-radius: 8px;
    background-color: transparent;
    &:hover {
        background-color: #E6F2FF;
    }
`;

const DocumentsDocHeaderStyle = styled.div`
    display: flex;
    gap: 12px;
    overflow: hidden;
`;

const DocumentsDocTitleStyle = styled.div`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

interface DocumentsListProps {
    title: string;
    items: any;
    setShowDocument: (show: boolean) => void;
    isOpen?: boolean;
}

const DocumentsList: React.FC<DocumentsListProps> = ({ title, items, setShowDocument, isOpen = false }) => {
    const { t } = useTranslation();

    const [isVisible, setIsVisible] = useState(isOpen);

    // const [files, setFiles] = useState<File[]>([]);

    const handleFileChange = (e: any) => {
        toast.info(t('messages.info.service_not_available'));
        // const selectedFiles = e.target.files;
        // if (selectedFiles && selectedFiles.length > 0) {
        //     const newFiles = Array.from(selectedFiles);
        //     setFiles((prevFiles: any) => [...prevFiles, ...newFiles]);
        // }
    };

    const handleDrop = (e: any) => {
        e.preventDefault();
        toast.info(t('messages.info.service_not_available'));

        // const droppedFiles = e.dataTransfer.files;
        // if (droppedFiles.length > 0) {
        //     const newFiles = Array.from(droppedFiles);
        //     setFiles((prevFiles: any) => [...prevFiles, ...newFiles]);
        // }
    };

    return (
        <>
            <DocumentsTitleStyle onClick={() => items > 0 && setIsVisible(!isVisible)}>
                <Text color='#7E7D83' fontWeight='bold'>{title}</Text>

                {
                    items > 0 &&
                        <img 
                            src={DownArrowIcon} 
                            alt='icon' 
                            style={{ 
                                rotate: isVisible ? '0deg' : '180deg' 
                            }}
                        />
                }
            </DocumentsTitleStyle>

            {
                isVisible &&
                <Container $margin='8px'>
                    {
                        items.length === 0 ?
                            <Container
                                $border="1px dashed #96CBFF"
                                $padding="20px"
                                $borderRadius="12px"
                                $margin="12px"
                                onDrop={handleDrop}
                                onDragOver={(event) => event.preventDefault()}
                            >
                                <img
                                    src={CloudUploadIcon}
                                    alt="icon"
                                    style={{
                                        margin: '0 auto',
                                        display: 'flex',
                                        width: '40px',
                                    }}
                                />

                                <Container $height="16px" />

                                <Text $textAlign="center" color="#9FAEC1">{t('empty_list.documents')}</Text>

                                <input
                                    type="file"
                                    hidden
                                    id="browse"
                                    onChange={handleFileChange}
                                    accept=".pdf,.docx,.pptx,.txt,.xlsx"
                                    multiple
                                />

                                <RowWidget $justifyContent="center">
                                    <BrowseButton htmlFor="browse">{t('button.browse')}</BrowseButton>
                                </RowWidget>
                            </Container>
                            :
                            items.map((item: any, index: number) =>
                                <DocumentsContainerStyle key={index} onClick={() => setShowDocument(true)}>
                                    <DocumentsDocHeaderStyle>
                                        <img
                                            src={item.creator === 'AI' ? AIDocumentIcon : DocumentIcon}
                                            alt="icon"
                                            width={item.creator === 'AI' ? '22px' : '20px'}
                                            height={item.creator === 'AI' ? '22px' : '24px'}
                                        />

                                        <DocumentsDocTitleStyle>
                                            <Text margin='0 0 4px 0' fontWeight='bold' overflow='hidden' $textOverflow='ellipsis'>{item.title}</Text>

                                            <Text fontSize='14px' color='#A4A3A7'>{item.creator} | {item.createdAt}</Text>
                                        </DocumentsDocTitleStyle>
                                    </DocumentsDocHeaderStyle>

                                    {
                                        item.creator === 'AI' && 
                                            <DocumentActionMenu />
                                    }
                                </DocumentsContainerStyle>
                            )
                    }
                </Container>
            }
        </>
    );
}


export default DocumentsList;
