import { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import SharelinkIcon from '../../assets/SharelinkIcon.svg';

import Popup from "./Popup"

import Input from "../Inputs/Input";

import SwitchToggle from "../Toggles/SwitchToggle";

import TextButton from "../Buttons/TextButton";

import Text from "../../shared/lib/widgets/Text";
import RowWidget from "../../shared/lib/widgets/RowWidget";
import Container from "../../shared/lib/widgets/Container";

import { caseActions } from "../../entities/Case/models/slices/CaseSlice";
import { updateCase } from "../../entities/Cases/models/services/cases.services";

import { useAppSelector } from "../../shared/components/useAppSelector/useAppSelector";
import { useAppDispatch } from "../../shared/components/useAppDispatch/useAppDispatch";

import { StateSchema } from "../../app/providers/StoreProvider/config/StateSchema";
import CollaboratorsData from "../Tables/CollaboratorsTable";


interface ShareCasePopupProps {
    onSubmit: (e: any) => void;
    onClose: () => void;
}

const ShareCasePopup: React.FC<ShareCasePopupProps> = ({ onSubmit, onClose }) => {
    const { t } = useTranslation();
    
    const dispatch = useAppDispatch();

    const caseData = useAppSelector((state: StateSchema) => state.case?.case);
    
    const [enableShare, setEnableShare] = useState(false);

    useEffect(() => {
        setEnableShare(caseData?.share || false);
    }, [caseData?.share]);
    
    const copyLinkAddress = () => {
        navigator.clipboard.writeText(`${process.env.REACT_APP_URL}/case/${caseData?.id}`)
        toast.success(t('messages.success.link_copied'));
    }

    return (
        <Popup title={t('chat.popup.share.title')} onClose={onClose}>
            <Container>
                <RowWidget $gap="8px">
                    <SwitchToggle
                        isActive={enableShare}
                        onToggle={() => {
                            setEnableShare(!enableShare);
                            dispatch(updateCase({ id: caseData?.id, share: !caseData?.share }))
                            dispatch(caseActions.updateCase({ share: !caseData?.share }))
                        }}
                    />
                    
                    <Text color="#908F94" fontSize="14px" fontWeight="400">{enableShare ? t('toggle.share_on') : t('toggle.share_off')}</Text>
                </RowWidget>

                <Container $height="24px" />

                <RowWidget $gap="8px">
                    <Input
                        value={`${process.env.REACT_APP_URL}case/${caseData?.id}`}
                        readOnly
                    />

                    <TextButton
                        onClick={copyLinkAddress}
                        leading={SharelinkIcon}
                        title={t('button.copy_link')}
                        color="#153968"
                    />
                </RowWidget>

                {
                    enableShare &&
                    <CollaboratorsData onSubmit={onSubmit} />
                }
            </Container>
        </Popup>
    );
};

export default ShareCasePopup;
