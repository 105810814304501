import React, { useEffect, useState } from 'react';

import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PlusIcon from '../assets/PlusIcon.svg';
import FolderIcon from '../assets/folder.svg';
import FilterIcon from '../assets/FilterIcon.svg';
import NoResultIcon from '../assets/no_result.svg';
import ArchiveButtonIcon from '../assets/ArchiveButtonIcon.svg';

import SortByDropdown from '../components/DropDown/SortByDropdown';

import { filterOptions } from '../shared/consts/default_values';

import Tooltip from '../shared/lib/widgets/Tooltip';

import CaseCard from '../components/Cards/CaseCard';
import PrimaryButton from '../components/Buttons/PrimaryButton';
import SecondaryButton from '../components/Buttons/SecondaryButton';

import InputSearch from '../components/Inputs/InputSearch';

import DropDown from '../components/DropDown/DropDown';

import { Case } from '../entities/Case/models/types/Case.types';
import { getCases } from '../entities/Cases/models/slices/CasesSlice';
import { newLegalCase } from '../entities/Case/models/services/case.services';
import { fetchCases } from '../entities/Cases/models/services/cases.services';

import { useAppSelector } from '../shared/components/useAppSelector/useAppSelector';
import { useAppDispatch } from '../shared/components/useAppDispatch/useAppDispatch';

import NameCasePopup from '../components/Popups/NameCasePopup';

import Text from '../shared/lib/widgets/Text';
import Container from '../shared/lib/widgets/Container';
import RowWidget from '../shared/lib/widgets/RowWidget';
import GridWidget from '../shared/lib/widgets/GridWidget';
import WelcomePopup from '../components/Popups/WelcomePopup';


const MyCases: React.FC = () => {
    const { t } = useTranslation();
    
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    
    useEffect(() => {
        dispatch(fetchCases());
    }, [dispatch]);

    const cases = useAppSelector(getCases.selectAll);

    const recentlyModifiedCases = cases
        .sort((a: any, b: any) => new Date(b.last_edit).getTime() - new Date(a.last_edit).getTime())
        .slice(0, 4);
        
    const [cookies, setCookie] = useCookies(['welcomeShown']);
    const [showWelcome, setShowWelcome] = useState(false);

    useEffect(() => {
        if (!cookies.welcomeShown) {
            setShowWelcome(true);

            setCookie('welcomeShown', 'true', {
                path: '/',
                expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
            });
        }
    }, [cookies, setCookie]);
        
    const [nameCase, setNameCase] = useState(false)
    
    const [timeFrame, setTimeFrame] = useState('');
    const [sortby, setSortby] = useState('');
    
    const filter_cases: any = () => {
        let filteredData = [...cases];
    
        if (timeFrame !== '')
            filteredData = filteredData.filter(caseItem => {
                const year = new Date(caseItem.creation_date).getFullYear();
                return year === parseInt(timeFrame);
            });

        if (sortby !== '')
            switch(sortby){
                case 'date_created':
                    filteredData
                    .sort((a: any, b: any) => new Date(a.creation_date).getTime() - new Date(b.creation_date).getTime())
                    break;
                case 'alphabetical':
                    filteredData.sort((a: any, b: any) => a.name.localeCompare(b.name));
                    break;
                case 'last_modified':
                    filteredData
                    .sort((a: any, b: any) => new Date(b.last_edit).getTime() - new Date(a.last_edit).getTime())
                    break;
                case 'a_z':
                    filteredData.sort((a: any, b: any) => a.name.localeCompare(b.name));
                    break;
                case 'z_a':
                    filteredData.sort((a: any, b: any) => b.name.localeCompare(a.name));
                    break;
            }
        
        return filteredData;
    };

    const filteredCases = filter_cases();

    return (
        <Container $padding='20px' style={{ fontFamily: 'SF Pro Display, sans-serif' }}>
            {
                showWelcome &&
                    <WelcomePopup 
                        onClose={() => setShowWelcome(false)}
                        onSubmit={() => {
                            setShowWelcome(false);
                            setNameCase(true);
                        }}
                    />
            }
            
            {
                nameCase &&
                <NameCasePopup 
                    onClose={() => setNameCase(false)}
                    onSubmit={(name: string) => dispatch(newLegalCase(name))}
                />
            }
            
            <Container $height='16px' />
            
            <RowWidget $justifyContent="space-between" $gap='16px' $flexWrap='wrap'>
                <Container>
                    <Text fontSize='32px' fontWeight="600">{t('my_cases.title')}</Text>

                    <Container $height='10px' />

                    <Text color='#201F29' fontWeight="400" fontSize='16px'>{t('my_cases.subtitle')}</Text>
                </Container>

                <RowWidget $height='fit-content' $flexWrap='wrap' $gap='16px'>
                    <Tooltip
                        title={t('tooltip.archive.title')}
                        description={t('tooltip.archive.description')}
                        position='left'
                    >
                        <SecondaryButton 
                            onClick={() => navigate('/archive')} 
                            leading={ArchiveButtonIcon} 
                            title={t('button.archive')}
                            $isLarge
                        />
                    </Tooltip>

                    <PrimaryButton 
                        onClick={() => setNameCase(true)}
                        leading={PlusIcon} 
                        title={t('button.new_legal_case')}
                        $isLarge
                        $width='200px'
                    />
                </RowWidget>
            </RowWidget>

            <Container $height='40px' />

            {
                cases.length !== 0 &&
                <>
                    <Text fontSize='22px' fontWeight="600">{t('my_cases.section.recent')}</Text>

                    <Container $height='16px' />

                    <GridWidget $columnsMinWidth='350px' $gap='16px'>
                        {
                            recentlyModifiedCases.map((item: Case) =>
                                <CaseCard key={item.id} caseData={item} isRecentlyUsed={true} />
                            )
                        }
                    </GridWidget>

                    <Container $height='40px' />
                </>
            }

            <Text fontSize='22px' fontWeight="600">{t('my_cases.section.all_cases')}</Text>

            <Container $height='16px' />

            <Container>
                <RowWidget $flexWrap='wrap' $gap='16px'>
                    <Tooltip
                        title={t('tooltip.search.title')}
                        description={t('tooltip.search.description')}
                    >
                        <InputSearch placeholder={t('dropdown.filter.search')} someData={cases} />
                    </Tooltip>

                    <Tooltip
                        title={t('tooltip.timeframe.title')}
                        description={t('tooltip.timeframe.description')}
                    >
                        <DropDown 
                            width='200px'
                            dropDownName={t('dropdown.filter.timeframe')}
                            value={timeFrame} 
                            onChange={(e) => {
                                setTimeFrame(e);
                            }} 
                            menuItems={filterOptions.year} 
                        />
                    </Tooltip>

                    <RowWidget $flex='1' $justifyContent='end'>
                        <Tooltip
                            title={t('tooltip.sort_by.title')}
                            description={t('tooltip.sort_by.description')}
                        >
                            <SortByDropdown 
                                width='200px'
                                dropDownName={t('dropdown.filter.sort_by')}
                                value={sortby} 
                                leading={FilterIcon}
                                translation={'sort_by'}
                                onChange={setSortby} 
                                title1={t('dropdown.filter.sort_by')}
                                title2={t('dropdown.filter.sort_by')}
                                menuItems1={filterOptions.sort.slice(0, 3)} 
                                menuItems2={filterOptions.sort.slice(3)} 
                            />
                        </Tooltip>
                    </RowWidget>
                </RowWidget>

                {
                    (timeFrame || sortby) ?
                        <RowWidget $margin="20px 0 0 0" $gap="8px">
                            <Text color="#7E7D83">{t('my_cases.search_query')} “{ timeFrame ? timeFrame : sortby ? t(`dropdown.sort_by.${sortby}`) : '' }”</Text>
                            <Text color="#7E7D83" fontWeight="bold" style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => {
                                setTimeFrame('');
                                setSortby('');
                            }}>{t(`button.cancel`)}</Text>
                        </RowWidget>
                        :
                        null
                }
            </Container>


            <Container $height='24px' />

            {
                cases.length === 0 ?
                    <RowWidget $flexDirection='column' $alignItems='center' $justifyContent='center' $margin='128px 0 0 0'>
                            <img src={FolderIcon} alt="icon" width="50px" height="50px"/>

                            <Container $height='16px' />

                            <Text $textAlign='center' color="#BBC6D3">{t('my_cases.no_cases')}</Text>
                    </RowWidget>
                    :
                    filteredCases && filteredCases.length === 0 ?
                        <RowWidget $flexDirection='column' $alignItems='center' $justifyContent='center' $margin='128px 0 0 0'>
                            <img src={NoResultIcon} alt="icon" width="50px" height="50px"/>

                            <Container $height='16px' />

                            <Text $textAlign='center' color="#778CA7" fontWeight='bold' fontSize='20px'>{t('my_cases.no_search_query.title')}</Text>

                            <Container $height='12px' />

                            <Text $textAlign='center' color="#BBC6D3">{t('my_cases.no_search_query.description')}</Text>
                        </RowWidget>
                        :
                        <GridWidget $columnsMinWidth='350px' $gap='16px'>
                            {
                                filteredCases?.map((item: Case) => (
                                    <CaseCard key={item.id} caseData={item} />
                                ))
                            }
                        </GridWidget>
            }
        </Container >
    );
};

export default MyCases;