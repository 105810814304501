import React, { useState } from "react";

import { toast } from "react-toastify";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';

import { useAppSelector } from "../../shared/components/useAppSelector/useAppSelector";

import { StateSchema } from "../../app/providers/StoreProvider/config/StateSchema";

import EditBillingInfoPopup from "../Popups/EditBillingInfoPopup";

import SecondaryButton from "../Buttons/SecondaryButton";

import Text from "../../shared/lib/widgets/Text";
import Container from "../../shared/lib/widgets/Container";
import RowWidget from "../../shared/lib/widgets/RowWidget";


const BillingUserDataCardStyle = styled.div`
    width: 100%;
    border-radius: 12px;
    border: 1px solid #e9e9ea;
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    @media (max-width: 666px) {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
`;

const BillingUserDataCard = () => {
    const { t } = useTranslation();

    const user = useAppSelector((state: StateSchema) => state.user.userData);
    
    const [showBillingInfo, setBillingInfo] = useState(false);

    return (
        <BillingUserDataCardStyle>
            {
                showBillingInfo && 
                <EditBillingInfoPopup 
                    onSubmit={() => toast.info(t('messages.info.service_not_available'))} 
                    onClose={() => setBillingInfo(false)}
                />
            }

            <Container $width="100%">
                <RowWidget $gap='8px'>
                    <Text color="#7e7d83" fontWeight="bold">{t('input.user_name_label')}:</Text>

                    <Text>{user?.first_name}</Text>
                </RowWidget>
                
                <Container $height="10px" />
                
                <RowWidget $gap='8px'>
                    <Text color="#7e7d83" fontWeight="bold">{t('input.user_surname_label')}:</Text>

                    <Text>{user?.last_name}</Text>
                </RowWidget>
            </Container>
            
            <Container $width="100%">
                <RowWidget $gap='8px'>
                    <Text color="#7e7d83" fontWeight="bold">{t('settings.billing_and_payments.section.info_details.pec_address')}:</Text>

                    <Text>-</Text>
                </RowWidget>
                
                <Container $height="10px" />
                
                <RowWidget $gap='8px'>
                    <Text color="#7e7d83" fontWeight="bold">{t('settings.billing_and_payments.section.info_details.sdi_code')}:</Text>

                    <Text>-</Text>
                </RowWidget>
            </Container>
            
            <Container $width="100%">
                <RowWidget $gap='8px'>
                    <Text color="#7e7d83" fontWeight="bold">{t('settings.billing_and_payments.section.info_details.vat_number')}:</Text>

                    <Text>-</Text>
                </RowWidget>
                
                <Container $height="10px" />
                
                <RowWidget $gap='8px'>
                    <Text color="#7e7d83" fontWeight="bold">{t('settings.billing_and_payments.section.info_details.tax_code')}:</Text>

                    <Text>-</Text>
                </RowWidget>
            </Container>
            
            <RowWidget $justifyContent="flex-end" $width="100%">
                <SecondaryButton
                    onClick={() => setBillingInfo(true)}
                    title={t('button.edit')}
                    $width="120px"
                />
            </RowWidget>
        </BillingUserDataCardStyle>
    );
}

export default BillingUserDataCard;