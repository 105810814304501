import { useState } from "react";

import { EditorState, RichUtils, DraftHandleValue, DraftEditorCommand } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import UndoIcon from '../../assets/tools/undo.svg';
import RedoIcon from '../../assets/tools/redo.svg';
import AlignmentIcon from '../../assets/tools/alignment.svg';
import BoldIcon from '../../assets/tools/bold.svg';
import ItalicIcon from '../../assets/tools/italic.svg';
import UnderlineIcon from '../../assets/tools/underline.svg';
import CanceledIcon from '../../assets/tools/canceled.svg';
import DotListIcon from '../../assets/tools/dot_list.svg';
import NumberedListIcon from '../../assets/tools/numbered_list.svg';

import EditorHeader from "./EditorHeader";

import Container from "../../shared/lib/widgets/Container";

import './richEditor.css';


const DocumentEditor = () => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const onChange = (newEditorState: EditorState) => setEditorState(newEditorState);

    const handleKeyCommand = (command: DraftEditorCommand, editorState: EditorState): DraftHandleValue => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setEditorState(newState);
            return 'handled';
        }
        return 'not-handled';
    };

    const styleMap = {
        STRIKETHROUGH: {
            textDecoration: 'line-through',
        },
        SUPERSCRIPT: {
            verticalAlign: 'super',
            fontSize: 'smaller',
        },
        SUBSCRIPT: {
            verticalAlign: 'sub',
            fontSize: 'smaller',
        },
    };

    // const isStyleActive = (style: string) => editorState.getCurrentInlineStyle().has(style);

    return (
        <>
            <EditorHeader editorState={editorState} setEditorState={setEditorState} />

            <Container $backgroundColor="#F9FAFC" $padding="16px 0">
                <Editor
                    toolbar={{
                        options: ['history', 'blockType', 'fontSize', 'textAlign', 'colorPicker', 'inline', 'list', 'link', 'remove'],
                        history: {
                            options: ['undo', 'redo'],
                            undo: { icon: UndoIcon, className: 'tool-icon' },
                            redo: { icon: RedoIcon, className: 'tool-icon' },
                        },
                        inline: {
                            options: ['bold', 'italic', 'underline', 'strikethrough'],
                            bold: { icon: BoldIcon, className: 'tool-icon' },
                            italic: { icon: ItalicIcon, className: 'tool-icon' },
                            underline: { icon: UnderlineIcon, className: 'tool-icon' },
                            strikethrough: { icon: CanceledIcon, className: 'tool-icon' },
                            superscript: true,
                            subscript: true,
                        },
                        textAlign: {
                            inDropdown: true,
                            options: ['left', 'center', 'right', 'justify'],
                            left: { icon: AlignmentIcon, className: undefined },
                            center: { icon: AlignmentIcon, className: undefined },
                            right: { icon: AlignmentIcon, className: undefined },
                            justify: { icon: AlignmentIcon, className: undefined },
                        },
                        list: {
                            inDropdown: false,
                            options: ['unordered', 'ordered'],
                            unordered: { icon: DotListIcon, className: 'tool-icon' },
                            ordered: { icon: NumberedListIcon, className: 'tool-icon' },
                        },
                        link: {
                            inDropdown: false,
                            options: ['link'],
                            className: 'tool-icon'
                        },
                    }}
                    toolbarStyle={{
                        position: 'fixed',
                        top: '90px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        zIndex: '3',
                        backgroundColor: 'transparent',
                        border: 'none',
                        padding: '0',
                        margin: '0',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                    editorStyle={{
                        width: '920px',
                        height: '100%',
                        minHeight: '100vh',
                        backgroundColor: '#FFF',
                        margin: 'auto',
                        borderRadius: '12px',
                        padding: '32px',
                    }}
                    editorState={editorState}
                    handleKeyCommand={handleKeyCommand}
                    onEditorStateChange={onChange}
                    customStyleMap={styleMap}
                />
            </Container>
        </>
    );
};

export default DocumentEditor;
