import { Suspense, memo } from "react";
import { Route, Routes } from "react-router-dom";

import { RequireAuth } from "./RequireAuth";

import { routeConfig } from "../models/config/routeConfig";
import { AppRoutesProps } from "../models/types/AppRoutesProps.types";

import Loader from "../../../../shared/lib/widgets/Loader";
import Header from "../../../../components/Header/Header";


const renderWithWrapper = (route: AppRoutesProps) => {
    const element = <Suspense fallback={<Loader />}>{route.element}</Suspense>;

    return (
        <Route
            key={route.path}
            path={route.path}
            element={
                route.authOnly ? 
                <RequireAuth>
                    <>
                        <Header />
                        {element}
                    </>
                </RequireAuth> 
                : element}
        />
    );
}

export const AppRouter = memo(function AppRouter() {
    return <Routes>{Object.values(routeConfig).map(renderWithWrapper)}</Routes>;
})
