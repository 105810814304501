import React, { useState } from "react";

import styled from "styled-components";

import VideoPopup from '../Popups/VideoPopup';

import PlayIcon from '../../assets/PlayIcon.svg';

import Text from "../../shared/lib/widgets/Text";
import RowWidget from "../../shared/lib/widgets/RowWidget";


const TutorialCardContainer = styled.div`
    width: 100%;
    max-width: 386px;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const VideoContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const VideoPreview = styled.img`
    background-color: #FFEEEE;
    width: 100%;
    max-height: 200px;
    min-height: 200px;
    height: 100%;
    border-radius: 16px;
    object-fit: cover;
    display: flex;
    cursor: pointer;
`;

const TimeContainer = styled.div`
    position: absolute;
    width: fit-content;
    height: fit-content;
    bottom: 12px;
    left: 12px;
    padding: 6px 10px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    gap: 4px;
    background-color: #000000b2;
`;

const DifficultyContainer = styled.div<{ $backgroundcolor: string }>`
    position: absolute;
    width: fit-content;
    height: fit-content;
    top: 12px;
    left: 12px;
    padding: 6px 10px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    gap: 4px;
    background-color: ${(props) => props.$backgroundcolor};
    color: #FFF;
    font-weight: 400;
    font-size: 14px;
    font-family: 'SF Pro Display';
`;

const TutorialText = styled.div`
    height: 100%;
`;

const TutorialDescription = styled.div`
    margin-top: 10px;
    font-size: 14px;
    color: #666;
    height: 35px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
`;


interface TutorialCardProps {
    link: string;
    title: string;
    description: string;
    src?: string;
    level?: string;
    time?: string;
    date?: string;
    price?: string;
}

enum ArticleLevel {
    Beginner = "Beginner",
    Intermediate = "Intermediate",
    Advanced = "Advanced",
}

const TutorialCard: React.FC<TutorialCardProps> = (props) => {
    const [showVideoPreview, setShowVideoPreview] = useState(false);
    
    const getEnumLevel = (level: string) => {
        switch (level) {
            case '1':
                return ArticleLevel.Beginner;
            case '2':
                return ArticleLevel.Intermediate;
            case '3':
                return ArticleLevel.Advanced;
            default:
                return null;
        }
    };

    const getBackgroundColor = () => {
        const enumLvl = getEnumLevel(props.level!)
        switch (enumLvl) {
            case ArticleLevel.Beginner:
                return '#6CC243'; // Green
            case ArticleLevel.Intermediate:
                return '#6174E8'; // Blue
            case ArticleLevel.Advanced:
                return '#FF5252'; // Red
            default:
                return '#000'; // Default case
        }
    };
    return (
        <TutorialCardContainer>
            {
                showVideoPreview &&
                <VideoPopup 
                    videoSrc={props.link}
                    videoTitle={props.title}
                    onClose={() => setShowVideoPreview(false)}
                />
            }

            <VideoContainer>
                <VideoPreview onClick={() => setShowVideoPreview(true)} src={props.src} alt="" />

                {
                    props.level &&
                    <DifficultyContainer $backgroundcolor={getBackgroundColor()}>{getEnumLevel(props.level)}</DifficultyContainer>
                }
                
                {
                    props.time &&
                    <TimeContainer>
                        <img width="12px" height="12px" src={PlayIcon} alt="icon" />
                        <Text fontSize="14px" fontWeight="400" color="#FFF">{props.time}</Text>
                    </TimeContainer>
                }
            </VideoContainer>
            
            <TutorialText>

                <Text fontSize="20px" fontWeight="bold" color="#333">{props.title}</Text>

                <TutorialDescription>{props.description}</TutorialDescription>

                {
                    props.date || props.price ?
                    <RowWidget $justifyContent="space-between" $alignItems="center">
                        <Text fontSize="14px" fontWeight="400" color="#8E9092">{props.date}</Text>
                        <Text fontSize="16px" fontWeight="bold" color={props.price ? '#333' : '#6CC243'}>{props.price ? props.price : 'FREE'}</Text>
                    </RowWidget>
                    : null
                }
            </TutorialText>
        </TutorialCardContainer>
    );
}

export default TutorialCard;