import styled from "styled-components";


interface TextProps {
    margin?: string;
    padding?: string;
    fontSize?: string;
    fontWeight?: string;
    overflow?: string;
    color?: string;
    $textAlign?: string;
    $textOverflow?: string;
}

const Text = styled.div<TextProps>`
    margin: ${(props) => props.margin || '0'};
    padding: ${(props) => props.padding || '0'};
    font-size: ${(props) => props.fontSize || 'inherit'};
    font-weight: ${(props) => props.fontWeight || 'normal'};
    overflow: ${(props) => props.overflow || 'visible'};
    color: ${(props) => props.color || '#201F29'};
    text-align: ${(props) => props.$textAlign || 'left'};
    text-overflow: ${(props) => props.$textOverflow || ''};
    font-family: 'SF Pro Display';
`;

export default Text;
