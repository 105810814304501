import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import Text from "../../shared/lib/widgets/Text";
import RowWidget from "../../shared/lib/widgets/RowWidget";

import Popup from "./Popup"

import DropDown from "../DropDown/DropDown";

import CompanyForm from "../Forms/CompanyForm";
import FreelancerForm from "../Forms/FreelancerForm";
import IndividualForm from "../Forms/IndividualForm";
import PhysicalPersonForm from "../Forms/PhysicalPersonForm";
import FirmOrAssociationForm from "../Forms/FirmOrAssociationForm";


interface EditBillingInfoPopupProps {
    onSubmit: () => void;
    onClose: () => void;
}

const EditBillingInfoPopup: React.FC<EditBillingInfoPopupProps> = ({ onClose }) => {
    const { t } = useTranslation();
    
    const [selectedType, setSelectedType] = useState(t('dropdown.legal_entity.title'));
    
    const formComponents: any = {
        [t('dropdown.legal_entity.freelancer')]: FreelancerForm,
        [t('dropdown.legal_entity.individual_enterprise')]: IndividualForm,
        [t('dropdown.legal_entity.company')]: CompanyForm,
        [t('dropdown.legal_entity.law_firm_or_professional_association')]: FirmOrAssociationForm,
        [t('dropdown.legal_entity.physical_person')]: PhysicalPersonForm,
    };

    const SelectedForm = formComponents[selectedType];

    return (
        <Popup title={t('settings.billing_and_payments.popup.title')} onClose={onClose} width="650px">
            <RowWidget $flexDirection='column'>
                <Text fontSize="16px" fontWeight="400" color="rgba(70, 69, 77, 1)" margin="0 0 4px 0">{t('dropdown.legal_entity.label')}</Text>
                
                <DropDown 
                    dropDownName={selectedType}
                    value={selectedType} 
                    onChange={setSelectedType} 
                    menuItems={Object.keys(formComponents)} 
                />

                {SelectedForm && <SelectedForm onClose={onClose} />}
            </RowWidget>
        </Popup>
    );
};

export default EditBillingInfoPopup;
