import { useState } from "react";

import styled from "styled-components";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import PlusIcon from '../../assets/PlusIcon.svg';
import CardIcon from '../../assets/Card.svg';
import ArrowIcon from '../../assets/Arrowleft.svg';

import AddCreditCardPopup from "../Popups/AddCreaditCardPopup";

import TextButton from "../Buttons/TextButton";
import PrimaryButton from "../Buttons/PrimaryButton";

import LoadStripe from "../../shared/components/LoadStripe/LoadStripe";

import Text from "../../shared/lib/widgets/Text";
import Container from "../../shared/lib/widgets/Container";
import RowWidget from "../../shared/lib/widgets/RowWidget";


const CreditCardCard = styled.div`
    flex: 1;
    height: 100%;
    padding: 20px;
    border-radius: 12px;
    background-color: #F6F7F9;
    @media (max-width: 768px) {
        flex: 1 1 350px;
        width: 100%;
    }
`;

const CreditCard = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const [showCrediCardField, setShowCrediCardField] = useState(false);
    return (
        <>
            {
                showCrediCardField &&
                <LoadStripe>
                    <AddCreditCardPopup
                        onSubmit={() => toast.info(t('messages.info.service_not_available'))}
                        onClose={() => setShowCrediCardField(false)}
                    />
                </LoadStripe>
            }

            {
                true ?
                    <CreditCardCard>
                        <Text fontWeight='bold' fontSize='20px'>{t('subscriptions.credit_card.title')}</Text>

                        <Container $height='16px' />

                        <Text color='#7E7D83'>{t('subscriptions.credit_card.subtitle')}</Text>

                        <Container $height='64px' />

                        <PrimaryButton
                            onClick={() => setShowCrediCardField(true)}
                            leading={PlusIcon}
                            title={t('button.add_credit_card')}
                            $width='200px'
                        />
                    </CreditCardCard>
                    :
                    <CreditCardCard>
                        <RowWidget $justifyContent='space-between'>
                            <Text fontWeight='bold' fontSize='20px'>Carta di credito</Text>

                            <RowWidget $alignItems='center' $gap='8px' style={{ cursor: 'pointer' }} onClick={() => navigate('/settings')}>
                                <Text color='#153968' fontWeight='bold'>Cronologia della fatture</Text>

                                <img
                                    src={ArrowIcon}
                                    alt="icon"
                                    width="16px"
                                    height="16px"
                                    style={{ rotate: '180deg' }}
                                />
                            </RowWidget>
                        </RowWidget>

                        <Container $height='20px' />

                        <RowWidget $gap='12px'>
                            <Container $backgroundColor='#e8ebf0' $borderRadius='50%' $height='50px' $width='50px'>
                                <RowWidget $justifyContent='center' $alignItems='center' $height='100%'>
                                    <img
                                        src={CardIcon}
                                        alt="icon"
                                        width="25px"
                                        height="25px"
                                    />
                                </RowWidget>
                            </Container>

                            <Container>
                                <Text color='#7E7D83' fontSize='14px' fontWeight='bold'>Carta di credito</Text>

                                <Container $height='10px' />

                                <Text>Visa **** **** **** xxxx</Text>

                                <Container $height='12px' />

                                <Text fontWeight='bold' fontSize='18px'>xxxxx xxxxx</Text>

                                <Container $height='16px' />

                                <TextButton
                                    onClick={() => setShowCrediCardField(true)}
                                    title='Cambia metodo di pagamento'
                                    fontWeight='bold'
                                />
                            </Container>
                        </RowWidget>
                    </CreditCardCard>
            }
        </>
    );
}

export default CreditCard;