import styled from "styled-components";


interface RowProps {
    $width?: string;
    $height?: string;
    $justifyContent?: string;
    $alignItems?: string;
    $flexDirection?: string;
    $flexWrap?: string;
    $flex?: string;
    $gap?: string;
    $margin?: string;
    $padding?: string;
}

const RowWidget = styled.div<RowProps>`
    display: flex;
    width: ${(props) => props.$width || 'auto'};
    height: ${(props) => props.$height || 'auto'};
    justify-content: ${(props) => props.$justifyContent || 'flex-start'};
    align-items: ${(props) => props.$alignItems || 'stretch'};
    flex-direction: ${(props) => props.$flexDirection || 'row'};
    flex-wrap: ${(props) => props.$flexWrap || 'nowrap'};
    flex: ${(props) => props.$flex || '0 1 auto'};
    gap: ${(props) => props.$gap || '0'};
    margin: ${(props) => props.$margin || '0'};
    padding: ${(props) => props.$padding || '0'};
`;

export default RowWidget;
