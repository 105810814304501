import { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { signOut } from "firebase/auth";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation } from "react-router-dom";

import { auth } from "../../../../configs/firebase-config";

import { USER_LOCALSTORAGE_KEY } from "../../../../shared/consts/localStorage";

import { getRouteSignIn } from "../../../../shared/consts/routes";

import { StateSchema } from "../../StoreProvider/config/StateSchema";

import { userActions } from "../../../../entities/User/models/slices/UserSlice";
import { casesActions } from "../../../../entities/Cases/models/slices/CasesSlice";

import { useAppSelector } from "../../../../shared/components/useAppSelector/useAppSelector";
import { useAppDispatch } from "../../../../shared/components/useAppDispatch/useAppDispatch";

import PrimaryButton from "../../../../components/Buttons/PrimaryButton";

import RowWidget from "../../../../shared/lib/widgets/RowWidget";
import Loader from "../../../../shared/lib/widgets/Loader";
import Text from "../../../../shared/lib/widgets/Text";
import Header from "../../../../components/Header/Header";


interface RequireAuthProps {
    children: JSX.Element;
}

export function RequireAuth({ children }: RequireAuthProps) {
    const { t } = useTranslation();

    const location = useLocation();
    const dispatch = useAppDispatch();

    const userState = useAppSelector((state: StateSchema) => state.user);

    const userId = localStorage.getItem(USER_LOCALSTORAGE_KEY);
    
    const [cookies, setCookie] = useCookies(['accessToken']);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (authUser) => {
            if (authUser) {
                try {
                    const tokenResult = await authUser.getIdTokenResult();
                    const tokenExpirationTime = new Date(tokenResult.expirationTime).getTime();
                    const currentTime = Date.now();
    
                    if (tokenExpirationTime - currentTime < 3600000) {
                        const refreshedToken = await authUser.getIdToken(true);
                        setCookie('accessToken', refreshedToken, {
                            expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
                        });
                    }
                } catch (error: any) {
                    if (error.code === 'auth/id-token-expired') {
                        toast.info('Session expired. Please sign in again.');
                    } else {
                        console.error('An error occurred during token refresh:', error);
                    }
                }
            } else {
                setCookie('accessToken', '');
            }
    
            setLoading(false);
        });
    
        return () => unsubscribe();
    }, [setCookie]);

    if (loading) {
        return (
            <RowWidget $justifyContent='center' $alignItems='center' $height='80vh'>
                <Loader />
            </RowWidget>
        );
    }

    if (!cookies || !userId) {
        return (
            <Navigate
                to={getRouteSignIn()}
                state={{ from: location }}
                replace
            />
        );
    } else if (!userState.userData?.email) {
        return (
            <>
                <Header />
                
                <RowWidget $justifyContent='center' $alignItems='center' $height='80vh' $gap="8px">
                    <Text>{t('empty_list.user_not_found')}</Text>
                    <PrimaryButton 
                        title="Esci"
                        onClick={() => {
                            dispatch(userActions.logout());
                            dispatch(casesActions.logout());
                            signOut(auth);
                        }}
                        $width="120px"
                    />
                </RowWidget>
            </>
        );
    }

    return children;
}