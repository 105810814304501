import React from "react";

import styled from 'styled-components';
import { useTranslation } from "react-i18next";

import ContactPopup from "./ContactPopup"

import VideoPlayer from "../VideoPlayer/VideoPlayer";

import Text from "../../shared/lib/widgets/Text";
import Container from "../../shared/lib/widgets/Container";
import RowWidget from "../../shared/lib/widgets/RowWidget";


export const PlayerWrapper = styled.div<{ $isFullscreen?: Boolean, $videoSrc?: boolean }>`
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
    border-radius: ${(props) => props.$isFullscreen ? '' : '16px'};
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.$videoSrc ? '' : '#46454d'};
    &:hover {
        .controls_visibility {
            visibility: visible;
            bottom: 0;
        }
    }
`;

interface VideoPopupProps {
    videoSrc: string;
    videoTitle: string;
    onClose: () => void;
}

const VideoPopup: React.FC<VideoPopupProps> = ({ videoSrc, videoTitle, onClose }) => {
    const { t } = useTranslation();

    return (
        <ContactPopup title={videoTitle} onClose={onClose} width="900px">
            <Container>
                <Container $height="16px" />

                {
                    videoSrc ?
                        <VideoPlayer video={videoSrc} />
                        :
                        <PlayerWrapper>
                            <RowWidget $justifyContent="center" $height="100%" $alignItems="center">
                                <Text fontWeight="bold" fontSize="26px" color="#FFF">No video provided</Text>
                            </RowWidget>
                        </PlayerWrapper>
                }

                <Container $height="16px" />

                <Text color='#A4A3A7'>{t('academy.popup.player.description')}</Text>
            </Container>
        </ContactPopup>
    );
}

export default VideoPopup;