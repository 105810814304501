import React, { useState } from "react";

import styled from "styled-components";
import { Link } from "react-router-dom";

import SearchIcon from '../../assets/SearchIcon.svg';
import CrossIcon from '../../assets/SearchCross.svg';


const SearchContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    min-width: 500px;
    height: 45px;
    border: 1px solid #E9E9EA;
    border-radius: 6px;
    background-color: #FFF;
    &:hover {
        box-shadow: 0px 6px 16px 0px #201F2914;
    }
    &:focus-within {
        outline: 1px solid #4158E3;
        box-shadow: 0px 6px 16px 0px #201F2914;
    }

    @media (max-width: 532px) {
        width: 100%;
        min-width: auto;
        flex: 1 1 500px;
    }
`;

const SearchIconStyle = styled.img`
    width: 20px;
    height: 20px;
    margin: 0 12px;
`;

const SearchInput = styled.input`
    border-radius: 8px;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    color: #000;
`;

const CrossIconStyle = styled.img`
    width: 12px;
    height: 12px;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
`;

const SuggestionsContainer = styled.div`
    position: absolute;
    width: 100%;
    top: calc(100% + 6px);
    left: 0;
    z-index: 1;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 6px 16px 0px #201F2914;
    max-height: 170px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        background: transparent; 
    }
    &::-webkit-scrollbar-thumb {
        cursor: pointer;
        border-radius: 40px;
        background: #E8ECF0; 
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #aaa; 
    }
`;

const SuggestionItem = styled.a`
    margin: 2px;
    display: block;
    padding: 8px;
    border-radius: 4px;
    &:hover {
        background-color: #F6F7F9;
    }
`;

interface InputSearchProps {
    placeholder: string;
    someData?: any;
}

const InputSearch: React.FC<InputSearchProps> = ({ placeholder, someData }) => {
    const [inputValue, setInputValue] = useState('');

    return (
        <SearchContainer>            
            <SearchIconStyle src={SearchIcon} alt="icon" />

            <SearchInput value={inputValue} onChange={(e) => setInputValue(e.target.value)} placeholder={placeholder} />
            
            {
                inputValue &&
                <CrossIconStyle onClick={() => setInputValue('')} src={CrossIcon} alt="icon" />
            }

            {
                inputValue && someData &&
                <SuggestionsContainer>
                    {
                        someData
                        .filter((data: any) => data.name.toLowerCase().includes(inputValue.toLowerCase()))
                        .map((item: any, index: number) => 
                            <Link key={index} to={item.id ? `/case/${item.id}` : item.link} style={{ textDecoration: 'none', color: '#201F29' }}>
                                <SuggestionItem>
                                    {item.name}
                                </SuggestionItem>
                            </Link>
                        )
                    }
                </SuggestionsContainer>
            }
        </SearchContainer>
    );
}

export default InputSearch;