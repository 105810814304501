import React from "react";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { StateSchema } from "../app/providers/StoreProvider/config/StateSchema";

import { useAppSelector } from "../shared/components/useAppSelector/useAppSelector";

import Header from "../components/Header/Header";

import Text from "../shared/lib/widgets/Text";
import Container from "../shared/lib/widgets/Container";
import RowWidget from "../shared/lib/widgets/RowWidget";


const NotFound: React.FC = () => {    
    const { t } = useTranslation();
    
    const user = useAppSelector((state: StateSchema) => state.user.userData);

    return (
        <>
            {
                user &&
                <Header />
            }

            <Container $margin="16px" $border="1px solid grey" $borderRadius="16px">
                <RowWidget $justifyContent="center" $alignItems="center" $gap="8px" $height="80px">
                    <Text color="grey" fontSize="18px">{t('empty_list.page_not_found')}</Text>
                    <Link style={{ color: '#4158E3', fontSize: '18px', fontWeight: 'bold' }} to='/'>Home</Link>
                </RowWidget>
            </Container>
        </>
    )
}


export default NotFound;
