import React, { MutableRefObject, useRef, useState } from "react";

import styled from 'styled-components';

import screenfull from "screenfull";
import ReactPlayer from "react-player";

import PlayIcon from '../../assets/play.svg';
import PlayButtonIcon from '../../assets/play_button.svg';
import PauseIcon from '../../assets/pause.svg';
import FullScreenIcon from '../../assets/full_screen.svg';

import { PlayerWrapper } from "../Popups/VideoPopup";

import RowWidget from "../../shared/lib/widgets/RowWidget";


const CenteralButton = styled.img<{ $playing: boolean }>`
    visibility: ${(props) => props.$playing ? 'hidden' : 'visible'};
    width: 100px;
    height: 100px;
    object-fit: contain;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const Controls = styled.div`
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 4px 16px 8px 16px;
    transition: .3s;
    visibility: hidden;
    position: absolute;
    bottom: -30%;
`;

const ControlButtons = styled.div`
    display: grid;
    width: 100%;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;
`;

const Progress = styled.input`
    width: 100%;
    height: 8px;
    outline: none;
    margin-bottom: 8px;
    overflow: hidden;
    cursor: pointer;
    border-radius: 8px;

    &::-webkit-slider-thumb {
        visibility: hidden;
    }

    &::-webkit-slider-runnable-track {
        background: linear-gradient(to right, #fdfdfe ${(props: any) => props.value * 100}%, #7e7d83 ${(props: any) => props.value * 100}%);
    }
`;

const VolumeProgress = styled.input.attrs({ type: 'range' })`
    height: 5px;
    outline: none;
    cursor: pointer;
    border-radius: 8px;
    appearance: none;

    &::-webkit-slider-runnable-track {
        height: 6px;
        background: ${(props) => `linear-gradient(to right, #fdfdfe ${props.value}%, #7e7d83 ${props.value}%)`};
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        height: 22px;
        width: 6px;
        background: #fff;
        margin-top: -8px;
    }
`;

const Time = styled.div`
    margin-left: 10px;
    color: white;
`;

const PlayerIcon = styled.img`
    cursor: pointer;
`;

const SpeedControls = styled.div`
    position: relative;
`;

const SpeedButton = styled.button`
    color: #FFF;
    font-size: 20px;
    cursor: pointer;
    background: none;
    border: none;
`;

const SpeedDropDown = styled.div`
    position: absolute;
    bottom: calc(100% + 10px);
    right: 0;
    background-color: #FFF;
    padding: 4px;
    border-radius: 6px;
`;

const SpeedDropDownItem = styled.div<{ $isActive: boolean }>`
    cursor: pointer;
    border-radius: 6px;
    color: ${(props) => props.$isActive ? 'red' : '#201F29'};
    font-size: 16px;
    width: 80px;
    padding: 8px 16px;
    
    &:hover {
        background-color: #F6F7F9;
    }
`;

interface State {
    playing: boolean;
    played: number;
    duration: number;
    muted: boolean;
    volume: number;
    playbackRate: number;
}

interface VideoPlayerProps {
    video: string;
}

let count = 0;

const VideoPlayer: React.FC<VideoPlayerProps> = ({ video }) => {
    const [state, setState] = useState<State>({
        playing: true,
        played: 0,
        duration: 0,
        muted: false,
        volume: 1,
        playbackRate: 1,
    });

    const [showPlaybackRate, setShowPlaybackRate] = useState(false);
    const [showControls, setShowControls] = useState(false);

    const playerRef = useRef() as MutableRefObject<ReactPlayer>;
    const playerContainerRef = useRef<HTMLDivElement>(null);

    const handleMouseMove = () => {
        setShowControls(true);
        count = 0;
    }
    const hanldeMouseLeave = () => {
        setShowControls(false);
        count = 0;
    }

    const handleFullScreen = () => {
        screenfull.toggle(playerContainerRef.current!);
    }
    
    const handleProgressHover = (changeState: any) => {
        setState({ ...state, played: changeState.played });

        if (count > 2) {
            if (showControls) 
                setShowControls(false);
            count = 0;
        }

        if (showControls) {
            count += 1;
        }
    };
    
    const handlePlayPause = () => {
        setState({ ...state, playing: !state.playing });
    };

    const handlerVolumeChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setState({ ...state, 
            volume: parseFloat(e.target.value) / 100,
            muted: parseInt(e.target.value) === 0 ? true : false, 
        });
    }

    const handleSeekChange = (e: any) => {
        setState({ ...state, played: parseFloat(e.target.value) });
        playerRef.current.seekTo(parseFloat(e.target.value));
    };

    const handleDuration = (duration: any) => {
        setState({ ...state, duration: duration });
    };

    const formatTime = (seconds: any) => {
        const date = new Date(seconds * 1000);
        const hh = date.getUTCHours();
        const mm = date.getUTCMinutes();
        const ss = date.getUTCSeconds();
        if (hh) {
            return `${hh}:${mm.toString().padStart(2, '0')}:${ss.toString().padStart(2, '0')}`;
        }
        return `${mm}:${ss.toString().padStart(2, '0')}`;
    };

    return (
        <PlayerWrapper 
            onMouseMove={handleMouseMove}
            onMouseLeave={hanldeMouseLeave}
            ref={playerContainerRef}
            onDoubleClick={(e: any) => {
                if (e.target.tagName === 'VIDEO')
                    handleFullScreen();
            }}
            $isFullscreen={screenfull.isFullscreen}
            $videoSrc={!!video}
            onClick={(e:any) => {
                if (e.target.tagName === 'VIDEO')
                    handlePlayPause();
            }}
        >
            <ReactPlayer
                ref={playerRef}
                url={video}
                onProgress={handleProgressHover}
                onDuration={handleDuration}
                controls={false}
                playing={state.playing}
                playbackRate={state.playbackRate}
                volume={state.volume}
                muted={state.muted}
                width="100%"
                height="auto"
            />

            {
                !state.playing &&
                    <CenteralButton $playing={state.playing} src={PlayButtonIcon} alt="icon" onClick={handlePlayPause} />
            }

            <Controls className={showControls ? "controls_visibility" : ''}>
                <Progress
                    type="range"
                    min={0}
                    max={1}
                    step="any"
                    value={state.played}
                    onChange={handleSeekChange}
                />
                <ControlButtons>
                    <RowWidget $alignItems="center" $gap="16px">
                        <PlayerIcon src={state.playing ? PauseIcon : PlayIcon} alt="icon" onClick={handlePlayPause} />

                        <VolumeProgress 
                            type="range" 
                            min={0}
                            max={100}
                            onChange={handlerVolumeChange}
                            value={state.muted ? 0 : state.volume * 100}
                        />
                    </RowWidget>
                    
                    <RowWidget $justifyContent="center">
                        <Time>
                            {formatTime(state.duration * state.played)} / {formatTime(state.duration)}
                        </Time>
                    </RowWidget>

                    <RowWidget $alignItems="center" $gap="20px" $justifyContent="end">
                        <SpeedControls>
                            <SpeedButton onClick={() => setShowPlaybackRate(!showPlaybackRate)}>{state.playbackRate}x</SpeedButton>

                            {
                                showPlaybackRate &&
                                    <SpeedDropDown>
                                        {[1.75, 1.5, 1.25, 1, 0.75].map((item, index) => 
                                            <SpeedDropDownItem 
                                                key={index} 
                                                $isActive={item === state.playbackRate}
                                                onClick={() => {
                                                    setState({ ...state, playbackRate: item })
                                                    setShowPlaybackRate(false);
                                                }}>{item}x</SpeedDropDownItem>
                                        )}
                                    </SpeedDropDown>
                            }
                        </SpeedControls>

                        <PlayerIcon 
                            src={FullScreenIcon} 
                            alt="icon" 
                            onClick={handleFullScreen}
                        />
                    </RowWidget>
                </ControlButtons>
            </Controls>
        </PlayerWrapper>
    )
}

export default VideoPlayer;