import React from "react";

import { Trans, useTranslation } from "react-i18next";

import StarIcon from '../../assets/StarIcon.svg';

import Text from "../../shared/lib/widgets/Text";
import Container from "../../shared/lib/widgets/Container"
import RowWidget from "../../shared/lib/widgets/RowWidget";

import SecondaryButton from "../Buttons/SecondaryButton";
import PrimaryButton from "../Buttons/PrimaryButton";


interface SubscriptionCardProps {
    onClick?: () => void;
    subscriptionDetails: any;
    toggle?: boolean;
}

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({ onClick, subscriptionDetails, toggle }) => {
    const { t } = useTranslation();
    
    return (
        <Container $width="360px" $height="436px" $padding="20px" $borderRadius='12px' $backgroundColor="#E8ECF0">
            <RowWidget $height="100%" $flexDirection="column" $justifyContent="space-between">
                <RowWidget $justifyContent="space-between">
                    <Container>
                        <Text color="#FF5252" fontSize="14px" fontWeight="bold">{subscriptionDetails.title}</Text>
                        
                        <Container $height="4px" />

                        {
                            subscriptionDetails.title === 'ENTERPRISE' ?
                                <Text color="#201F29" fontWeight="400" fontSize="40px">{t('subscriptions.subscription_type.contact')}</Text>
                                :
                                <RowWidget $alignItems="center" $gap="4px">
                                    <Text color="#201F29" fontWeight="400" fontSize="40px">€{toggle ? subscriptionDetails.priceMonth : subscriptionDetails.priceYear}</Text>
                                    <Text color="#201F29" fontWeight="600" fontSize="14px">{t('subscriptions.subscription_type.per_month')}</Text>
                                </RowWidget>
                        }

                        <Container $height="8px" />

                        <RowWidget>
                            <Container $padding="2px 6px" $backgroundColor="#DCE1E8" $borderRadius="40px">
                                {/* <Text color="#153968" fontWeight="bold" fontSize="14px">{subscriptionDetails.title === 'TEAM' ? `fino a ${subscriptionDetails.user}` : subscriptionDetails.user}</Text> */}
                                <Text color="#153968" fontWeight="bold" fontSize="14px">
                                    {
                                        !isNaN(subscriptionDetails.user) ?
                                            subscriptionDetails.user > 1 ?
                                                <Trans t={t} i18nKey="subscriptions.subscription_type.up_to_users">
                                                    {subscriptionDetails.user}
                                                </Trans>
                                                :
                                                <Trans t={t} i18nKey="subscriptions.subscription_type.user">
                                                    {subscriptionDetails.user}
                                                </Trans>
                                            :
                                            <Trans t={t} i18nKey="subscriptions.subscription_type.users">
                                                {subscriptionDetails.user}
                                            </Trans>
                                    }
                                </Text>
                            </Container>
                        </RowWidget>

                        <Container $height="24px" />
                    </Container>

                    {
                        subscriptionDetails.title === 'TEAM' &&
                        <Container $height="fit-content" $borderRadius="40px" $padding="10px 14px" $backgroundColor="#FF5252">
                            <Text fontSize="14px" color="#fff">{t('subscriptions.recomended')}</Text>
                        </Container>
                    }
                </RowWidget>

                <Container>
                    {
                        (t(subscriptionDetails.items, { returnObjects: true }) as string[])
                        .map((item: string, index: any) =>
                            <RowWidget $padding="16px 0" key={index} style={{ borderBottom: `1px solid ${index < subscriptionDetails.items.length - 1 ? 'rgba(220, 225, 232, 1)' : 'transparent'}` }}>
                                <Text fontWeight={index === 0 && subscriptionDetails.containPreviousPlan? '600' : '400'} 
                                    fontSize={index === 0 && subscriptionDetails.containPreviousPlan ? '16px' : '14px'}
                                >{item}</Text>
                            </RowWidget>
                        )
                    }
                    
                    <Container $height="40px" />
                    
                    {
                        subscriptionDetails.title === 'ENTERPRISE' ?
                        <SecondaryButton 
                            onClick={onClick}
                            title={t('subscriptions.subscription_type.contact')}
                            $width='180px'
                        /> : 
                        subscriptionDetails.isPlanPurchased?
                        <PrimaryButton 
                            title='Piano Attuale'
                            $width='180px'
                            $isDisabled
                        /> :
                        <PrimaryButton 
                            onClick={onClick}
                            leading={StarIcon}
                            title={`${t('subscriptions.subscription_type.choose')} ${subscriptionDetails.title}`}
                            $width='180px'
                        />
                    }
                </Container>
            </RowWidget>
        </Container>
    );
}

export default SubscriptionCard;