import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from '../locales/en.json';
import translationIT from '../locales/it.json';

const resources = {
    en: {
        translation: translationEN
    },
    it: {
        translation: translationIT
    }
};

const defaultLanguage = localStorage.getItem('i18nextLng') || 'it';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: defaultLanguage,
        fallbackLng: 'it',
        resources,
        debug: false,
        returnNull: false,
        interpolation: {
            escapeValue: false
        },
    });

export default i18n;