import styled from "styled-components";
import { useTranslation } from "react-i18next";


const PurchasedAssistantTableStyle = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 200px 200px 120px;
    gap: 8px;
    padding: 0 16px;
    
    @media (max-width: 690px) {
        display: none;
    }
`;

const PurchasedAssistantTableItem = styled.div`
    color: #a4a3a7;
    font-weight: bold;
    font-size: 16px;
`;

const PurchasedAssistantTable = () => {
    const { t } = useTranslation();

    return (
        <PurchasedAssistantTableStyle>
            <PurchasedAssistantTableItem>{t('table.ai_assistant').toLocaleUpperCase()}</PurchasedAssistantTableItem>
            <PurchasedAssistantTableItem>{t('table.amount').toLocaleUpperCase()}</PurchasedAssistantTableItem>
            <PurchasedAssistantTableItem>{t('table.date').toLocaleUpperCase()}</PurchasedAssistantTableItem>
            <PurchasedAssistantTableItem></PurchasedAssistantTableItem>
        </PurchasedAssistantTableStyle>
    );
}

export default PurchasedAssistantTable;