import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import UserImg from '../../assets/user.svg';
// import AlertIcon from '../../assets/tooltip.svg';

import Input from "../Inputs/Input";

import Popup from "./Popup"

import UserRoleToggle from "../Toggles/UserRoleToggle";

import PrimaryButton from "../Buttons/PrimaryButton";
import SecondaryButton from "../Buttons/SecondaryButton";

import Text from "../../shared/lib/widgets/Text";
import RowWidget from "../../shared/lib/widgets/RowWidget";
import Container from "../../shared/lib/widgets/Container";

import { isEmail } from "../../shared/utils/validators";
import { Member } from "../../entities/Team/models/types/Team.types";


interface TeamMemberPopupProps {
    userData?: Member;
    onSubmit: () => void;
    onClose: () => void;
}

const TeamMemberPopup: React.FC<TeamMemberPopupProps> = ({ userData, onSubmit, onClose }) => {
    const { t } = useTranslation();
    
    const [isAdmin, setIsAdmin] = useState<boolean>(false);

    const [name, setName] = useState<string>('');
    const [surname, setSurname] = useState<string>('');
    const [email, setEmail] = useState<string>('');

    
    const [errorState, setErrorState] = useState({
        name: '',
        surname: '',
        email: '',
    });
    const validate = () => {
        const newErrors = {
            name: '',
            surname: '',
            email: '',
        };

        if (!name)
            newErrors.name = t('messages.errors.input.empty');

        if (!surname)
            newErrors.surname = t('messages.errors.input.empty');
    
        if (!isEmail(email))
            newErrors.email = t('messages.errors.input.email');

        return newErrors;
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        const validationErrors = validate();
        if (Object.values(validationErrors).some(error => error))
            return setErrorState(validationErrors);

        onSubmit();
    }
    
    return (
        <Popup title={userData ? t('settings.team.popup.edit.title') : t('settings.team.popup.invite.title')} onClose={onClose} width="600px">
            <Container>
                {
                    !userData ?
                    <>
                        <RowWidget>
                            <Input 
                                label={t('input.user_name_label')}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Mario"
                                errorMessage={errorState.name}
                            />

                            <Container $width="16px" />
                            
                            <Input 
                                label={t('input.user_surname_label')}
                                value={surname}
                                onChange={(e) => setSurname(e.target.value)}
                                placeholder="Rossi"
                                errorMessage={errorState.surname}
                            />
                        </RowWidget>

                        <Container $height="16px" />
                        
                        <Input 
                            label={t('input.user_email_label')}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="email@gmail.com"
                            errorMessage={errorState.email}
                            />
                    </> :
                    <Container $padding="16px" $borderRadius="12px" $backgroundColor="#F6F7F9">
                        <RowWidget>
                            <img src={userData.image ? userData.image : UserImg} alt="user" width="40px" height="40px" style={{ borderRadius: '50%', backgroundColor: 'rgba(222, 225, 241, 1)', objectFit: 'cover' }} />
                            
                            <Container $width="16px" />

                            <RowWidget $flexDirection="column" $justifyContent="space-between">
                                <Text fontWeight="600" $textAlign="left">{userData.first_name} {userData.last_name}</Text>
                                <Text color="rgba(126, 125, 131, 1)" fontSize="14px" fontWeight="400">{userData.email}</Text>
                            </RowWidget>
                        </RowWidget>
                    </Container>
                }

                <Container $height="16px" />

                <Text fontSize="14px" fontWeight="400" color="#46454D" $textAlign="left">Ruolo</Text>

                <Container $height="8px" />

                <UserRoleToggle onClick={setIsAdmin} toggleState={isAdmin} />
                
                {/* <Container $height="16px" /> */}
                
                {/* <RowWidget>
                    <img src={AlertIcon} alt="icon" width="16px" height="16px" />

                    <Container $width="8px" />
                    
                    <Text fontSize="14px" fontWeight="400" color="#7E7D83" textAlign="left">Non puoi selezionare il ruolo di amministratore finché non elimini l'utente amministratore precedente.</Text>
                </RowWidget> */}

                <Container $height="32px" />
                
                <RowWidget $justifyContent="space-between" $gap="16px">
                    <SecondaryButton 
                        onClick={onClose}
                        title={t('button.cancel')}
                        $width='120px'
                        $borderColor='#E9E9EA'
                    />
                    <PrimaryButton 
                        onClick={userData ? onSubmit : handleSubmit}
                        title={t('button.add_member')}
                        $width='160px'
                    />
                </RowWidget>
            </Container>
        </Popup>
    );
};

export default TeamMemberPopup;