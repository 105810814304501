import { createSlice } from "@reduxjs/toolkit";

import { TeamState } from "../types/Team.types";
import { addMember, changeAdmin, fetchTeam, removeMember } from "../services/team.services";

const initialState: TeamState = {
    team: undefined,
    isLoading: false,
}

const TeamSlice = createSlice({
    name: 'team',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTeam.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchTeam.fulfilled, (state, action) => {
                state.isLoading = false;
                state.team = action.payload;
            }
            )
            .addCase(fetchTeam.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            });
        builder
            .addCase(changeAdmin.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(changeAdmin.fulfilled, (state, action) => {
                state.isLoading = false;
                state.team!.admin = action.payload;
            }
            )
            .addCase(changeAdmin.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            });
        builder
            .addCase(addMember.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addMember.fulfilled, (state, action) => {
                state.isLoading = false;
                if (state.team?.members) {
                    state.team?.members.push(action.payload);
                }
            })
            .addCase(addMember.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            });
        builder
            .addCase(removeMember.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(removeMember.fulfilled, (state, action) => {
                state.isLoading = false;
                if (state.team?.members) {
                    state.team.members = state.team.members.filter(member => member.id !== action.payload);
                }
            })
            .addCase(removeMember.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            });
    },
});

export const { 
    reducer: teamReducer, 
    actions: teamActions,
} = TeamSlice;
