import React from "react";

import InputComponent from "./InputComponent";
import styled from "styled-components";


const InputStyle = styled.div`
    width: 100%;
    height: 40px;
    padding: 10.5px 16px;
    border-radius: 6px;
    border: 1px solid transparent;
    font-family: 'SF Pro Display', sans-serif;
    background-color: #f6f7f9;
`;

interface InputCreditCardProps {
    label?: string;
    children?: React.ReactNode
}

const InputCreditCard: React.FC<InputCreditCardProps> = (props) => {
    return (
        <InputComponent label={props.label}>
            <InputStyle>
                {props.children}
            </InputStyle>
        </InputComponent>
    );
}

export default InputCreditCard;