import CrossIcon from '../../assets/CrossIcon.svg'

import RowWidget from "../../shared/lib/widgets/RowWidget";
import Text from "../../shared/lib/widgets/Text";


interface DocumentPopupPopup {
    onClose: () => void; 
    caseName: string; 
    index: number;
}

const DocumentPopup: React.FC<DocumentPopupPopup> = ({ onClose, caseName, index }) => {
    return (
        <div style={{
            position: 'fixed',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '3',
            display: 'flex',
            justifyContent: 'end',
        }}>
            <div style={{
                width: '660px',
                height: '100%',
                backgroundColor: '#FFF',
            }}>
                <div style={{
                    height: '64px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderBottom: '1px solid #E9E9EA',
                    padding: '20px'
                }}>
                    <RowWidget $gap='8px'>
                        <Text fontSize='18px' color='#007FFF' fontWeight='bold'>[{index}]</Text>
                        <Text fontSize='18px' fontWeight='bold'>{caseName}</Text>
                    </RowWidget>
                    
                    <img 
                        src={CrossIcon} 
                        alt="icon" 
                        onClick={onClose}
                        style={{
                            width: '26px',
                            height: '26px',
                            borderRadius: '50%',
                            cursor: 'pointer',
                            opacity: '.7',
                            padding: '6px',
                        }}
                    />
                </div>

                <div style={{
                    height: '64px',
                    display: 'grid',
                    placeItems: 'center',
                }}>
                    <div>
                        <span style={{
                            fontWeight: 'bold',
                        }}>1</span>
                        <span>/2 pages</span>
                    </div>
                </div>

                <div style={{
                    backgroundColor: '#E8EBF0',
                    height: '100%',
                    width: '100%',
                    padding: '20px',
                }}>
                    <div style={{
                        backgroundColor: '#FFF',
                        width: '100%',
                        height: '80%',
                        borderRadius: '6px',
                        marginBottom: '20px'
                    }}></div>

                    <div style={{
                        backgroundColor: '#FFF',
                        width: '100%',
                        height: '100%',
                        borderRadius: '6px'
                    }}></div>
                </div>
            </div>
        </div>
    );
}


export default DocumentPopup;