import { createSlice } from "@reduxjs/toolkit";

import { CaseState } from "../types/Case.types";
import { addCollaborator, editCase, fetchCaseById, updateCollaborator } from "../services/case.services";

const initialState: CaseState = {
    isLoading: false,
    error: '',
};

const CaseSlice = createSlice({
    name: 'case',
    initialState: initialState,
    reducers: {
        updateCase: (state, action) => {
            state.case!.share = action.payload.share;
        }
    },
    extraReducers: (builder) => {
        // // Fetch Case
        builder
            .addCase(fetchCaseById.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchCaseById.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            })
            .addCase(fetchCaseById.fulfilled, (state, action) => {
                state.isLoading = false;
                state.case = action.payload;
            });
        // Edit Cases
        builder
            .addCase(editCase.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(editCase.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            })
            .addCase(editCase.fulfilled, (state, action) => {
                state.isLoading = false;
                state.case = action.payload;
            });
        // Add Collaborator to Cases
        builder
            .addCase(addCollaborator.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addCollaborator.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            })
            .addCase(addCollaborator.fulfilled, (state, action) => {
                state.isLoading = false;
                if (state.case) {
                    if (!state.case.collaborators) {
                        state.case.collaborators = [];
                    }
                    state.case.collaborators.push(action.payload);
                } else {
                    console.error("Case is not initialized");
                }
            });
        // Update Collaborator to Cases
        builder
            .addCase(updateCollaborator.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateCollaborator.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            })
            .addCase(updateCollaborator.fulfilled, (state, action) => {
                state.isLoading = false;
                const index: any = state.case?.collaborators.findIndex(collab => collab.id === action.payload.id);
                if (index !== -1 && state.case?.collaborators) {
                    const updatedCollaborator = {
                        ...state.case.collaborators[index],
                        ...action.payload
                    };
                    state.case.collaborators[index] = updatedCollaborator;
                }
            });
    },
});


export const { 
    reducer: caseReducer, 
    actions: caseActions,
} = CaseSlice;
