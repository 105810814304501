import React from "react";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckIcon from '../../assets/RightMarkIcon.svg';

import Text from "../../shared/lib/widgets/Text";
import RowWidget from "../../shared/lib/widgets/RowWidget";

import Popup from "./Popup"

import Container from "../../shared/lib/widgets/Container";

import { Agent } from "../../entities/Agents/models/types/Agent.types";

import SubscriptionPaymentForm from "../Forms/SubsciptionPaymentForm";


interface PurchaseAgentPopupProps {
    onSubmit: () => void;
    onClose: () => void;
    agentInfo: Agent
}

const PurchaseAgentPopup: React.FC<PurchaseAgentPopupProps> = ({ onSubmit, onClose, agentInfo }) => {
    const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_SECRET}`);

    return (
        <Popup title="Acquisto dell'Assistente AI" onClose={onClose} width="1024px" >
            <RowWidget $flexWrap="wrap">
                <Container $width="350px">
                    <RowWidget $alignItems="center">
                        <img src={agentInfo.profileImage} alt="agent" style={{ width: '60px', height: '60px', borderRadius: '50%', objectFit: 'cover', marginRight: '12px' }} />
                        <Text fontWeight="bold" fontSize="18px">{agentInfo.name}</Text>
                    </RowWidget>

                    <Container $height="24px" />

                    <RowWidget $gap="8px" $flexWrap="wrap">
                        {
                            agentInfo.skills.map((item, index) =>
                                <Container key={index} $padding="6px 12px" $backgroundColor="#F6F7F9" $borderRadius="40px">
                                    <Text color="#201F29" style={{ whiteSpace: 'nowrap' }}>{item.name}</Text>
                                </Container>
                            )
                        }
                    </RowWidget>
                </Container>

                <Container style={{ minWidth: '64px' }} />

                <RowWidget $flex="1" $flexDirection="column">
                    <RowWidget $justifyContent="space-between" $alignItems="center">
                        <Text fontSize="14px" color="#201F29">Plan</Text>

                        <RowWidget $alignItems="center">
                            <Text fontSize="13px" color="#7E7D83">Valuta</Text>
                            <Container $width="8px" />
                            <Container $backgroundColor="#F6F7F9" $padding="4px 8px" $borderRadius="10px">
                                <Text color="#201F29" fontSize="14px">EUR</Text>
                            </Container>
                        </RowWidget>
                    </RowWidget>

                    <Container $height="8px" />

                    <Container $cursor="pointer" $width="100%" $backgroundColor="#FFF" $padding="16px" $border="1px solid #4158E3" $borderRadius="8px">
                        <RowWidget $justifyContent="space-between" $alignItems="center">
                            <Text fontSize="14px" fontWeight="400">Paga mensilmente</Text>

                            <img src={CheckIcon} alt="icon" width='15px' height='15px' />
                        </RowWidget>

                        <Container $height='16px' />

                        <RowWidget $justifyContent="space-between" $alignItems="center">
                            <Text fontSize="14px" fontWeight="bold">€{agentInfo.price}/mese</Text>
                        </RowWidget>
                    </Container>

                    <Container $height="32px" />

                    {
                        stripePromise && 
                        <Elements stripe={stripePromise}>
                            <SubscriptionPaymentForm 
                                onSubmit={onSubmit}
                                onClose={onClose}
                                price={`€${agentInfo.price}`}
                            />
                        </Elements>
                    }
                </RowWidget>
            </RowWidget>
        </Popup>
    );
};

export default PurchaseAgentPopup;