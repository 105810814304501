import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import i18n from 'i18next';


export const fetchTeam = createAsyncThunk(
    'team/fetchTeam',
    async (id: string, thunkApi: any) => {
        const { extra, rejectWithValue } = thunkApi;

        try {
            if (!id)
                return

            const { data: teamData } = await extra.api.get(`/teams/${id}/`);

            const { data: adminResponseData } = await extra.api.get(`/users/${teamData.admin.id}`);
            const adminData = {
                id: adminResponseData.id,
                image: adminResponseData.image,
                first_name: adminResponseData.first_name,
                last_name: adminResponseData.last_name,
                email: adminResponseData.email,
                dateAdded: teamData.admin.dateAdded,
                lastActive: teamData.admin.lastActive,
            };

            const memberDataPromises = teamData.members.map(async (member: any) => {
                const { data: memberApiResponse } = await extra.api.get(`/users/${member.id}/`);
                return {
                    id: memberApiResponse.id,
                    image: memberApiResponse.image,
                    first_name: memberApiResponse.first_name,
                    last_name: memberApiResponse.last_name,
                    email: memberApiResponse.email,
                    dateAdded: member.dateAdded,
                    lastActive: member.lastActive,
                };
            });            
            const membersData = await Promise.all(memberDataPromises);

            const result = {
                ...teamData,
                admin: adminData,
                members: membersData,
            };

            return result;
        } catch (error: any) {
            toast.error(i18n.t("messages.errors.team.not_found"));
            return rejectWithValue('Team fetch error!');
        }
    }
);

export const changeAdmin = createAsyncThunk(
    'team/changeAdmin',
    async ({id, email}: any, thunkApi: any) => {
        const { extra, rejectWithValue } = thunkApi;

        try {
            const response = await extra.api.patch(`/team/change-admin/${id}/`, {email});
            return response.data;
        } catch (error) {
            toast.error(i18n.t("messages.errors.team.update"));
            return rejectWithValue('Team edit error!');
        }
    }
);

export const addMember = createAsyncThunk(
    'team/addMember',
    async ({id, email}: any, thunkApi: any) => {
        const { extra, rejectWithValue } = thunkApi;

        try {
            const response = await extra.api.post(`/team/add/${id}/`, {email});
            return response.data;
        } catch (error) {
            toast.error(i18n.t("messages.errors.team.add_member"))
            return rejectWithValue('Team add member error!');
        }
    }
);

export const removeMember = createAsyncThunk(
    'team/removeMember',
    async ({id, memberId}: any, thunkApi: any) => {
        const { extra, rejectWithValue } = thunkApi;

        try {
            await extra.api.delete(`/team/remove/${id}/${memberId}/`);
            return memberId;
        } catch (error) {
            toast.error(i18n.t("messages.errors.team.remove_member"));
            return rejectWithValue('Team remove member error!');
        }
    }
);
