import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import SubHeader from '../../SubHeader/SubHeader';

import DropDown from '../../DropDown/DropDown';

import TutorialCard from '../../Cards/TutorialCard';

import Text from '../../../shared/lib/widgets/Text';
import RowWidget from '../../../shared/lib/widgets/RowWidget';
import Container from '../../../shared/lib/widgets/Container';


const Practical: React.FC = () => {
    const { t } = useTranslation();
    
    const [practicalExamples, setPracticalExamples] = useState([]);
    
    const fetchPracticalExamples = () => {
        setPracticalExamples([]);
    }

    useEffect(() => {
        fetchPracticalExamples();
    }, [])

    const [companySize, setCompanySize] = useState('');
    const [useCase, setUseCase] = useState("");

    const companySizeItems = [t('dropdown.company_size.small'), t('dropdown.company_size.medium'), t('dropdown.company_size.big')];
    const useCaseItems = ['Lorem ipsum', 'Lorem ipsum', 'Lorem ipsum'];

    const [filterOptions, setFilterOptions] = useState<any | null>(null);
    
    const handleCompanySizeChange = (value: string) => {
        setCompanySize(value);
        setFilterOptions({ type: 'companySize', value });
    }
    const handleUseCaseChange = (value: string) => {
        setUseCase(value);
        setFilterOptions({ type: 'useCase', value });
    }

    const getFilteredArticles = useCallback((articles: any, filter: any | null) => {
        if (!filter) return articles;

        const filteredData = [...articles];

        // if (filter.type === 'companySize')
            // Filter for company size type

        // if (filter.type === 'useCase')
            // Filter for useCase type

        return filteredData;
    }, []);

    const filteredPracticalExamples = useMemo(() => getFilteredArticles(practicalExamples, filterOptions), [practicalExamples, filterOptions, getFilteredArticles]);

    return (
        <Container>
            <SubHeader title={t('academy.title')} to='/academy' border='1px solid #E4E4E4' />

            <Container $height='40px' />
            
            <Text fontSize='32px' fontWeight='bold' $textAlign='center'>{t('academy.practical_examples.title')}</Text>

            <Container $height='10px' />

            <Text color='#908F94' fontWeight='400' $textAlign='center'>{t('academy.practical_examples.sub_description')}</Text>
            
            <Container $height='24px' />

            <RowWidget $justifyContent='center' $gap='16px' $flexWrap='wrap'>
                <DropDown 
                    width='250px'
                    dropDownName={t('dropdown.company_size.title')}
                    value={companySize}
                    onChange={handleCompanySizeChange}
                    menuItems={companySizeItems}
                />

                <DropDown 
                    width='250px'
                    dropDownName={t('dropdown.use_case.title')}
                    value={useCase}
                    onChange={handleUseCaseChange}
                    menuItems={useCaseItems}
                />
            </RowWidget>
            
            <Container $height='32px' />

            <RowWidget $justifyContent='center' $gap='16px' $flexWrap='wrap'>
                {
                    filteredPracticalExamples.length === 0 ?
                        <Container $borderRadius='16px' $border='1px solid grey' $width='100%' $margin='0 16px'>
                            <RowWidget $justifyContent='center' $alignItems='center' $height='80px'>
                                <Text fontSize='18px' color='grey'>{t('empty_list.record')}</Text>
                            </RowWidget>
                        </Container>
                        :
                        filteredPracticalExamples.map((item: any, index: number) => 
                            <TutorialCard
                                key={index}
                                link={item.link}
                                src={item.image}
                                title={item.name}
                                description={item.description}
                            />
                        )
                }
            </RowWidget>

            <Container $height='32px' />
        </Container >
    );
};

export default Practical;

