import React from "react";

import styled from "styled-components";


const TabBarButtonsStyle = styled.div`
    user-select: none;
    width: 100%;
    padding: 2px;
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    background-color: #F6F7F9;
    border-radius: 8px;
`;

const TabBarButtonStyle = styled.div<{ $isActive?: boolean }>`
    flex: 1 1 200px;
    padding: 12px 0;
    text-align: center;
    background-color: ${(props) => (props.$isActive ? '#FFF' : 'transparent')};
    color: ${(props) => (props.$isActive ? '#000' : '#7E7D83')};
    cursor: pointer;
    border-radius: 8px;
    font-size: 16px;
    &:hover {
        background-color: #F2F4F6;
    }
`;

interface TabBarButtonsProps {
    onClick: (e: any) => void;
    value: number;
    items: String[];
}

const TabBarButtons: React.FC<TabBarButtonsProps> = ({ onClick, value, items }) => {
    return (
        <TabBarButtonsStyle>
            {
                items.map((item, index) =>
                    <TabBarButtonStyle key={index} onClick={() => onClick(index)} $isActive={value === index}>{item}</TabBarButtonStyle>
                )
            }
        </TabBarButtonsStyle>
    );
};

export default TabBarButtons;
