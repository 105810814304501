import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import i18n from 'i18next';


export const fetchUserById = createAsyncThunk(
    'user/fetchUserById',
    async (id: string, thunkApi: any) => {
        const { extra, rejectWithValue } = thunkApi;

        try {
            const response = await extra.api.get(`/users/${id}/`);

            return response.data;
        } catch (error: any) {
            if (error.code === 'ERR_NETWORK' || error.code[0] === 5)
                return toast.error(i18n.t('messages.errors.server_answer'));

            toast.error(i18n.t("messages.errors.user.not_found"));
            return rejectWithValue('User fetch failed!');
        }
    }
);

export const userPurchaseAgent = createAsyncThunk(
    'user/purchaseAgent',
    async (id: string, thunkApi: any) => {
        const { extra, rejectWithValue } = thunkApi;

        try {
            const response = await extra.api.get(`/agent-purchase/${id}/`);
            return response.data;
        } catch (error) {
            toast.error(i18n.t("messages.errors.agent.not_found"));
            return rejectWithValue('User agents fetch error!');
        }
    }
);

export const updateUserData = createAsyncThunk(
    'user/updateUserData',
    async (userData: any, thunkApi: any) => {
        const { extra, rejectWithValue } = thunkApi;

        try {
            const response = await extra.api.patch(`/users/${userData.id}/`, userData);
            return response.data;
        } catch (error) {
            toast.error(i18n.t("messages.errors.user.update"));
            return rejectWithValue('User patch error!');
        }
    }
);
