import { FC, PropsWithChildren, useEffect } from "react";

import { useStore } from "react-redux";

import { ReduxStoreWithManager, StateSchemaKey } from "../../../app/providers/StoreProvider/config/StateSchema";

import { useAppDispatch } from "../useAppDispatch/useAppDispatch";

import { DynamicReducerLoaderProps } from "./DynamicReducerLoader.types";


export const DynamicReducerLoader: FC<PropsWithChildren<DynamicReducerLoaderProps>> = (props) => {
    const { children, reducers, removeAfterUnmount = false } = props;

    const store = useStore() as ReduxStoreWithManager;
    const dispatch = useAppDispatch();
    
    useEffect(() => {
        Object.entries(reducers).forEach(([name, reducer]) => {
            if (!store.getState()[name as StateSchemaKey]) {
                store.reducerManager.add(name as StateSchemaKey, reducer);
                dispatch({ type: `@INIT ${name} reducer` });
            }
        });

        return () => {
            if (removeAfterUnmount) {
                Object.entries(reducers).forEach(([name]) => {
                    store.reducerManager.remove(name as StateSchemaKey);
                    dispatch({ type: `@DESTROY ${name} reducer` });
                });
            }
        }
    }, [dispatch, reducers, removeAfterUnmount, store]);

    return <>{children}</>;
}