import React from "react";

import { useTranslation } from "react-i18next";

import CheckIcon from '../../assets/RightMarkIcon.svg';

import Text from "../../shared/lib/widgets/Text";
import Container from "../../shared/lib/widgets/Container";
import RowWidget from "../../shared/lib/widgets/RowWidget";


interface PlanToggleProps {
    onClick: (toggleState: boolean) => void;
    toggleState: boolean;
    priceMonth: string;
    priceYeah: string
}

const PlanToggle: React.FC<PlanToggleProps> = ({ onClick, toggleState, priceMonth, priceYeah }) => {
    const { t } = useTranslation();
    
    return (
        <RowWidget>
            <Container onClick={() => onClick(false)} $cursor="pointer" $width="100%" $backgroundColor={!toggleState ? "#fff" : "#F6F7F9"} $padding="16px" $border={`1px solid ${!toggleState ? "#4158E3" : "transparent"}`} $borderRadius="8px">
                <RowWidget $justifyContent="space-between" $alignItems="center" $margin="0 0 16px 0">
                    <Text fontSize="14px" fontWeight="400">{t('toggle.pay_monthly')}</Text>

                    {
                        !toggleState &&
                        <img src={CheckIcon} alt="icon" width='15px' height='15px' />
                    }
                </RowWidget>

                <RowWidget $justifyContent="space-between" $alignItems="center">
                    <Text fontSize="14px" fontWeight="bold">€{priceMonth}/{t('subscriptions.month')}</Text>
                </RowWidget>
            </Container>

            <Container $width='32px' />

            <Container onClick={() => onClick(true)} $cursor="pointer" $width="100%" $backgroundColor={toggleState ? "#fff" : "#F6F7F9"} $padding="16px" $border={`1px solid ${toggleState ? "#4158E3" : "transparent"}`} $borderRadius="8px">
                <RowWidget $justifyContent="space-between" $alignItems="center" $margin="0 0 16px 0">
                    <Text fontSize="14px" fontWeight="400">{t('toggle.pay_yearly')}</Text>

                    {
                        toggleState &&
                        <img src={CheckIcon} alt="icon" width='15px' height='15px' />
                    }
                </RowWidget>

                <RowWidget $justifyContent="space-between" $alignItems="center">
                    <Text fontSize="14px" fontWeight="bold">€{priceYeah}/{t('subscriptions.month')}</Text>

                    <Container $backgroundColor="#EDF8E8" $padding="4px 10px" $borderRadius="6px" $cursor="pointer">
                        <Text color="#306F11" fontWeight="400" fontSize="13px">SAVE 20%</Text>
                    </Container>
                </RowWidget>
            </Container>
        </RowWidget>
    );
}

export default PlanToggle;