import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import i18n from 'i18next';

export const fetchAgents = createAsyncThunk(
    'agent/fetchAgents',
    async (_, thunkApi: any) => {
        const { extra, rejectWithValue } = thunkApi;

        try {
            const response = await extra.api.get(`/agents/`)
            return response.data;
        } catch (error) {
            toast.error(i18n.t("messages.errors.agent.not_found"));
            return rejectWithValue('Agents fetch error!');
        }
    }
);

export const fetchAgentById = createAsyncThunk(
    'agent/fetchAgentById',
    async (id: string, thunkApi: any) => {
        const { extra, rejectWithValue } = thunkApi;

        try {
            const response = await extra.api.get(`/agents/${id}/`)
            return response.data;
        } catch (error) {
            toast.error(i18n.t("messages.errors.agent.search"));
            return rejectWithValue('Agents fetch error!');
        }
    }
);

export const fetchUserAgents = createAsyncThunk(
    'agent/fetchUserAgents',
    async (_, thunkApi: any) => {
        const { extra, rejectWithValue } = thunkApi;

        try {
            const response = await extra.api.get('/agents-user/');

            return response.data;
        } catch (error) {
            toast.error(i18n.t("messages.errors.agent.not_found"));
            return rejectWithValue('User agents fetch error!');
        }
    }
);
