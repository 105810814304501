import React from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";


const BillingTableStyle = styled.div`
    width: 100%;
    background-color: #F6F7F9;
    border-radius: 12px;
    border: 1px solid #E9E9EA;
    min-width: 600px;
    @media (max-width: 666px) {
        min-width: auto;
    }
`;

const BillingTableHeaderStyle = styled.div`
    display: grid;
    grid-template-columns: 1fr minmax(50px, 140px) minmax(50px, 140px) minmax(50px, 140px) minmax(50px, 140px) minmax(50px, 150px);
    gap: 8px;
    padding: 10px 12px;
    border-bottom: 1px solid #E9E9EA;
    @media (max-width: 666px) {
        display: block;
        width: 100%;
    }
`;

const BillingTableCellStyle = styled.div`
    color: #7E7D83;
    font-size: 14px;
    font-weight: bold;
`;

const TableContentStyle = styled.div`
    border-radius: 0 0 12px 12px;
    overflow: hidden;
`;

interface BillingTableProps {
    children: React.ReactNode;
}

const BillingTable: React.FC<BillingTableProps> = ({ children }) => {
    const { t } = useTranslation();
    
    return (
        <BillingTableStyle>
            <BillingTableHeaderStyle>
                <BillingTableCellStyle>{t('table.invoice')}</BillingTableCellStyle>
                <BillingTableCellStyle>{t('table.billing_date')}</BillingTableCellStyle>
                <BillingTableCellStyle>{t('table.status')}</BillingTableCellStyle>
                <BillingTableCellStyle>{t('table.amount')}</BillingTableCellStyle>
                <BillingTableCellStyle>{t('table.item')}</BillingTableCellStyle>
                <BillingTableCellStyle></BillingTableCellStyle>
            </BillingTableHeaderStyle>
            
            <TableContentStyle>
                {children}
            </TableContentStyle>
        </BillingTableStyle>
    );
}

export default BillingTable;