import React, { useState } from 'react';

import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import PlusIcon from '../../../assets/PlusIconBlue.svg';
import NoTeamImg from '../../../assets/no_team_img.png';

import SecondaryButton from '../../Buttons/SecondaryButton';

import { StateSchema } from '../../../app/providers/StoreProvider/config/StateSchema';

import { teamReducer } from '../../../entities/Team/models/slices/TeamSlice';

import { useAppSelector } from '../../../shared/components/useAppSelector/useAppSelector';
import { ReducersList } from '../../../shared/components/DynamicModuleLoader/DynamicReducerLoader.types';
import { DynamicReducerLoader } from '../../../shared/components/DynamicModuleLoader/DynamicReducerLoader';

import TeamMemberPopup from '../../Popups/TeamMemberPopup';

import Text from '../../../shared/lib/widgets/Text';
import RowWidget from '../../../shared/lib/widgets/RowWidget';
import TeamManagement from '../../TablesData/TeamManagement';


const reducers: ReducersList = {
    team: teamReducer,
}

const Teams: React.FC = () => {
    const { t } = useTranslation();

    const user = useAppSelector((state: StateSchema) => state.user);

    const [addNewMember, setAddNewMember] = useState(false);

    return (
        <DynamicReducerLoader reducers={reducers}>
            {
                user.userData?.team_id?
                    <TeamManagement /> 
                    :
                    <RowWidget $justifyContent='center' $alignItems='center' $flexDirection='column' $height='55vh'>
                        {
                            addNewMember && 
                                <TeamMemberPopup 
                                    onSubmit={() => toast.info(t('messages.info.service_not_available'))} 
                                    onClose={() => setAddNewMember(false)} 
                                />
                        }
                        
                        <img
                            style={{
                                width: '300px',
                                marginBottom: '16px',
                            }}
                            src={NoTeamImg}
                            alt="no team"
                        />

                        <Text fontSize='18px' fontWeight='bold'>{t('settings.team.without_team.title')}</Text>

                        <Text fontSize='16px' color='grey' margin='12px 0 32px 0'>{t('settings.team.without_team.subtitle')}</Text>

                        <SecondaryButton
                            onClick={() => user.userData?.subscription_id && user.userData?.subscription_id.id === '2' ? setAddNewMember(true) : toast.info(t('messages.info.subscribe_to_team'))}
                            leading={PlusIcon}
                            title={t('button.add_team_member')}
                            $width='200px'
                        />
                    </RowWidget>
            }
        </DynamicReducerLoader>
    )
};

export default Teams;
