import { useState } from "react";

import styled from "styled-components";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";
import { AuthProvider, FacebookAuthProvider, GoogleAuthProvider, sendEmailVerification, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
//@ts-ignore
import ReactStars from "react-rating-stars-component";

import GoogleIcon from "../../assets/google.svg";
import FacebookIcon from "../../assets/facebook.svg";

import { auth } from "../../configs/firebase-config";

import { fetchUserById } from "../../entities/User/models/services/user.services";

import { $api } from "../../shared/api/api";
import { errorHandler } from "../../shared/utils/errorHandler";
import { USER_LOCALSTORAGE_KEY } from "../../shared/consts/localStorage";
import { useAppDispatch } from "../../shared/components/useAppDispatch/useAppDispatch";


import Input from "../../components/Inputs/Input";
import TextButton from "../../components/Buttons/TextButton";
import PrimaryButton from "../../components/Buttons/PrimaryButton";

import Text from "../../shared/lib/widgets/Text";
import Container from "../../shared/lib/widgets/Container";
import RowWidget from "../../shared/lib/widgets/RowWidget";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import { isEmail } from "../../shared/utils/validators";
import InputPassword from "../../components/Inputs/InputPassword";

const SignInContainer = styled.div`
    display: flex;
    gap: 200px;
    width: 100%;
    height: 90vh;
    justify-content: center;
    align-items: center;
    padding: 64px 32px 32px 32px;
    @media (max-width: 1024px) {
        display: block;
        .invisible {
            display: none;
        }
        .visible {
            width: 500px;
            margin: 0 auto;
            align-items: center;
        }
    }
    @media (max-width: 568px) {
        .visible {
            width: 100%;
        }
    }
`;

const SignIn = () => {
    const { t } = useTranslation();
    
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const [errorState, setErrorState] = useState({
        email: '',
        password: '',
    });

    const isAuthentificated = localStorage.getItem(USER_LOCALSTORAGE_KEY);
    if (isAuthentificated) {
        return <Navigate to='/' />
    }
    
    const validate = () => {
        const newErrors = {
            email: '',
            password: '',
        };

        if (!isEmail(email))
            newErrors.email = t('messages.errors.input.email');
        
        if (password.length < 8)
            newErrors.password = t('messages.errors.input.password');

        return newErrors;
    };

    const loginHandler = async (e: React.FormEvent) => {
        e.preventDefault();
        
        const validationErrors = validate();
        if (Object.values(validationErrors).some(error => error))
            return setErrorState(validationErrors);

        if (!isLoading)
            try {
                setIsLoading(true)
                var user: any;

                try {
                    user = await $api.post('/users-email/', { email: email.trim().toLowerCase() })
                } catch (e: any) {
                    if (e.code === 500)
                        return toast.error(t('messages.errors.server_answer'));
                    return toast.error(t('messages.errors.user.not_found_email'));
                }

                await signInWithEmailAndPassword(auth, email, password)

                if (!auth.currentUser?.emailVerified) {
                    await sendEmailVerification(auth.currentUser!)
                        .then(() => toast.info(t('messages.info.check_email')))
                        .catch(() => toast.error(t('messages.errors.email_send_error')));
                    navigate('/email-verification');
                    return;
                }

                dispatch(fetchUserById(user.data.id));
                localStorage.setItem(USER_LOCALSTORAGE_KEY, user.data.id);
                navigate('/');
            } catch (error: any) {
                toast.error(errorHandler(error.code));
            } finally {
                setIsLoading(false)
            }
    };

    const providerHandler = async (provider: AuthProvider) => {
        return await signInWithPopup(auth, provider)
        .then(async (result) => {
            const user = result.user;
            
            var userServer: any;
            try {
                userServer = await $api.post('/users-email/', { email: user.email })
            } catch (e) {
                await auth.currentUser!.delete();
                return toast.info(t('messages.errors.user.not_found_email'));
            }

            dispatch(fetchUserById(userServer.data.id));
            localStorage.setItem(USER_LOCALSTORAGE_KEY, userServer.data.id);
            navigate('/');
        })
        .catch((e) => {
            console.log(e)
            toast.error(errorHandler(e.code));
        });
    }
    
    const signInGoogleHandler = () => {
        const provider = new GoogleAuthProvider();
        providerHandler(provider);
    }

    const signInFacebookHandler = () => {
        const provider = new FacebookAuthProvider();
        providerHandler(provider);

    }

    return (
        <SignInContainer>
            <RowWidget $width="550px" $height="100%" $flexDirection="column" $justifyContent="space-between" className="visible">
                <RowWidget>
                    <img style={{ height: '25px', marginRight: '8px', }} src="images/img_group.svg" alt="logo" />
                    <img style={{ height: '32px' }} src="images/img_group_light_blue_a700.svg" alt="logo" />
                </RowWidget>

                <Container $width="360px" $margin="0 0 0 auto" className="visible">
                    <Text fontSize="32px" fontWeight="600">{t('auth.sign_in.title')}</Text>

                    <Container $height="8px" />
                    
                    <Text color="#908F94">{t('auth.sign_in.description')}</Text>

                    <Container $height="40px" />
                    
                    <Input 
                        label={t('input.user_email_label')}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="email@gmail.com"
                        errorMessage={errorState.email}
                    />

                    <Container $height="16px" />
                    
                    <RowWidget $justifyContent="space-between">
                        <Text fontSize="14px" color="#46454d">Password</Text>
                        
                        <TextButton 
                            onClick={() => navigate('/forgot-password')}
                            title={t('button.forgot_password')}
                            color="#007FFF" 
                            fontSize="14px"
                        />
                    </RowWidget>
                    
                    <Container $height="6px" />

                    <InputPassword 
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder={t('input.password_placeholder')}
                        errorMessage={errorState.password}
                    />

                    <Container $height="32px" />

                    <PrimaryButton 
                        title={isLoading ? "Loading..." : t('auth.sign_in.title')}
                        onClick={loginHandler}
                        $width="100%"
                    />

                    <Container $height="16px" />
                    
                    <RowWidget $gap="12px">
                        <SecondaryButton
                            onClick={signInGoogleHandler}
                            leading={GoogleIcon}
                            title={t('auth.sign_in.sign_with_google')}
                            $width="100%"
                            $borderColor="#E9E9EA"
                        />
                        <SecondaryButton
                            onClick={signInFacebookHandler}
                            leading={FacebookIcon}
                            $width="fit-content"
                            $borderColor="#E9E9EA"
                        />
                    </RowWidget>

                    <Container $height="20px" />

                    <RowWidget $justifyContent="center">
                        <Text margin="0 4px" fontSize="14px" color="#46454D">{t('auth.sign_in.no_account')}</Text>
                        <TextButton 
                            onClick={() => navigate('/sign-up')}
                            title={t('auth.sign_up.title')}
                            fontWeight="bold" 
                            fontSize="14px" 
                            color="#007FFF" 
                        />
                    </RowWidget>
                </Container>

                <Text color="#7E7D83" fontSize="14px">Copyright © 2024 LegalAssists</Text>
            </RowWidget>

            <Container $width="600px" $height="100%" $padding="40px" $backgroundColor="#96CBFF" $borderRadius="24px" className="invisible">
                <Container $backgroundColor="#fff" $borderRadius="20px" $height="100%">
                    <RowWidget $height="100%" $justifyContent="space-between" $flexDirection="column">
                        <Container $padding="45px 45px 0 45px">
                            <RowWidget $alignItems="center">
                                <ReactStars
                                    edit={false}
                                    count={5}
                                    isHalf={false}
                                    color='#FFA552'
                                    activeColor='#FFA552'
                                    key={5}
                                />
                                <Text color="#7E7D83" margin="0 0 0 8px">12,000+ {t('auth.sign_in.info.users')}</Text>
                            </RowWidget>
                            
                            <Text margin="50px 10% 0 0" fontWeight="400" fontSize="24px" style={{ lineHeight: '30px' }}>{t('auth.sign_in.info.description')}</Text>
                        </Container>
                        
                        <RowWidget $alignItems="end" $justifyContent="space-between" $padding="0 8px 0 45px">
                            <Container>
                                <Text fontSize="16px" fontWeight="bold">Andrew Danson</Text>
                                <Container $height="2px" />
                                <Text fontSize="14px">{t('auth.sign_in.info.head_of')}</Text>
                                <Container $height="32px" />
                            </Container>

                            <img src="images/img_128975_1.png" alt="img" style={{ objectFit: 'cover', height: '200px' }} />
                        </RowWidget>
                    </RowWidget>
                </Container>
            </Container>
        </SignInContainer>
    );
}

export default SignIn;