import axios, { AxiosInstance } from 'axios';
import { USER_LOCALSTORAGE_KEY } from '../consts/localStorage';

const __API__ = process.env.REACT_APP_API_URL;


export const $api: AxiosInstance = axios.create({
    baseURL: __API__,
});

$api.interceptors.request.use(async (config) => {
    const token = localStorage.getItem(USER_LOCALSTORAGE_KEY);
    
    if (config.headers) {
        config.headers['Authorization'] = token || '';
    }
    
    return config;
});
