import { useState } from "react";

import { useTranslation } from "react-i18next";

import ContactPopup from "./ContactPopup";

import Input from "../Inputs/Input";
import InputTextArea from "../Inputs/InputTextArea";

import PrimaryButton from "../Buttons/PrimaryButton";

import Text from "../../shared/lib/widgets/Text";
import RowWidget from "../../shared/lib/widgets/RowWidget";
import Container from "../../shared/lib/widgets/Container";
import InputPhone from "../Inputs/InputPhone";


interface SalesContactPopupProps {
    onSubmit: (e: any) => void;
    onClose: () => void;
}

const SalesContactPopup: React.FC<SalesContactPopupProps> = ({ onSubmit, onClose }) => {
    const { t } = useTranslation();
    
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        organization: '',
        webAddress: '',
        message: '',
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onSubmit(e);
    };

    return (
        <ContactPopup title={t('upgrade_plan.popup.contact.title')} onClose={onClose}>
            <Container>
                <Text fontSize="16px" fontWeight="400" color="#908F94" margin="0 0 32px 0">{t('upgrade_plan.popup.contact.subtitle')}</Text>

                <RowWidget $gap="16px">
                    <Input 
                        name='first_name'
                        label={t('input.user_name_label')}
                        value={formData.first_name} 
                        onChange={handleInputChange} 
                        placeholder='Mario' 
                    />

                    <Input 
                        name="last_name"
                        label={t('input.user_surname_label')}
                        value={formData.last_name} 
                        onChange={handleInputChange} 
                        placeholder='Rossi' 
                    />
                </RowWidget>

                <Container $height="16px" />

                <RowWidget $gap="16px">
                    <InputPhone 
                        value={formData.phone} 
                        onChange={(e) => setFormData((prevData: any) => ({...prevData, 'phone': e}))}
                        placeholder='123456789' 
                    />

                    <Input 
                        name="email"
                        label={t('input.user_email_label')}
                        value={formData.email} 
                        onChange={handleInputChange} 
                        placeholder='email@gmail.com' 
                    />
                </RowWidget>

                <Container $height="16px" />

                <Input 
                    name='organization'
                    label={t('input.organization_label')}
                    value={formData.organization} 
                    onChange={handleInputChange} 
                    placeholder={t('input.organization_placeholder')}
                />

                <Container $height="16px" />

                <Input 
                    name='webAddress'
                    label={t('input.web_label')}
                    value={formData.webAddress} 
                    onChange={handleInputChange} 
                    placeholder='https://example.com' 
                />

                <Container $height="16px" />

                <InputTextArea 
                    name="message"
                    label={t('input.message_label')}
                    value={formData.message} 
                    onChange={handleInputChange} 
                    placeholder={t('input.message_label')}
                    rows={5}
                />

                <Container $height="32px" />

                <PrimaryButton 
                    onClick={handleSubmit}
                    title={t('button.send_message')}
                    $width='180px'
                />
            </Container>
        </ContactPopup>
    );
};

export default SalesContactPopup;