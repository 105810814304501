import React from "react";

import { useTranslation } from 'react-i18next';

import BillingUserDataCard from "../../Cards/BillingUserDataCard";

import BillingTable from "../../Tables/BillingTable";

import BillingData from "../../TablesData/BillingData";

import Text from "../../../shared/lib/widgets/Text";
import RowWidget from "../../../shared/lib/widgets/RowWidget";
import Container from "../../../shared/lib/widgets/Container";


const BillingAndPayments: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Container>
            <Text fontSize="18px" fontWeight="bold">{t('settings.billing_and_payments.section.info')}</Text>

            <Container $height="12px" />

            <BillingUserDataCard />
            
            <Container $height="40px" />

            <RowWidget $justifyContent="space-between" $alignItems="center">
                <Text fontSize="18px" fontWeight="bold">{t('settings.billing_and_payments.section.billing')}</Text>

                {/* <PrimaryButton 
                    borderColor="rgba(21, 57, 104, 1)"
                    color="rgba(21, 57, 104, 1)"
                    width="160px"
                    onClick={() => {}}
                    leading={DownloadIcon}
                    title="Scarica Tutto"
                    isBackgroundVisible
                    
                    isBold
                /> */}
            </RowWidget>

            <Container $height="12px" />
            
            <BillingTable>
                <BillingData />
            </BillingTable>
        </Container >
    )
}
export default BillingAndPayments;
