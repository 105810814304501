import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { StoreProvider } from './app/providers/StoreProvider/ui/StoreProvider';

import reportWebVitals from './reportWebVitals';

import './configs/firebase-config';
import './index.css';

import './shared/config/i18n';


const container = document.getElementById('root') as HTMLElement
if (!container) {
  throw new Error('Container not found. React app will not be provided');
}

const root = createRoot(container);
root.render(
  <BrowserRouter>
    <StoreProvider>
      <App />
    </StoreProvider>
  </BrowserRouter>
);

reportWebVitals();
