import { useState } from "react";

import styled from "styled-components";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";
import { AuthProvider, createUserWithEmailAndPassword, FacebookAuthProvider, fetchSignInMethodsForEmail, GoogleAuthProvider, sendEmailVerification, signInWithPopup } from "firebase/auth";
//@ts-ignore
import ReactStars from "react-rating-stars-component";

import GoogleIcon from "../../assets/google.svg";
import FacebookIcon from "../../assets/facebook.svg";

import { auth } from "../../configs/firebase-config";

import { fetchUserById } from "../../entities/User/models/services/user.services";

import { $api } from "../../shared/api/api";
import { errorHandler } from "../../shared/utils/errorHandler";
import { USER_LOCALSTORAGE_KEY } from "../../shared/consts/localStorage";
import { useAppDispatch } from "../../shared/components/useAppDispatch/useAppDispatch";

import { isEmail } from "../../shared/utils/validators";

import Input from "../../components/Inputs/Input";
import InputPhone from "../../components/Inputs/InputPhone";
import InputPassword from "../../components/Inputs/InputPassword";

import TextButton from "../../components/Buttons/TextButton";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton";

import Text from "../../shared/lib/widgets/Text";
import RowWidget from "../../shared/lib/widgets/RowWidget";
import Container from "../../shared/lib/widgets/Container";


const SignupContainer = styled.div`
    display: flex;
    overflow: hidden;
    @media (max-width: 1440px) {
        overflow: visible;
        display: block;
        .invisible {
            display: none;
        }
        .visible {
            margin: 16px auto;
            width: 500px;
            padding: 0 12px;
        }
    }
    @media (max-width: 568px) {
        .visible {
            width: 90%;
        }
    }
`;

const SignupWallpaper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

const BackgoundFilter = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 64px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: linear-gradient(#0281ffb5, #0281ff9e, #0281ffb5, #0281ff55);
`;

const SignUp = () => {
    const { t } = useTranslation();
    
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [, setCookie] = useCookies(['welcomeShown']);

    const [agreeTermsOfService, setAgreeTermsOfService] = useState(false);
    
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const [errorState, setErrorState] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        password: '',
    });

    const isAuthentificated = localStorage.getItem(USER_LOCALSTORAGE_KEY);
    if (isAuthentificated) {
        return <Navigate to='/' />
    }

    const validate = () => {
        const newErrors = {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            password: '',
        };

        if (!firstName) 
            newErrors.firstName = t('messages.errors.input.empty');

        if (!lastName) 
            newErrors.lastName = t('messages.errors.input.empty');

        if (phone.length < 5) 
            newErrors.phone = t('messages.errors.input.not_complete');

        if (!isEmail(email))
            newErrors.email = t('messages.errors.input.email');

        if (password.length < 8)
            newErrors.password = t('messages.errors.input.password');

        return newErrors;
    };

    const signUpHandler = async (e: React.FormEvent) => {
        e.preventDefault();
    
        if (agreeTermsOfService) {
            const validationErrors = validate();
            if (Object.values(validationErrors).some(error => error))
                return setErrorState(validationErrors);
    
            if (!isLoading)
                try {
                    setIsLoading(true)

                    const existingMethods = await fetchSignInMethodsForEmail(auth, email.trim().toLowerCase());
                    if (existingMethods.length > 0)
                        return toast.info(t('messages.info.email_already_in_use'));

                    const user: any = await $api.post('/users/', {
                        email: email.trim().toLowerCase(), 
                        first_name: firstName, 
                        last_name: lastName, 
                        phone: phone, 
                    });

                    if (!user.data)
                        return toast.error(t('messages.errors.server_answer'));

                    await createUserWithEmailAndPassword(auth, email.trim().toLowerCase(), password)

                    await sendEmailVerification(auth.currentUser!)
                        .then(() => toast.success(t('messages.success.forgot_password_email_send')))
                        .catch(() => toast.error(t('messages.errors.user.email_send')));
                    
                    setCookie('welcomeShown', 'false', { path: '/' });
                    navigate('/email-verification');
                } catch (error: any) {
                    toast.error(errorHandler(error.code));
                } finally {
                    setIsLoading(false)
                }
        }
    };

    const providerHandler = async (provider: AuthProvider) => {
        return await signInWithPopup(auth, provider)
        .then(async (result) => {
            const user = result.user;
            
            const userServer: any = await $api.post('/users/', {
                email: user.email, 
                first_name: user.displayName?.split(' ')[0], 
                last_name: user.displayName?.split(' ')[1], 
                phone: user.phoneNumber, 
            });
            
            if (!userServer.data)
                return toast.error(t('messages.errors.server_answer'));
            
            setCookie('welcomeShown', 'false', { path: '/' });
            dispatch(fetchUserById(userServer.data.id));
            localStorage.setItem(USER_LOCALSTORAGE_KEY, userServer.data.id);
            navigate('/');
        })
        .catch((e) => {
            console.log(e)
            toast.error(errorHandler(e.code));
        });
    }

    const signUpGoogleHandler = async () => {
        const provider = new GoogleAuthProvider();
        providerHandler(provider);
    }

    const signUpFacebookHandler = async () => {
        const provider = new FacebookAuthProvider();
        providerHandler(provider);
    }


    return (
        <SignupContainer>
            <Container $flex="1" $height="100vh" className="invisible">
                <SignupWallpaper>
                    <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src="images/img_aerial_view_cit.png" alt="" />
                    <BackgoundFilter>
                        <Container $margin="0 35% 0 0">
                            <RowWidget>
                                <img style={{ height: '25px', marginRight: '8px' }} src="images/img_arrow_up.svg" alt="" />
                                <img style={{ height: '32px' }} src="images/img_group_white_a700.svg" alt="" />
                            </RowWidget>

                            <Container $height="32px" />

                            <Text fontWeight="bold" fontSize="50px" color="#fff">{t('auth.sign_up.info.title')}</Text>

                            <Container $height="16px" />

                            <Text color="#fff" fontSize="18px">{t('auth.sign_up.info.description')}</Text>
                        </Container>

                        <RowWidget $alignItems="center">
                            <img height='60px' src="images/img_frame_545.png" alt="people" />

                            <Container $width="12px" />
                            
                            <Container>
                                <RowWidget $alignItems="center">
                                    <ReactStars
                                        edit={false}
                                        count={5}
                                        isHalf={false}
                                        color='#FFA552'
                                        activeColor='#FFA552'
                                        key={5}
                                    />

                                    <Text margin="0 0 0 8px" color="#fff">5.0</Text>
                                </RowWidget>

                                <Container $height="6px" />

                                <Text color="#fff">{t('auth.sign_up.info.reviews')}</Text>
                            </Container>
                        </RowWidget>
                    </BackgoundFilter>
                </SignupWallpaper>
            </Container>
            
            <RowWidget $flexDirection="column" $justifyContent="space-between" $width="500px" $margin="0 10%" $alignItems="center" className="visible">
                <RowWidget $justifyContent="center" $margin=" 32px 0 50px 0" onClick={() => navigate('/sign-in')} style={{ cursor: 'pointer' }}>
                    <img style={{ height: '25px', marginRight: '8px', }} src="images/img_group.svg" alt="logo" />
                    <img style={{ height: '32px' }} src="images/img_group_light_blue_a700.svg" alt="logo" />
                </RowWidget>

                <Container>
                    <Text fontWeight="bold" fontSize="28px">{t('auth.sign_up.title')}</Text>

                    <Container $height="8px" />

                    <Text color="#908F94">{t('auth.sign_up.description')}</Text>

                    <Container $height="32px" />
                    
                    <Input 
                        label={t('input.user_name_label')}
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        placeholder="Mario"
                        errorMessage={errorState.firstName}
                    />

                    <Container $height="16px" />
                    
                    <Input 
                        label={t('input.user_surname_label')}
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        placeholder="Rossi"
                        errorMessage={errorState.lastName}
                    />
                    
                    <Container $height="16px" />
                    
                    <InputPhone 
                        value={phone}
                        onChange={(e) => setPhone(e)}
                        placeholder="+123 4567 89"
                        errorMessage={errorState.phone}
                    />
                    
                    <Container $height="16px" />
                    
                    <Input 
                        label={t('input.user_email_label')}
                        name="otp"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="email@gmail.com"
                        errorMessage={errorState.email}
                    />
                    
                    <Container $height="16px" />
                    
                    <InputPassword 
                        label="Password"
                        name="otp"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder={t('input.password_placeholder')}
                        errorMessage={errorState.password}
                    />

                    <Container $height="12px" />

                    <RowWidget $alignItems="center">
                        <input 
                            type="checkbox" 
                            checked={agreeTermsOfService}
                            onChange={() => setAgreeTermsOfService(!agreeTermsOfService)}
                            style={{
                                width: '20px',
                                height: '20px',
                                outline: 'none',
                                borderRadius: '50%',
                                cursor: 'pointer',
                            }}
                        />

                        <Container $width="8px" />

                        <RowWidget $flexWrap="wrap">
                            <Text>{t('auth.sign_up.terms_and_privacy.text1')} &nbsp;</Text>
                            <Text color="#007FFF">{t('auth.sign_up.terms_and_privacy.text2')} &nbsp;</Text>
                            <Text>{t('auth.sign_up.terms_and_privacy.text3')} &nbsp;</Text> 
                            <Text color="#007FFF">{t('auth.sign_up.terms_and_privacy.text4')}</Text>
                            <Text>.</Text> 
                        </RowWidget>
                    </RowWidget>

                    <Container $height="32px" />

                    <PrimaryButton 
                        onClick={(e) => agreeTermsOfService ? signUpHandler(e) : null}
                        title={isLoading ? "Loading..." : t('button.create_account')}
                        $isDisabled={agreeTermsOfService ? false : true}
                        $width="100%"
                    />
                    
                    <Container $height="16px" />

                    <RowWidget $justifyContent="center">
                        <Text margin="0 4px" fontSize="14px" color="#46454D">{t('auth.sign_up.no_account')}</Text>
                        <TextButton 
                            onClick={() => navigate('/sign-in')}
                            title={t('auth.sign_in.title')}
                            fontWeight="bold" 
                            fontSize="14px" 
                            color="#007FFF" 
                        />
                    </RowWidget>
                    
                    <Container $height="24px" />

                    <RowWidget $gap="12px" $alignItems="center">
                        <Container $height="1px" $backgroundColor="#E9E9EA" $width="100%" />
                        <Text color="#7E7D83">{t('auth.email_verification.description_text.text4').toUpperCase()}</Text>
                        <Container $height="1px" $backgroundColor="#E9E9EA" $width="100%" />
                    </RowWidget>

                    <Container $height="24px" />

                    <RowWidget $gap="12px">
                        <SecondaryButton
                            onClick={signUpGoogleHandler}
                            leading={GoogleIcon}
                            title={t('auth.sign_up.sign_with_google')}
                            $width="100%"
                            $borderColor="#E9E9EA"
                        />
                        <SecondaryButton
                            onClick={signUpFacebookHandler}
                            leading={FacebookIcon}
                            $width="fit-content"
                            $borderColor="#E9E9EA"
                        />
                    </RowWidget>
                </Container>

                <Container></Container>
            </RowWidget>
        </SignupContainer>
    );
}

export default SignUp;