import React from 'react';

import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Document, Page, PDFDownloadLink, StyleSheet, Text, View } from '@react-pdf/renderer';

import TextWidget from '../../shared/lib/widgets/Text';
import Container from '../../shared/lib/widgets/Container';


const BillingCardStyle = styled.div`
    padding: 10px 10px;
    background-color: #FFF; 
    border-bottom: 1px solid #E9E9EA;
    display: grid;
    grid-template-columns: 1fr minmax(50px, 140px) minmax(50px, 140px) minmax(50px, 140px) minmax(50px, 140px) minmax(50px, 150px);
    gap: 8px;
    align-items: center;
`;

const BillingCellStyle = styled.div`
    font-size: 14px;
    color: #201F29;
`;

interface BillingCardProps {
    billingData: any;
    invoice: string;
}

const BillingCard: React.FC<BillingCardProps> = ({ billingData, invoice, }) => {
    return (
        <BillingCardStyle>
            <TextWidget fontWeight="bold" color="#201F29">{invoice}</TextWidget>

            <BillingCellStyle>
                {`${new Date(billingData.created*1000).toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}`}
            </BillingCellStyle>

            <BillingCellStyle>
                {
                    billingData.status === 'succeeded' ? 
                        <Container $width='100px' $padding='8px 24px' $backgroundColor='#EDF8E8' $borderRadius='40px'>
                            <TextWidget color='#4EB61C' $textAlign='center'>Pagato</TextWidget>
                        </Container>
                    : 
                    billingData.status === 'requires_payment_method' ? 
                        <Container $width='100px' $padding='8px 24px' $backgroundColor='#FFF6EE' $borderRadius='40px'>
                            <TextWidget color='#FFA552' $textAlign='center'>In attesa</TextWidget>
                        </Container>
                    : 
                        <Container $width='100px' $padding='8px 24px' $backgroundColor='#FFEEEE' $borderRadius='40px'>
                            <TextWidget color='#FF5252' $textAlign='center'>Rifiutato</TextWidget>
                        </Container>
                }
            </BillingCellStyle>
                
            <BillingCellStyle>
                <TextWidget color="#201F29" fontSize='14px'>
                    {`${billingData.currency.toUpperCase()} ${billingData.currency === 'usd' ? ' $' : '€'}${`${billingData.amount/100}`}`}
                </TextWidget>
            </BillingCellStyle>

            <BillingCellStyle>-</BillingCellStyle>
            
            <BillingCellStyle>
                <PDFDownloadLink style={{ textDecoration: 'none', fontWeight: 'bold', fontSize: '14px', width: '150px', display: 'flex', justifyContent: 'flex-end', fontFamily: 'SF Pro Display', color: 'rgba(65, 88, 227, 1)' }} document={<MyDocument billingData={billingData} />} fileName={`billing.pdf`}>
                    {({ blob, url, loading, error }) => {
                        if (error)
                            return toast.error(error.name)
                        return loading ? 'Loading document...' : 'Download'
                    }
                    }
                </PDFDownloadLink>
            </BillingCellStyle>
        </BillingCardStyle>
    );
};

export default BillingCard;


const pdfStyles = StyleSheet.create({
    page: {
        padding: '32px',
        fontFamily: 'Helvetica',
    },
    row: {
        fontSize: '14px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '32px',
    },
    header: {
        fontSize: '32px',
        fontWeight: 'bold',
        fontFamily: 'Helvetica',
        color: '#007FFF',
        marginBottom: '16px',
    },
    subHeader: {
        fontSize: '18px',
        fontFamily: 'Helvetica',
        fontWeight: 'bold',
        marginBottom: '24px',
    },
    title: {
        fontSize: '16px',
        fontFamily: 'Helvetica',
        fontWeight: 'bold',
        marginBottom: '16px',
    },
    sectionContent: {
        marginBottom: '12px',
    },
    paidText: {
        fontFamily: 'Helvetica',
        fontWeight: 'bold',
    },
    boldText: {
        fontSize: '14px',
        fontFamily: 'Helvetica',
        fontWeight: 'bold',
        paddingLeft: '16px',
    },
});


const MyDocument = ({billingData}: any) => {
    return (
        <Document>
            <Page size="A4" style={pdfStyles.page}>
                <View style={pdfStyles.row}>
                    <View style={{ width: '50%' }}>
                        <Text style={pdfStyles.header}>LegalAssist</Text>

                        <Text>Società LegalAssist</Text>
                        <Text>Indirizzo</Text>
                        <Text>Citta, ZIPCode</Text>
                    </View>

                    <View style={{ width: '50%' }}>
                        <Text style={pdfStyles.subHeader}>FATTURA</Text>
                        <Text style={pdfStyles.sectionContent}>Fattura # <Text style={pdfStyles.boldText}>2024-29-03</Text></Text>
                        <Text style={pdfStyles.sectionContent}>Data fattura <Text style={pdfStyles.boldText}>{`${new Date(billingData.created*1000).toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}`}</Text></Text>
                        <Text style={pdfStyles.sectionContent}>Importo della fattura <Text style={pdfStyles.boldText}>{`${billingData.currency === 'usd' ? ' $' : '€'} ${`${billingData.amount/100}`} (${billingData.currency.toUpperCase()} )`}</Text></Text>
                        <Text style={pdfStyles.sectionContent}>Stato <Text style={{...pdfStyles.paidText, color: billingData.status === 'succeeded' ? 'rgba(78, 182, 28, 1)' : billingData.status === 'requires_payment_method' ? 'rgba(255, 165, 82, 1)' : 'rgba(255, 82, 82, 1)'}}>{billingData.status === 'succeeded' ? "PAID" : billingData.status === 'requires_payment_method' ? 'PENDING' : 'FAILD'}</Text></Text>
                    </View>
                </View>

                <View style={pdfStyles.row}>
                    <View style={{ width: '50%' }}>
                        <Text style={pdfStyles.title}>ADDEBITATO A:</Text>
                        <Text style={{ ...pdfStyles.sectionContent, fontWeight: 'bold' }}>Nome Cognome</Text>
                        <Text>Indirizzo</Text>
                        <Text style={pdfStyles.sectionContent}>Citta, ZIPCode</Text>
                        <Text>email@gmail.com</Text>
                    </View>

                    <View style={{ width: '50%' }}>
                        <Text style={pdfStyles.title}>SOTTOSCRIZIONE:</Text>
                        
                        <View style={{ ...pdfStyles.sectionContent, flexDirection: 'row' }}>
                            <Text>ID</Text>
                            <Text style={pdfStyles.boldText}>1234567890</Text>
                        </View>
                        <View style={{ ...pdfStyles.sectionContent, flexDirection: 'row' }}>
                            <Text>Periodo di fatturazione</Text>
                            <Text style={pdfStyles.boldText}>Dal 29 febbraio al 29 marzo 2024</Text>
                        </View>
                        <View style={{ ...pdfStyles.sectionContent, flexDirection: 'row' }}>
                            <Text>Fatturazione successiva</Text>
                            <Text style={pdfStyles.boldText}>Data 29 aprile 2024</Text>
                        </View>
                    </View>
                </View>


                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderBottom: '1px solid #d3d3d3', color: '#d3d3d3', fontSize: '12px', paddingBottom: '12px' }}>
                    <Text>DESCRIZIONE</Text>
                    <Text>IMPORTO (EUR)</Text>
                </View>

                <View style={{ borderBottom: '1px solid #d3d3d3', padding: '12px 0', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text style={{ fontWeight: 'bold', fontSize: '14px' }}>Abbonamento mensile LegalAssist PRO</Text>
                    <Text style={{ fontWeight: 'bold', fontSize: '14px' }}>{`${billingData.currency === 'eur' ? '€' : ' $'}${billingData.amount/100}`}</Text>
                </View>

                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', fontSize: '14px', fontWeight: 'normal', margin: '8px 0' }}>
                    <View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '150px', margin: '4px 0' }}>
                            <Text>Totale parziale</Text>
                            <Text>{`${billingData.currency === 'eur' ? '€' : ' $'}${billingData.amount/100}`}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '150px', marginBottom: '8px' }}>
                            <Text>Tasse Regionali 10%</Text>
                            <Text>{billingData.currency === 'eur' ? '€' : ' $'}3.00</Text>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '150px', paddingTop: '8px', borderTop: '1px spolid #d3d3d3' }}>
                            <Text>Totale</Text>
                            <Text>{`${billingData.currency === 'eur' ? '€' : ' $'}${billingData.amount/100 + 3}`}</Text>
                        </View>
                    </View>
                </View>

                <View style={{ position: 'absolute', bottom: '32px', left: '64px', right: '64px' }}>
                    <Text style={{ color: '#153968', fontSize: '18px', textAlign: 'center', marginBottom: '8px', fontWeight: 'bold' }}>Grazie per aver fiducia in noi</Text>
                    <Text style={{ textAlign: 'center', fontSize: '10px', color: '#d3d3d3' }}>Lorem ipsum dolor sit amet consectetur. Volutpat rutrum convallis adipiscing turpis mi. Hac tincidunt quis euismod facilisi commodo tincidunt blandit. Posuere consequat eu urna dictum netus eget enim adipiscing augue. Diam et amet aliquet aenean sed ultrices egestas feugiat.</Text>
                </View>
            </Page>
        </Document>
    )
}