import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import i18n from 'i18next';

export const fetchCases = createAsyncThunk(
    'cases/fetchCases',
    async (_, thunkApi: any) => {
        const { extra, rejectWithValue } = thunkApi;

        try {
            const response = await extra.api.get('/cases-user/');
            return response.data;
        } catch (error: any) {
            toast.error(i18n.t('messages.errors.cases.not_found'));
            return rejectWithValue('Cases fetch error!');
        }
    }
);

export const updateCase = createAsyncThunk(
    'cases/updateCase',
    async (caseData: any, thunkApi: any) => {
        const { extra, rejectWithValue } = thunkApi;

        try {
            const response = await extra.api.patch(`/cases/${caseData.id}/`, caseData);
            return response.data;
        } catch (error: any) {
            toast.error(i18n.t('messages.errors.cases.search'));
            return rejectWithValue('Case edit error!');
        }
    }
);

export const deleteCase = createAsyncThunk(
    'cases/deleteCase',
    async (id: string, thunkApi: any) => {
        const { extra, rejectWithValue } = thunkApi;

        try {
            await extra.api.delete(`/cases/${id}/`)
            return id;
        } catch (error: any) {
            toast.error(i18n.t('messages.errors.cases.delete'));
            return rejectWithValue('Case delete error!');
        }
    }
);